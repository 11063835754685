$bgColors: (
  white: $white,
  primary-blue: $primary-blue,
  teal: $teal,
  teal-10: $teal-10,
);
@each $value in $bgColors {
  .bg-color-#{'' + nth($value, 1)} {
    background-color: nth($value, 2);
  }
}

.bg-color-teal,
.bg-color-primary-blue {
  &,
  a:not(.cmp-cta-button) {
    color: $white;
  }
}
