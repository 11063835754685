#top-search-modal {
  position: absolute;
  top: 100%;
  width: rem(300px);
  padding: rem(10px);
  background-color: $white;
  display: none;

  @include media-breakpoint-down(sm) {
    width: 100%;
    left: 0;
    z-index: 10;
  }
  .cmp_modal__header {
    display: none;
  }
  .cmp-search__input {
    height: 100%;
    width: 100%;
    font-size: 16px;
    border: 1px solid #dfe1e5;
    background: #fff;
    padding: 5px 9px 4px 30px;
    &:focus {
      outline: 0;
    }
  }
  .cmp-search__results-wrapper {
    p {
      margin-top: rem(10px);
      margin-bottom: rem(10px);
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: $color-accent;
      display: flex;
      align-items: center;
      letter-spacing: $letter-spacing;
      text-decoration: none;
      font-weight: $font-semibold;
      text-transform: uppercase;
      svg {
        margin-left: rem(10px);
      }
    }
  }
  &.open {
    display: block;
  }
}
