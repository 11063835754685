.cmp-navigation__item-mobile {
  align-items: center;
  display: inline-flex;
  @include media-breakpoint-up(rn) {
    display: none;
  }
}

.mobile-toggle-menu {
  width: rem(23px);
  height: rem(9px);
  position: relative;
  display: inline-flex;
  &:before,
  &:after {
    display: none;
  }

  span {
    position: absolute;
    width: 100%;
    height: 2px;
    right: 0;
    border-radius: 5px;
    background-color: $color-foreground;
    transition: all $transition-time;
  }
  span:first-child {
    top: 0;
  }
  span:last-child {
    bottom: 0;
    width: 75%;
  }
  &.active {
    span:first-child {
      transform: translateY(4px) rotate(45deg);
    }
    span:last-child {
      transform: translateY(-3px) rotate(-45deg);
      width: 100%;
    }
  }
}

.mega-menu__mobile {
  background-color: $white;
  position: fixed;
  top: $header-height-rn;
  left: 0;
  width: 100%;
  margin-top: -1px;
  display: none;
  height: calc(100% - #{$header-height-rn});
  z-index: 100;
  @include media-breakpoint-up(rn) {
    display: none;
  }
  &.open {
    @include media-breakpoint-down(rn) {
      display: block;
    }
  }
  ul {
    @include reset-list;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: rem(18px);
      .arrow {
        width: rem(100px);
        text-align: right;
      }
    }
    .active a {
      color: $color-accent;
    }
    a {
      font-weight: $font-semibold;
      text-transform: uppercase;
      letter-spacing: $letter-spacing;
      text-decoration: none;
      color: $color-foreground;
      display: inline-flex;
      align-items: center;
      transition: color $transition-time;
      padding-top: $spacer;
      padding-bottom: $spacer;
      width: 100%;
    }
  }
  .back-to-menu {
    color: $color-foreground;
    display: flex;
    align-items: center;
    font-size: rem(16px);
    text-decoration: none;
    padding-top: $spacer;
    padding-bottom: $spacer;
    font-weight: $font-semibold;
    letter-spacing: $letter-spacing;
    .arrow {
      margin-right: rem(10px);
      &:after {
        transform: rotate(130deg);
      }
    }
  }
  .arrow {
    display: inline-block;
    &:after {
      content: '';
      border: solid $color-foreground;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      border-top-right-radius: 30%;
      transform: rotate(-45deg);
    }
  }
  .scroll-container {
    display: flex;
  }
  .column {
    width: 100vw;
    padding: $spacer calc($spacer * 1.33);
    @include media-breakpoint-up(md) {
      width: 50vw;
    }
  }
  .menu-group {
    display: none;
    &.active {
      display: block;
    }
  }
}
