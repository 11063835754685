.cmp-accordion {
  &__item {
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius;
    border: 2px solid $primary;
    margin-bottom: calc($spacer * 1.33);
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__button {
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: calc($spacer * 2);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: inherit;
    outline: 0;
    color: $color-foreground;

    &:hover {
      .cmp-accordion__title {
        color: $primary-hover;
      }
      .cmp-accordion__icon:after {
        border-color: $primary-hover;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: calc($spacer * 1.33);
    }
    &--expanded {
      .cmp-accordion__icon:after {
        transform: rotate(-135deg);
      }
    }
  }
  &__title {
    color: $primary;
    font-style: italic;
    font-family: $font-serif;
    max-width: 90%;
  }
  &__icon {
    &::after {
      content: '';
      transition: all 0.35s;
      border: solid $primary;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 8px;
      transform: rotate(45deg);
      flex-shrink: 0;
      position: absolute;
      right: calc($spacer * 2);
      top: calc($spacer * 2);
      @include media-breakpoint-down(sm) {
        padding: 4px;
        right: calc($spacer * 1.33);
        top: calc($spacer * 1.33);
      }
    }
    svg {
      display: none;
    }
  }
  &__header {
    font-size: rem(24px);
    line-height: 1.2;
    cursor: pointer;
    letter-spacing: -0.24px;
    p {
      font-size: rem(20px);
      width: 100%;
      margin-top: calc($spacer * 1.33);
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
  &__panel {
    padding: 0 calc($spacer * 2) calc($spacer * 2);
    @include media-breakpoint-down(sm) {
      padding: 0 calc($spacer * 1.3) calc($spacer * 1.3);
    }
    .aem-GridColumn {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .image {
    margin-top: 0!important;
  }
}

.accordion .component:not(:last-child) {
  margin-bottom: calc($spacer * 2);
}
