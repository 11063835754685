.cmp-embed {
  &-youtube,
  &__youtube,
  &-vimeo {
    position: relative;
    height: 0;
    border-radius: $border-radius;
    overflow: hidden;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.cmp-embed-selfhostedvideo {
    border-radius: $border-radius;
    overflow: hidden;
    video {
      width: 100%;
      vertical-align: middle;
    }
  }
}

.embed + .text {
  + .cta-button {
    margin-top: rem(-35px);
  }
}
