.cmp-columncontainer {
  .cmp-container > .aem-Grid > .aem-GridColumn {
    padding-left: 0;
    padding-right: 0;
  }
  .columns-count-2 {
    .column {
      max-width: 50%;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
  }
  .columns-count-3 {
    .column {
      max-width: 33.33%;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
  }
  .columns-count-4 {
    .column {
      max-width: 25%;
      @include media-breakpoint-down(lg) {
        max-width: 50%;
        &:nth-child(-n + 2) {
          margin-bottom: calc($spacer * 2);
        }
      }
      @include media-breakpoint-down(md) {
        max-width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .column {
    @include media-breakpoint-down(sm) {
      margin-bottom: calc($spacer * 2);
      &:last-child {
        margin-bottom: 0;
      }
    }
    .cmp-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .tile {
      flex: 1;
    }
    .cmp-cta-button {
      flex-wrap: wrap;
      justify-content: center;
      @include media-breakpoint-down(lg) {
        padding-left: rem(15px);
        padding-right: rem(15px);
      }
      @include media-breakpoint-down(sm) {
        padding-left: rem(24px);
        padding-right: rem(24px);
      }
    }
    .text.text--md + .text {
      margin-top: $spacer;
    }
  }
}
