#{headings(1,6)} {
  margin-top: 0;
  margin-bottom: $heading-spacer;
  font-weight: $font-regular;
}
h1,
h2 {
  font-weight: $font-light;
  letter-spacing: -0.66px;
}
h1 {
  @include font-size($heading-xxl);
  line-height: 1.2;
}
h2 {
  @include font-size($heading-xl);
  line-height: 1.3;
}
h3 {
  @include font-size($heading-lg);
}
#{headings(3,6)} {
  line-height: 1.5;
}
