.image {
  margin-top: var(--section-spacing);
  margin-bottom: var(--section-spacing);

  &--rectangular,
  &--tall,
  &--full-width {
    position: relative;
    img {
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      object-fit: cover;
    }
  }
  &--rectangular {
    padding-top: 83.51%;
  }
  &--tall {
    padding-top: 145.79%;
  }
  &--full-width {
    padding-top: 46.24%;
  }
  &:not(.image--no-rounded) img {
    border-radius: $border-radius;
  }
  &:not(.image--no-shadow) img {
    box-shadow: 0 23px 20px rgb($primary-blue, 24%);
    .bg-color-primary-blue & {
      box-shadow: 0 23px 20px rgb(#000, 24%);
    }
  }
}

.image + .image {
  margin-top: 0;
}
.cmp-image {
  &__image {
    vertical-align: middle;
  }
}

.image
  + .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg),
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg)
  + .image {
  margin-top: 0;
}
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) {
  .image {
    margin-top: 0;
  }
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) {
  .image {
    margin-top: calc(var(--section-spacing) / 2);
    margin-bottom: calc(var(--section-spacing) / 2);
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.component {
  .image {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.image + .component {
  padding-top: 0 !important;
}
.in-page-anchors + .image {
  margin-top: 0;
}
