.social-feed {
  position: relative;
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
  .slick-list {
    overflow: visible;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: auto;
    &:not(.slick-active) {
      opacity: 0.3;
    }
    > div {
      height: 50%;
      padding: $spacer;
      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .slick-dots {
    position: relative;
    display: flex !important;
    justify-content: center;
    bottom: auto;
    margin-top: calc(var(--section-spacing) / 2);
    li {
      height: 100%;
      width: 100%;
      border-radius: $border-radius;
      overflow: hidden;
      max-width: rem(86px);
      opacity: 0.3;
      &.slick-active {
        opacity: 1;
      }
    }
    button {
      width: 100%;
      height: 100%;
      background-color: $primary;
      padding: 2px;
      &:before {
        display: none;
      }
    }
  }
  .cmp-social-feed {
    &__item {
      height: 100%;
    }
    &__card {
      background-color: $white;
      padding: calc($spacer * 2);
      border-radius: rem(10px);
      display: flex;
      box-shadow: 0 13px 10px rgba($primary, 10%);
      height: 100%;
      outline: 0;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: $spacer;
    }
    &__icon {
      display: inline-flex;
      max-width: rem(26px);
    }
    &__excerpt {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &__image {
      width: 11.979vw;
      height: 11.979vw;
      flex-shrink: 0;
      @include media-breakpoint-up(3xl) {
        width: rem(239px);
        height: rem(239px);
      }
      @include media-breakpoint-down(sm) {
        width: 27.083vw;
        height: 27.083vw;
      }
      img {
        display: block;
        border-radius: rem(6px);
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      margin-bottom: rem(10px);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      font-size: rem(16px);
      max-height: rem(97px);

      a {
        color: $primary;
        &:hover {
          color: $primary-hover;
        }
      }
    }
    .cmp-social-feed__image + .cmp-social-feed__excerpt {
      margin-left: 1.563vw;
      @include media-breakpoint-up(3xl) {
        margin-left: rem(30px);
      }
      @include media-breakpoint-down(sm) {
        margin-left: rem(20px);
      }
    }
    &__date {
      font-size: rem(12px);
      font-weight: $font-semibold;
      color: $primary-blue;
      display: inline-block;
    }
    &__cta {
      margin-top: auto;
      @include media-breakpoint-down(sm) {
        text-align: right;
      }
    }
    .cta--link {
      font-weight: $font-semibold;
      border-radius: rem(29px);
      letter-spacing: 2.08px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      font-size: rem(16px);
      line-height: 2.125;
      text-decoration: none;
      color: $primary;
      &:hover {
        color: $primary-hover;
      }
      .cta--link__icomoon--arrow {
        margin-left: rem(10px);
      }
    }
  }
  .cookieconsent-optout-statistics {
    &>div {
      top: 0;
      width: calc(100% - 30px);
      height: calc(100% - var(--section-spacing));
      
      @include media-breakpoint-down(lg) {
        width: calc(100% - 40px);
      }
    }
  }
}

.title + .social-feed {
  margin-top: calc($spacer * 1.33);
}

