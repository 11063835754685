.cmp-lab-member {
  .title {
    padding-top: 0;
  }
  &__back {
    flex-direction: row-reverse;
    .cmp-cta-button__icon {
      margin-left: 0;
      margin-right: rem(10px);
      transform: rotate(180deg);
    }
  }
  &__name {
    color: $primary;
    font-family: $font-serif;
    font-style: italic;
    font-size: rem(30px);
    margin-bottom: $spacer;
    @include media-breakpoint-down(sm) {
      font-size: rem(24px);
      margin-bottom: rem(10px);
    }
  }
  &__position {
    font-size: rem(26px);
    line-height: 1;
    font-weight: $font-medium;
    @include media-breakpoint-down(sm) {
      font-size: rem(20px);
      margin-bottom: rem(10px);
    }
  }
  .image {
    margin-top: 0;
    margin-bottom: calc($spacer * 2);
  }
}
