//== Font
$font-serif: 'IBM Plex Serif', serif;
$font-sans-serif: 'Raleway', sans-serif;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

$font-family: $font-sans-serif;
$font-size: rem(16px);
$font-height: 1.5;

$letter-spacing: 2.8px;

//== Color
$white: #fff;
$pomegranate: #f04b42;
$supernova: #ffcb05;
$ecstasy: #f68628;
$key-lime-pie: #bfd730;
$teal: #009fa5;
$teal-10: #f1f9fa;
$ocean-green: #44ba87;
$cerulean: #00aae7;
$primary-gray: #7c7e80;
$primary-blue: #3870b7;
$primary: $teal;
$primary-hover: #006b6f;

$color-background: $white;
$color-foreground: #212b32;
$color-link: $primary-blue;
$color-accent: $teal;
$color-accent-darker: #006b6f;

$color-text: $color-foreground;
$color-text-inverted: $color-background;
$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

//== Layout
$transition-time: 0.3s;
$container-max-width: 1200px;
$border-radius: rem(20px);
$drop-shadow: drop-shadow(0 23px 20px rgba(56, 112, 183, 0.25));
$drop-shadow-newsroom-pr: drop-shadow(0 23px 20px rgba(64, 147, 150, 0.10));
$header-height: rem(170px);
$header-height-rn: rem(80px);
$image-offset: rem(36px);

$heading-xxl: (
  null: rem(66px),
  map-get($grid-breakpoints, sm): rem(46px),
);
$heading-xl: (
  null: rem(46px),
  map-get($grid-breakpoints, sm): rem(36px),
);
$heading-lg: (
  null: rem(40px),
  map-get($grid-breakpoints, sm): rem(30px),
);
$text-base: (
  null: rem(20px),
);
$text-lg: (
  null: rem(30px),
);
$text-md: (
  null: rem(26px),
);
$text-sm: (
  null: rem(18px),
);
$text-xs: (
  null: rem(16px),
);

$p-spacer: calc($spacer * 2);
$heading-spacer: calc($spacer * 2);

// Root variabiles
:root {
  --input-border-radius: #{rem(28px)};
  --input-height: #{rem(56px)};
  --input-background-color: #fff;
  --input-padding-x: #{rem(20px)};
  --input-padding-y: #{rem(15px)};
  --input-font-size: #{rem(16px)};
  --input-line-height: 1.825;
  --section-spacing: #{rem(100px)};
  @include media-breakpoint-down(sm) {
    --section-spacing: #{rem(80px)};
  }
}
