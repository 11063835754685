.root.container {
  @extend %container;

  .xf-content-height {
    min-height: inherit;
    margin: 0;
  }
}
.root.container > .cmp-container > section > .cmp-container {
  section:first-child {
    padding-bottom: calc($spacer * 4.66);
    .component:not(.image) {
      padding-bottom: 0;
    }
  }
}
.root.container > .cmp-container > section > .cmp-container {
  section:first-child,
  .generic-hero:first-child {
    padding-top: calc(#{$header-height} + #{rem(40px)});

    @include media-breakpoint-down(rn) {
      padding-top: calc(#{$header-height-rn} + #{rem(60px)});
    }
  }
}
.container {
  position: relative;
  &.aem-GridColumn {
    padding-top: var(--section-spacing);
    padding-bottom: var(--section-spacing);
    .container.aem-GridColumn:not(.has-bg) {
      padding-top: 0;
      padding-bottom: 0;
    }
    &:not(.container--flower-style):not(.container--border-style) {
      .component:not(.image) {
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .component:not(.image) {
          padding-top: 0;
          padding-bottom: 0;
        }
        p:last-child {
          margin-bottom: 0;
        }
        & + .component:not(.image) {
          padding-top: 0;
        }
      }
    }
  }
  .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.generic-hero):not(.has-bg)
    + .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) {
    padding-top: 0;
  }
}
.component:not(.image) {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
  .component:not(.image) {
    padding-top: 0;
    padding-bottom: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  & + .component:not(.image) {
    padding-top: 0;
  }
}
.component:not(.image)
  + .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg),
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg)
  + .component:not(.image) {
  padding-top: 0;
}
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) {
  .component:not(.image) {
    padding-top: 0;
  }
}
.container--flower-style,
.cmp-textmedia,
.container--border-style {
  .component:not(.image) {
    padding-top: 0;
    padding-bottom: 0;
  }
  .component.text {
    margin-bottom: calc($spacer * 2) !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.cmp-container {
  &[class*='bg-color-'] {
    background-color: transparent;
  }
}
.container--bg-full-style {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
  @extend %full-width;
}
.container-image,
.container-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container-image {
  z-index: 2;
  background-position: center;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}
.container-color {
  z-index: 1;
}
.container--border-style {
  > .cmp-container {
    border: 2px solid $white;
    border-radius: $border-radius;
    padding: 3.906vw 5.885vw;
    margin-left: calc($grid-gutter-width / 1.5);
    margin-right: calc($grid-gutter-width / 1.5);
    @include media-breakpoint-up(3xl) {
      padding: rem(75px) rem(113px);
    }
    @include media-breakpoint-down(sm) {
      padding: 15.625vw 5.417vw;
    }
    @each $value in $bgColors {
      &.bg-color-#{'' + nth($value, 1)} {
        background-color: rgba(nth($value, 2), 56%);
      }
    }
    .aem-GridColumn {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .cta-button {
    margin-top: rem(10px);
  }
}
.container--flower-style__svg {
  display: none;
}
.container--flower-style {
  min-height: rem(677px);
  .container--flower-style__svg {
    display: block;
    overflow: hidden;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    pointer-events: none;
    @extend %full-width;

    &:after {
      content: '';
      background-image: url('./resources/images/gei-flower.svg');
      //background-image: url('https://static-p52302-e321577.adobeaemcloud.com/d49631936115fd60c8d890d8a94e28a9efe123b589cf4cc7e27d9be3de8ddc0d/resources/images/gei-flower.svg');
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(25%);
      height: rem(870px);
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      left: 59vw;
      @include media-breakpoint-down(lg) {
        transform: translateY(63%);
        max-width: rem(435px);
      }
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        transform: translateY(54%);
        height: 105vw;
        left: 0;
      }
    }
  }
  > .cmp-container {
    a {
      font-weight: 500;
      white-space: nowrap;
    }
    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 35vw;
    }
  }
  .title h2 {
    @include font-size($heading-lg);
    line-height: 1.5;
  }
  .image {
    margin-top: 0;
    margin-bottom: calc($spacer * 2);
    img {
      max-width: 100%;
      width: auto;
    }
  }
  &.container.aem-GridColumn {
    @include media-breakpoint-down(sm) {
      padding-top: rem(30px);
    }
  }
}

@supports not (-webkit-touch-callout: none) {
  .container-image {
    background-attachment: fixed;
  }
}

body > .root.container > .cmp-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Force keep padding for component in preview mode
.aem-AuthorLayer-Preview .cq-Editable-dom--container.aem-GridColumn {
  padding-bottom: var(--section-spacing);
  padding-top: var(--section-spacing);
}
