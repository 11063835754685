.text-media {
  @media screen and (max-width: $container-max-width) {
    overflow: hidden;
  }
  &--large-gutter {
    .cmp-textmedia-text {
      @include media-breakpoint-up(md) {
        width: 41.66666667%;
        margin-left: auto;
        margin-right: auto;
        &.order-md-0 {
          margin-left: 0;
        }
      }
    }
  }
  &--center-alignment {
    .row {
      align-items: center;
    }
  }
  &--top-offset {
    margin-top: -$image-offset;
    z-index: 2;
    position: relative;
    .cmp-textmedia--side .cmp-textmedia-text {
      align-self: center;
    }
    &.component {
      padding-top: 0 !important;
    }
  }

  .text {
    &,
    p {
      @include media-breakpoint-down(md) {
        text-align: center !important;
      }
    }
    .cmp-title__text {
      @extend h2;
      text-transform: none;
      color: $color-foreground;
      .bg-color-primary-blue &,
      .bg-color-teal & {
        color: $white;
      }
    }
  }
  .cta-button {
    @include media-breakpoint-down(md) {
      text-align: center !important;
    }
  }
  .order-0 {
    @include media-breakpoint-down(sm) {
      margin-bottom: calc($spacer * 2);
    }
  }
}
.text-media + .text-media {
  margin-top: rem(-20px);
  @include media-breakpoint-down(sm) {
    margin-top: rem(-50px);
  }
}
.container.aem-GridColumn {
  .text-media--top-offset {
    margin-top: calc(-#{$image-offset} - var(--section-spacing));
  }
  .text-media + .text-media {
    margin-top: 0;
    padding-top: rem(30px) !important;
    @include media-breakpoint-down(sm) {
      padding-top: 0 !important;
      margin-top: rem(-10px);
    }
  }
}
