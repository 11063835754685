.cmp-search-filter {
  .title {
    padding-top: 0;
  }
  &__filtering {
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: center;
    }
  }

  &__search {
    position: relative;
    @include media-breakpoint-up(sm) {
      margin-right: $spacer;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: calc($spacer * 2);
    }
    &:hover {
      button {
        color: $primary;
      }
      input {
        color: $primary;
        @include placeholder {
          color: $primary;
        }
      }
    }
  }
  &__button {
    appearance: none;
    position: absolute;
    top: var(--input-padding-y);
    right: var(--input-padding-x);
    margin: auto;
    display: inline-block;
    padding: 0;
    background: transparent;
    border: 0;
    color: $color-text;
    svg {
      max-width: rem(16px);
    }
  }

  &__roles,
  &__search {
    width: 100%;
    @include media-breakpoint-up(sm) {
      max-width: rem(320px);
    }
  }
  &__roles {
    @include media-breakpoint-up(sm) {
      margin-left: $spacer;
    }
  }
  &__member {
    display: flex;
    margin-bottom: calc($spacer * 3.33);
    @include media-breakpoint-down(sm) {
      margin-bottom: calc($spacer * 2);
    }

    &-photo {
      object-fit: cover;
      width: rem(170px);
      height: rem(170px);
      border-radius: $border-radius;
      box-shadow: 0 20px 8px rgba($primary-blue, 24%);
      margin-right: calc($spacer * 2);
      @include media-breakpoint-down(sm) {
        width: rem(131px);
        height: rem(131px);
        margin-right: calc($spacer * 1.33);
      }
    }
    &-name {
      font-family: $font-serif;
      color: $primary;
      font-size: rem(30px);
      letter-spacing: -0.3px;
      font-style: italic;
      margin-bottom: $spacer;
      @include media-breakpoint-down(sm) {
        font-size: rem(24px);
        margin-bottom: rem(10px);
      }
    }
    &-position {
      line-height: 1.23;
      font-weight: $font-medium;
      font-size: rem(26px);
      margin-bottom: calc($spacer * 2);
      @include media-breakpoint-down(sm) {
        font-size: rem(20px);
        margin-bottom: rem(10px);
      }
    }
  }

  input,
  select {
    font-size: var(--input-font-size);
    appearance: none;
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    color: $color-text;
    border: 0;
    padding: var(--input-padding-y) var(--input-padding-x);
    width: 100%;
    height: var(--input-height);
    line-height: var(--input-line-height);
    font-weight: $font-semibold;
    text-transform: uppercase;
    letter-spacing: 2.08px;
    &:focus {
      outline: rgba($primary, 10%);
    }
    @include placeholder {
      color: $color-text;
      opacity: 1;
    }
  }

  .custom-select {
    font-weight: $font-semibold;
    text-transform: uppercase;
    letter-spacing: 2.08px;
    font-size: var(--input-font-size);
    position: relative;
    z-index: 1;

    &__label {
      display: block;
      padding: var(--input-padding-y) var(--input-padding-x);
      color: $color-text;
      height: var(--input-height);
      background: $white;
      border-radius: var(--input-border-radius);
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: var(--input-line-height);
      cursor: pointer;
    }
    &__arrow {
      position: absolute;
      right: var(--input-padding-x);
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0;
      height: 0;
      transform: rotateZ(45deg);
      border: solid $color-text;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      border-top-right-radius: 30%;
      transition: transform $transition-time;
    }
    &__list {
      @include reset-list;
      width: 100%;
      position: absolute;
      z-index: 1;
      background: $white;
      border-bottom-left-radius: var(--input-border-radius);
      border-bottom-right-radius: var(--input-border-radius);
      padding-bottom: rem(20px);
      visibility: hidden;
      li {
        padding: rem(5px) var(--input-padding-x);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        &:hover,
        &.current {
          font-weight: $font-bold;
        }
      }
    }
    &:hover {
      .custom-select__label {
        color: $primary;
      }
      .custom-select__arrow {
        border-color: $primary;
      }
    }
  }
  .expanded {
    .custom-select__arrow {
      transform: rotate(-135deg);
      border-color: $primary;
    }
    .custom-select__list {
      visibility: visible;
    }
    .custom-select__label {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: $primary;
    }
  }
  &__no-results {
    font-size: rem(20px);
  }
}
