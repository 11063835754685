.cmp-tile {
  padding: calc($spacer * 2.66) $spacer;
  border-radius: $border-radius;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  filter: $drop-shadow;
  background-color: $primary;
  &,
  a {
    color: $white;
  }

  &__image {
    margin-bottom: calc($spacer * 2);
    border-radius: 50%;
    width: 198px;
    height: 198px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  &__title {
    max-width: rem(274px);
    margin-left: auto;
    margin-right: auto;
    .cmp-title__text {
      font-size: rem(26px);
    }
  }
  &__description {
    margin-bottom: calc($spacer * 1.33);
    font-size: rem(20px);
    &:last-child {
      margin-bottom: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.tile--large {
  .cmp-tile {
    padding: calc($spacer * 5.2) $spacer;
    .cmp-tile__title {
      max-width: 100%;
    }
    .cmp-tile__description:not(:last-child) {
      margin-bottom: calc($spacer * 2);
    }
    .cmp-title__text {
      @include font-size($heading-xl);
      font-weight: $font-light;
    }
  }
}
.tile.aem-GridColumn.aem-GridColumn--default--12:last-child {
  padding-bottom: var(--section-spacing);
}
.container.aem-GridColumn {
  .tile.aem-GridColumn.aem-GridColumn--default--12:last-child {
    padding-bottom: 0;
  }
}
