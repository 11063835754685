.generic-hero {
  position: relative;
  .hero {
    &:after {
      content: '';
      display: block;
      overflow: hidden;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      pointer-events: none;
      z-index: -1;
      @extend %full-width;
    }
    @each $value in $bgColors {
      &.bg-color-#{'' + nth($value, 1)}:after {
        background-color: nth($value, 2);
      }
    }
  }
  .cmp-hero {
    position: relative;
    z-index: 2;
  }
  .cmp-hero__image {
    margin-top: 0;
    margin-bottom: -$image-offset;
  }
  .cmp-hero__title {
    padding-top: 0;
  }
  .cmp-hero-flower {
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
    @extend %full-width;
    svg {
      position: absolute;
      transform: translateY(-50%);
      height: auto;
      top: 100%;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 100%;
      vertical-align: middle;

      @include media-breakpoint-down(sm) {
        max-width: 190%;
        left: 50%;
        transform: translate(-50%, -40%);
      }
    }
  }
  .cmp-hero-flower__flower--teal {
    .flower-color {
      fill: $teal;
    }
  }
  .has-two-column-text {
    @include media-breakpoint-up(md) {
      .col-md-10 {
        width: 100%;
      }
    }
    .cmp-hero__text {
      @include media-breakpoint-up(sm) {
        column-count: 2;
        column-gap: calc($spacer * 2);
        text-align: left;
      }
    }
  }
}
.hero--homepage {
  .cmp-hero__title,
  .cmp-hero__section {
    &:first-child {
      @include media-breakpoint-up(sm) {
        margin-top: rem(100px);
      }
    }
  }

  .cmp-hero__cta {
    display: flex;
  }
  .cmp-hero__text {
    max-width: rem(450px);
    margin-bottom: $spacer;
    @include media-breakpoint-up(sm) {
      margin-bottom: rem(50px);
    }
  }

  .cta-button + .cta-button {
    @include media-breakpoint-up(sm) {
      margin-left: rem(10px);
    }
  }
  .cmp-hero-flower svg {
    transform: translate(34%, -50%);
    @include media-breakpoint-down(sm) {
      max-width: 300vw;
      transform: translate(-50vw, 0%);
      left: 0;
      top: 0;
    }
  }
}

.hero--page {
  .cmp-hero__text,
  .cmp-hero__title {
    &:last-child {
      margin-bottom: calc($spacer * 4.66);
    }
  }
}
