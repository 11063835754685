.experiencefragment.footer {
  margin-top: auto;
}
#main-footer {
  padding-top: calc($spacer * 4);
  padding-bottom: calc($spacer * 4);
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-down(rn) {
    padding-top: calc($spacer * 2);
    padding-bottom: calc($spacer * 2);
  }
  > .image,
  > .container {
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);
  }
  > .image {
    width: 83.33%;
    margin-left: auto;
    margin-right: auto; 

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
      margin: 0;
      padding-left: calc($grid-gutter-width / 2);
      padding-right: calc($grid-gutter-width / 2);
    }

    .cmp-image__link {
      display: inline-block;
    }
  }
  > .container {
    @include media-breakpoint-up(lg) {
      width: 75%;
    }
    @include media-breakpoint-up(md) {
      width: 66.66%;
    }
    > .cmp-container > .aem-Grid {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include media-breakpoint-down(lg) {
        align-items: flex-start;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 83.33%;
      }
      .button {
        width: auto !important;
        padding-left: rem(7.5px) !important;
        padding-right: rem(7.5px) !important;
        .cmp-button__text {
          font-size: rem(18px);
          font-weight: $font-medium;
        }
        svg {
          vertical-align: middle;
        }
      }
    }
  }
  .cmp-container section:first-child,
  .container.aem-GridColumn {
    padding: 0;
  }
  .aem-GridColumn--offset--default--1 {
    margin-left: 0;
  }

  .cmp-container {
    section:first-child {
      margin-right: rem(22.5px);
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: end;
      }
    }
    
    section:last-child {
      width: auto;
      @include media-breakpoint-down(lg) {
        display: flex;
        justify-content: start;
        margin-top: rem(20px);
      }
    }
    section {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }
  .component {
    padding-top: 0;
    padding-bottom: 0;
  }
  .image {
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-down(lg) {
      margin-bottom: rem(50px);
    }
  }
}
