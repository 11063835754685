.cmp-supporters-list {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);

  &__item-large {
    display: none;
    margin-bottom: var(--section-spacing);

    &.active {
      display: flex;
    }

    &-logo img {
      border-radius: $border-radius;
      box-shadow: 0 23px 20px rgb(56 112 183 / 24%);
    }
    &-content {
      font-size: rem(20px);
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: rem(-12px);
    margin-right: rem(-12px);
  }

  &__logo {
    width: 25%;
    padding-left: rem(12px);
    padding-right: rem(12px);
    margin-top: rem(15px);
    margin-bottom: rem(15px);
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
    img {
      background-color: $teal-10;
      border-radius: $border-radius;
      box-shadow: 0 23px 20px rgb(56 112 183 / 24%);
      padding: rem(10px);
      transition: background-color $transition-time;
    }
    a:hover img {
      background-color: $primary;
    }
  }
  .partners__arrows {
    text-align: center;
    display: none;
    margin-top: calc($spacer * 2);
    @include media-breakpoint-down(sm) {
      display: block;
    }
    a {
      display: inline-flex;
      width: rem(89px);
      height: rem(89px);
      border-radius: 50%;
      border: 2px solid $primary;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $primary;
    }
    a + a {
      margin-left: calc($spacer * 2);
    }
    span {
      pointer-events: none;
    }
    .disabled {
      opacity: 0.3;
    }
    .arrow-prev span {
      transform: rotate(180deg);
    }
  }
}
