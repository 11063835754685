.text {
  &:last-child p:last-child {
    margin-bottom: 0;
  }
  &--center {
    text-align: center;
    &:not(.text-title--center) {
      .text-title {
        text-align: left;
      }
    }
  }
  &--font-medium {
    font-weight: $font-medium;
  }
  &-title--center {
    .text-title {
      text-align: center;
    }
  }
  &.text-title--copy-divider {
    .cmp-title__text {
      font-style: italic;
      font-family: $font-serif;
      letter-spacing: -0.3px;
      padding-bottom: calc($spacer * 2);
      margin-bottom: calc($spacer * 2);
      border-bottom: 1px solid currentColor;
      text-transform: none;
      font-weight: $font-regular;
      font-size: rem(30px);
      @include media-breakpoint-down(sm) {
        font-size: rem(24px);
      }
    }
  }
  &--teal {
    .cmp-text {
      color: $teal;
    }
  }
  .cmp-title__text {
    margin-bottom: rem(10px);
    font-size: rem(24px);
    letter-spacing: 3.12px;
    font-weight: $font-semibold;
    line-height: 1.4;
    text-transform: uppercase;
    color: $teal;
    .bg-color-primary-blue &,
    .bg-color-teal & {
      color: $white;
    }
    sub,
    sup {
      font-size: 100%;
      top: -0.2em;
      font-weight: 300;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: $p-spacer;
    i {
      font-family: $font-serif;
    }
  }
  ul {
    @include reset-list;
    font-weight: $font-medium;
    li {
      margin-bottom: $spacer;
      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '–';
        display: inline-block;
        margin-right: rem(10px);
      }
    }
    .columns-count-2 & {
      max-width: rem(426px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  // Sizes
  &:not(.text--lg):not(.text--md):not(.text--sm):not(.text--xs) {
    @include font-size($text-base);
  }
  &--lg {
    @include font-size($text-lg);
  }
  &--md {
    @include font-size($text-md);
  }
  &--sm {
    @include font-size($text-sm);
  }
  &--xs {
    @include font-size($text-xs);
  }
}

.text.text-title--copy-divider.component {
  padding-bottom: 0 !important;
}
