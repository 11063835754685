.cmp-navigation {
  height: 100%;
  a sup {
    font-size: 100%;
    top: -0.2em;
    margin-left: -$letter-spacing;
  }
  &__group {
    @include reset-list;
    display: flex;
    height: 100%;

    .mega-menu {
      display: none;
      position: absolute;
      top: 60%;
      left: calc(($spacer * 1.33) * -1);
      padding: calc($spacer * 1.33);
      width: rem(250px);
      border-radius: rem(10px);
      overflow: hidden;
      height: auto;
      box-shadow: 0 13px 10px rgb($teal, 10%);
      background-color: $teal-10;
      z-index: 2;
      li {
        margin-bottom: rem(10px);
      }
      li + li {
        margin-left: 0;
      }
      li:last-child {
        margin-bottom: 0;
      }
      a {
        display: block;
        color: $color-foreground;
      }
      @include media-breakpoint-down(rn) {
        a:hover {
          color: $color-accent;
        }
      }
      .cmp-navigation__item--active a {
        color: $color-accent;
      }
    }
  }
  &__item-arrow {
    max-width: rem(11px);
    height: auto;
    width: auto;
    margin-left: rem(10px);
    path {
      stroke: currentColor;
    }
  }
  &__item {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      .mega-menu {
        display: block;
      }
    }
    a {
      font-weight: $font-semibold;
      text-transform: uppercase;
      letter-spacing: $letter-spacing;
      text-decoration: none;
      color: $color-foreground;
      display: inline-flex;
      align-items: center;
      transition: color $transition-time;
    }
  }
  &__item + &__item {
    margin-left: rem(20px);
  }
  @include media-breakpoint-up(rn) {
    &__item:hover > a {
      color: $color-accent;
    }
  }
  &__item--active > a {
    color: $color-accent;
  }
  &__item:hover {
    .cmp-navigation__item-arrow {
      transform: rotate(-180deg);
    }
  }
  .search-button {
    width: rem(24px);
    @include media-breakpoint-down(rn) {
      margin-right: rem(20px);
    }
    @include media-breakpoint-up(rn) {
      &:hover {
        color: $ocean-green;
      }
    }
    &.is-open {
      .search-icon {
        display: none;
      }
      .close-icon {
        display: block;
      }
    }
    .mega-menu-isOpen & {
      display: none;
    }
  }
  .search-icon {
    display: inline-block;
    width: rem(23px);
    height: auto;
    @include media-breakpoint-down(rn) {
      width: rem(20px);
    }
  }
  .close-icon {
    display: none;
    max-width: rem(18px);
  }

  //Visibility between mobile and desktop
  @include media-breakpoint-down(rn) {
    .show-desktopMenu {
      display: none;
    }
  }
  @include media-breakpoint-up(rn) {
    .hide-desktopMenu {
      display: none;
    }
  }
}
