//== Defaults

html,
body {
  color: $color-text;
  font-size: $font-size;
  font-family: $font-family;
  line-height: $font-height;
}
html {
  scroll-padding-top: $header-height;
  scroll-behavior: smooth;
  @include media-breakpoint-down(sm) {
    scroll-padding-top: $header-height-rn;
  }
}
* {
  box-sizing: border-box;
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'lnum';
  overflow-x: hidden;
}

a {
  color: $color-link;
}

a[href^='mailto:'] {
  word-break: break-word;
}

p {
  margin-top: 0;
  margin-bottom: calc($spacer * 2);
}

//== Layout
%full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

%container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}


[class*='cookieconsent-optout'] > div {
  p,
  p a {
    font-size: 24px;
    @include media-breakpoint-down(lg) {
      font-size: 2.218vw;
    }
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }        
  }
  p a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
  .cmp-cta-button {
    font-size: 18px;
  }
}

.CookieDeclaration {
  padding-top: 5.625rem;
  @include media-breakpoint-down(md) {
    padding-top: 40px;
  } 
}

.CybotCookiebotDialogDetailBulkConsentCount {
  color: $white!important;
}

[class*=cookieconsent-optout]>div {
  background-color: #068391!important;
}
#CybotCookiebotDialogFooter {
  align-self: center;
}