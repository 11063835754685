.cta-button {
  &--center {
    text-align: center;
  }
  &.icon-only {
    .cmp-cta-button {
      min-height: rem(55px);
      padding: rem(8px) rem(22px);
    }
    .cmp-cta-button__icon {
      margin: 0;
    }
  }
  .cmp-cta-button__icon:only-child {
    margin: 0;
  }
}

.cmp-cta-button {
  font-weight: $font-semibold;
  border-radius: rem(29px);
  letter-spacing: 2.08px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: rem(16px);
  line-height: 2.125;
  min-height: rem(59px);
  background-color: $primary;
  color: $white;
  border: 2px solid transparent;
  transition: all $transition-time;
  padding: rem(10px) rem(24px);
  cursor: pointer;
  &:hover {
    background-color: $primary-hover;
  }
  &__icon {
    margin-left: rem(10px);
    &.rotate-180deg {
      margin-left: 0;
      margin-right: rem(10px);
    }
  }
  &--style-teal-outline {
    background-color: transparent;
    border-color: $primary;
    color: $primary;
    &:hover {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }
  }
  &--style-white-outline {
    background-color: transparent;
    border-color: $white;
    color: $white;
    &:hover {
      background-color: $white;
      color: $primary;
      border-color: $white;
    }
  }
  &--style-black-outline {
    background-color: transparent;
    border-color: $color-foreground;
    color: $color-foreground;
    &:hover {
      background-color: $color-foreground;
      color: $white;
      border-color: $color-foreground;
    }
  }
  &--style-white-filled {
    &,
    &:hover {
      background-color: $white;
      color: $primary;
    }
  }
}
