@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital@0;1&amp;&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
@charset "UTF-8";
.row {
  --gei-gutter-x: 1.875rem;
  --gei-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: calc(-1 * var(--gei-gutter-y));
  margin-right: calc(-0.5 * var(--gei-gutter-x));
  margin-left: calc(-0.5 * var(--gei-gutter-x));
}
.row > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--gei-gutter-x) * 0.5);
  padding-left: calc(var(--gei-gutter-x) * 0.5);
  margin-top: var(--gei-gutter-y);
}
.col {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
}
.row-cols-auto > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}
.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --gei-gutter-x: 0;
}
.g-0,
.gy-0 {
  --gei-gutter-y: 0;
}
.g-1,
.gx-1 {
  --gei-gutter-x: 0.46875rem;
}
.g-1,
.gy-1 {
  --gei-gutter-y: 0.46875rem;
}
.g-2,
.gx-2 {
  --gei-gutter-x: 0.9375rem;
}
.g-2,
.gy-2 {
  --gei-gutter-y: 0.9375rem;
}
.g-3,
.gx-3 {
  --gei-gutter-x: 1.875rem;
}
.g-3,
.gy-3 {
  --gei-gutter-y: 1.875rem;
}
.g-4,
.gx-4 {
  --gei-gutter-x: 3.121875rem;
}
.g-4,
.gy-4 {
  --gei-gutter-y: 3.121875rem;
}
.g-5,
.gx-5 {
  --gei-gutter-x: 4.996875rem;
}
.g-5,
.gy-5 {
  --gei-gutter-y: 4.996875rem;
}
.g-6,
.gx-6 {
  --gei-gutter-x: 6.24375rem;
}
.g-6,
.gy-6 {
  --gei-gutter-y: 6.24375rem;
}
@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --gei-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --gei-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --gei-gutter-x: 0.46875rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --gei-gutter-y: 0.46875rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --gei-gutter-x: 0.9375rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --gei-gutter-y: 0.9375rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --gei-gutter-x: 1.875rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --gei-gutter-y: 1.875rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --gei-gutter-x: 3.121875rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --gei-gutter-y: 3.121875rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --gei-gutter-x: 4.996875rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --gei-gutter-y: 4.996875rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --gei-gutter-x: 6.24375rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --gei-gutter-y: 6.24375rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --gei-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --gei-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --gei-gutter-x: 0.46875rem;
  }

  .g-md-1,
.gy-md-1 {
    --gei-gutter-y: 0.46875rem;
  }

  .g-md-2,
.gx-md-2 {
    --gei-gutter-x: 0.9375rem;
  }

  .g-md-2,
.gy-md-2 {
    --gei-gutter-y: 0.9375rem;
  }

  .g-md-3,
.gx-md-3 {
    --gei-gutter-x: 1.875rem;
  }

  .g-md-3,
.gy-md-3 {
    --gei-gutter-y: 1.875rem;
  }

  .g-md-4,
.gx-md-4 {
    --gei-gutter-x: 3.121875rem;
  }

  .g-md-4,
.gy-md-4 {
    --gei-gutter-y: 3.121875rem;
  }

  .g-md-5,
.gx-md-5 {
    --gei-gutter-x: 4.996875rem;
  }

  .g-md-5,
.gy-md-5 {
    --gei-gutter-y: 4.996875rem;
  }

  .g-md-6,
.gx-md-6 {
    --gei-gutter-x: 6.24375rem;
  }

  .g-md-6,
.gy-md-6 {
    --gei-gutter-y: 6.24375rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --gei-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --gei-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --gei-gutter-x: 0.46875rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --gei-gutter-y: 0.46875rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --gei-gutter-x: 0.9375rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --gei-gutter-y: 0.9375rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --gei-gutter-x: 1.875rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --gei-gutter-y: 1.875rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --gei-gutter-x: 3.121875rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --gei-gutter-y: 3.121875rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --gei-gutter-x: 4.996875rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --gei-gutter-y: 4.996875rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --gei-gutter-x: 6.24375rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --gei-gutter-y: 6.24375rem;
  }
}
@media (min-width: 1025px) {
  .col-rn {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-rn-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-rn-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-rn-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-rn-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-rn-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-rn-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-rn-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-rn-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-rn-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-rn-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-rn-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-rn-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-rn-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-rn-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-rn-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-rn-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-rn-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-rn-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-rn-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-rn-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-rn-0 {
    margin-left: 0;
  }

  .offset-rn-1 {
    margin-left: 8.33333333%;
  }

  .offset-rn-2 {
    margin-left: 16.66666667%;
  }

  .offset-rn-3 {
    margin-left: 25%;
  }

  .offset-rn-4 {
    margin-left: 33.33333333%;
  }

  .offset-rn-5 {
    margin-left: 41.66666667%;
  }

  .offset-rn-6 {
    margin-left: 50%;
  }

  .offset-rn-7 {
    margin-left: 58.33333333%;
  }

  .offset-rn-8 {
    margin-left: 66.66666667%;
  }

  .offset-rn-9 {
    margin-left: 75%;
  }

  .offset-rn-10 {
    margin-left: 83.33333333%;
  }

  .offset-rn-11 {
    margin-left: 91.66666667%;
  }

  .g-rn-0,
.gx-rn-0 {
    --gei-gutter-x: 0;
  }

  .g-rn-0,
.gy-rn-0 {
    --gei-gutter-y: 0;
  }

  .g-rn-1,
.gx-rn-1 {
    --gei-gutter-x: 0.46875rem;
  }

  .g-rn-1,
.gy-rn-1 {
    --gei-gutter-y: 0.46875rem;
  }

  .g-rn-2,
.gx-rn-2 {
    --gei-gutter-x: 0.9375rem;
  }

  .g-rn-2,
.gy-rn-2 {
    --gei-gutter-y: 0.9375rem;
  }

  .g-rn-3,
.gx-rn-3 {
    --gei-gutter-x: 1.875rem;
  }

  .g-rn-3,
.gy-rn-3 {
    --gei-gutter-y: 1.875rem;
  }

  .g-rn-4,
.gx-rn-4 {
    --gei-gutter-x: 3.121875rem;
  }

  .g-rn-4,
.gy-rn-4 {
    --gei-gutter-y: 3.121875rem;
  }

  .g-rn-5,
.gx-rn-5 {
    --gei-gutter-x: 4.996875rem;
  }

  .g-rn-5,
.gy-rn-5 {
    --gei-gutter-y: 4.996875rem;
  }

  .g-rn-6,
.gx-rn-6 {
    --gei-gutter-x: 6.24375rem;
  }

  .g-rn-6,
.gy-rn-6 {
    --gei-gutter-y: 6.24375rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --gei-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --gei-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --gei-gutter-x: 0.46875rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --gei-gutter-y: 0.46875rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --gei-gutter-x: 0.9375rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --gei-gutter-y: 0.9375rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --gei-gutter-x: 1.875rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --gei-gutter-y: 1.875rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --gei-gutter-x: 3.121875rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --gei-gutter-y: 3.121875rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --gei-gutter-x: 4.996875rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --gei-gutter-y: 4.996875rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --gei-gutter-x: 6.24375rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --gei-gutter-y: 6.24375rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --gei-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --gei-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --gei-gutter-x: 0.46875rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --gei-gutter-y: 0.46875rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --gei-gutter-x: 0.9375rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --gei-gutter-y: 0.9375rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --gei-gutter-x: 1.875rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --gei-gutter-y: 1.875rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --gei-gutter-x: 3.121875rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --gei-gutter-y: 3.121875rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --gei-gutter-x: 4.996875rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --gei-gutter-y: 4.996875rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --gei-gutter-x: 6.24375rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --gei-gutter-y: 6.24375rem;
  }
}
@media (min-width: 1920px) {
  .col-3xl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-3xl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-3xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-3xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-3xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-3xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-3xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-3xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-3xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-3xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-3xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-3xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-3xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-3xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-3xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-3xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-3xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-3xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-3xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-3xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-3xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-3xl-0 {
    margin-left: 0;
  }

  .offset-3xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-3xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-3xl-3 {
    margin-left: 25%;
  }

  .offset-3xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-3xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-3xl-6 {
    margin-left: 50%;
  }

  .offset-3xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-3xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-3xl-9 {
    margin-left: 75%;
  }

  .offset-3xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-3xl-11 {
    margin-left: 91.66666667%;
  }

  .g-3xl-0,
.gx-3xl-0 {
    --gei-gutter-x: 0;
  }

  .g-3xl-0,
.gy-3xl-0 {
    --gei-gutter-y: 0;
  }

  .g-3xl-1,
.gx-3xl-1 {
    --gei-gutter-x: 0.46875rem;
  }

  .g-3xl-1,
.gy-3xl-1 {
    --gei-gutter-y: 0.46875rem;
  }

  .g-3xl-2,
.gx-3xl-2 {
    --gei-gutter-x: 0.9375rem;
  }

  .g-3xl-2,
.gy-3xl-2 {
    --gei-gutter-y: 0.9375rem;
  }

  .g-3xl-3,
.gx-3xl-3 {
    --gei-gutter-x: 1.875rem;
  }

  .g-3xl-3,
.gy-3xl-3 {
    --gei-gutter-y: 1.875rem;
  }

  .g-3xl-4,
.gx-3xl-4 {
    --gei-gutter-x: 3.121875rem;
  }

  .g-3xl-4,
.gy-3xl-4 {
    --gei-gutter-y: 3.121875rem;
  }

  .g-3xl-5,
.gx-3xl-5 {
    --gei-gutter-x: 4.996875rem;
  }

  .g-3xl-5,
.gy-3xl-5 {
    --gei-gutter-y: 4.996875rem;
  }

  .g-3xl-6,
.gx-3xl-6 {
    --gei-gutter-x: 6.24375rem;
  }

  .g-3xl-6,
.gy-3xl-6 {
    --gei-gutter-y: 6.24375rem;
  }
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-block {
  display: block;
}
.d-grid {
  display: grid;
}
.d-table {
  display: table;
}
.d-table-row {
  display: table-row;
}
.d-table-cell {
  display: table-cell;
}
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.d-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.d-none {
  display: none;
}
.flex-fill {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.flex-grow-0 {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.justify-content-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.justify-content-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.justify-content-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.justify-content-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.justify-content-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.justify-content-evenly {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.align-items-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.align-items-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.align-items-baseline {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.align-items-stretch {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.align-content-start {
  -ms-flex-line-pack: start;
      align-content: flex-start;
}
.align-content-end {
  -ms-flex-line-pack: end;
      align-content: flex-end;
}
.align-content-center {
  -ms-flex-line-pack: center;
      align-content: center;
}
.align-content-between {
  -ms-flex-line-pack: justify;
      align-content: space-between;
}
.align-content-around {
  -ms-flex-line-pack: distribute;
      align-content: space-around;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}
.align-self-auto {
  -ms-flex-item-align: auto;
      align-self: auto;
}
.align-self-start {
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.align-self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.align-self-center {
  -ms-flex-item-align: center;
      align-self: center;
}
.align-self-baseline {
  -ms-flex-item-align: baseline;
      align-self: baseline;
}
.align-self-stretch {
  -ms-flex-item-align: stretch;
      align-self: stretch;
}
.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}
.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}
.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}
.order-last {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.46875rem;
}
.m-2 {
  margin: 0.9375rem;
}
.m-3 {
  margin: 1.875rem;
}
.m-4 {
  margin: 3.121875rem;
}
.m-5 {
  margin: 4.996875rem;
}
.m-6 {
  margin: 6.24375rem;
}
.m-auto {
  margin: auto;
}
.mx-0 {
  margin-right: 0;
  margin-left: 0;
}
.mx-1 {
  margin-right: 0.46875rem;
  margin-left: 0.46875rem;
}
.mx-2 {
  margin-right: 0.9375rem;
  margin-left: 0.9375rem;
}
.mx-3 {
  margin-right: 1.875rem;
  margin-left: 1.875rem;
}
.mx-4 {
  margin-right: 3.121875rem;
  margin-left: 3.121875rem;
}
.mx-5 {
  margin-right: 4.996875rem;
  margin-left: 4.996875rem;
}
.mx-6 {
  margin-right: 6.24375rem;
  margin-left: 6.24375rem;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: 0.46875rem;
  margin-bottom: 0.46875rem;
}
.my-2 {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.my-3 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
.my-4 {
  margin-top: 3.121875rem;
  margin-bottom: 3.121875rem;
}
.my-5 {
  margin-top: 4.996875rem;
  margin-bottom: 4.996875rem;
}
.my-6 {
  margin-top: 6.24375rem;
  margin-bottom: 6.24375rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.46875rem;
}
.mt-2 {
  margin-top: 0.9375rem;
}
.mt-3 {
  margin-top: 1.875rem;
}
.mt-4 {
  margin-top: 3.121875rem;
}
.mt-5 {
  margin-top: 4.996875rem;
}
.mt-6 {
  margin-top: 6.24375rem;
}
.mt-auto {
  margin-top: auto;
}
.me-0 {
  margin-right: 0;
}
.me-1 {
  margin-right: 0.46875rem;
}
.me-2 {
  margin-right: 0.9375rem;
}
.me-3 {
  margin-right: 1.875rem;
}
.me-4 {
  margin-right: 3.121875rem;
}
.me-5 {
  margin-right: 4.996875rem;
}
.me-6 {
  margin-right: 6.24375rem;
}
.me-auto {
  margin-right: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.46875rem;
}
.mb-2 {
  margin-bottom: 0.9375rem;
}
.mb-3 {
  margin-bottom: 1.875rem;
}
.mb-4 {
  margin-bottom: 3.121875rem;
}
.mb-5 {
  margin-bottom: 4.996875rem;
}
.mb-6 {
  margin-bottom: 6.24375rem;
}
.mb-auto {
  margin-bottom: auto;
}
.ms-0 {
  margin-left: 0;
}
.ms-1 {
  margin-left: 0.46875rem;
}
.ms-2 {
  margin-left: 0.9375rem;
}
.ms-3 {
  margin-left: 1.875rem;
}
.ms-4 {
  margin-left: 3.121875rem;
}
.ms-5 {
  margin-left: 4.996875rem;
}
.ms-6 {
  margin-left: 6.24375rem;
}
.ms-auto {
  margin-left: auto;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.46875rem;
}
.p-2 {
  padding: 0.9375rem;
}
.p-3 {
  padding: 1.875rem;
}
.p-4 {
  padding: 3.121875rem;
}
.p-5 {
  padding: 4.996875rem;
}
.p-6 {
  padding: 6.24375rem;
}
.px-0 {
  padding-right: 0;
  padding-left: 0;
}
.px-1 {
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
.px-2 {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.px-3 {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}
.px-4 {
  padding-right: 3.121875rem;
  padding-left: 3.121875rem;
}
.px-5 {
  padding-right: 4.996875rem;
  padding-left: 4.996875rem;
}
.px-6 {
  padding-right: 6.24375rem;
  padding-left: 6.24375rem;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem;
}
.py-2 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.py-3 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.py-4 {
  padding-top: 3.121875rem;
  padding-bottom: 3.121875rem;
}
.py-5 {
  padding-top: 4.996875rem;
  padding-bottom: 4.996875rem;
}
.py-6 {
  padding-top: 6.24375rem;
  padding-bottom: 6.24375rem;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.46875rem;
}
.pt-2 {
  padding-top: 0.9375rem;
}
.pt-3 {
  padding-top: 1.875rem;
}
.pt-4 {
  padding-top: 3.121875rem;
}
.pt-5 {
  padding-top: 4.996875rem;
}
.pt-6 {
  padding-top: 6.24375rem;
}
.pe-0 {
  padding-right: 0;
}
.pe-1 {
  padding-right: 0.46875rem;
}
.pe-2 {
  padding-right: 0.9375rem;
}
.pe-3 {
  padding-right: 1.875rem;
}
.pe-4 {
  padding-right: 3.121875rem;
}
.pe-5 {
  padding-right: 4.996875rem;
}
.pe-6 {
  padding-right: 6.24375rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.46875rem;
}
.pb-2 {
  padding-bottom: 0.9375rem;
}
.pb-3 {
  padding-bottom: 1.875rem;
}
.pb-4 {
  padding-bottom: 3.121875rem;
}
.pb-5 {
  padding-bottom: 4.996875rem;
}
.pb-6 {
  padding-bottom: 6.24375rem;
}
.ps-0 {
  padding-left: 0;
}
.ps-1 {
  padding-left: 0.46875rem;
}
.ps-2 {
  padding-left: 0.9375rem;
}
.ps-3 {
  padding-left: 1.875rem;
}
.ps-4 {
  padding-left: 3.121875rem;
}
.ps-5 {
  padding-left: 4.996875rem;
}
.ps-6 {
  padding-left: 6.24375rem;
}
.text-start {
  text-align: left;
}
.text-end {
  text-align: right;
}
.text-center {
  text-align: center;
}
@media (min-width: 576px) {
  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-grid {
    display: grid;
  }

  .d-sm-table {
    display: table;
  }

  .d-sm-table-row {
    display: table-row;
  }

  .d-sm-table-cell {
    display: table-cell;
  }

  .d-sm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-sm-none {
    display: none;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .flex-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1;
        flex-shrink: 1;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .align-items-sm-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .align-items-sm-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .align-items-sm-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center;
        align-content: center;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto;
        align-self: auto;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center;
        align-self: center;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .m-sm-0 {
    margin: 0;
  }

  .m-sm-1 {
    margin: 0.46875rem;
  }

  .m-sm-2 {
    margin: 0.9375rem;
  }

  .m-sm-3 {
    margin: 1.875rem;
  }

  .m-sm-4 {
    margin: 3.121875rem;
  }

  .m-sm-5 {
    margin: 4.996875rem;
  }

  .m-sm-6 {
    margin: 6.24375rem;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-sm-1 {
    margin-right: 0.46875rem;
    margin-left: 0.46875rem;
  }

  .mx-sm-2 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }

  .mx-sm-3 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  .mx-sm-4 {
    margin-right: 3.121875rem;
    margin-left: 3.121875rem;
  }

  .mx-sm-5 {
    margin-right: 4.996875rem;
    margin-left: 4.996875rem;
  }

  .mx-sm-6 {
    margin-right: 6.24375rem;
    margin-left: 6.24375rem;
  }

  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-sm-1 {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }

  .my-sm-2 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }

  .my-sm-3 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .my-sm-4 {
    margin-top: 3.121875rem;
    margin-bottom: 3.121875rem;
  }

  .my-sm-5 {
    margin-top: 4.996875rem;
    margin-bottom: 4.996875rem;
  }

  .my-sm-6 {
    margin-top: 6.24375rem;
    margin-bottom: 6.24375rem;
  }

  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mt-sm-1 {
    margin-top: 0.46875rem;
  }

  .mt-sm-2 {
    margin-top: 0.9375rem;
  }

  .mt-sm-3 {
    margin-top: 1.875rem;
  }

  .mt-sm-4 {
    margin-top: 3.121875rem;
  }

  .mt-sm-5 {
    margin-top: 4.996875rem;
  }

  .mt-sm-6 {
    margin-top: 6.24375rem;
  }

  .mt-sm-auto {
    margin-top: auto;
  }

  .me-sm-0 {
    margin-right: 0;
  }

  .me-sm-1 {
    margin-right: 0.46875rem;
  }

  .me-sm-2 {
    margin-right: 0.9375rem;
  }

  .me-sm-3 {
    margin-right: 1.875rem;
  }

  .me-sm-4 {
    margin-right: 3.121875rem;
  }

  .me-sm-5 {
    margin-right: 4.996875rem;
  }

  .me-sm-6 {
    margin-right: 6.24375rem;
  }

  .me-sm-auto {
    margin-right: auto;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  .mb-sm-1 {
    margin-bottom: 0.46875rem;
  }

  .mb-sm-2 {
    margin-bottom: 0.9375rem;
  }

  .mb-sm-3 {
    margin-bottom: 1.875rem;
  }

  .mb-sm-4 {
    margin-bottom: 3.121875rem;
  }

  .mb-sm-5 {
    margin-bottom: 4.996875rem;
  }

  .mb-sm-6 {
    margin-bottom: 6.24375rem;
  }

  .mb-sm-auto {
    margin-bottom: auto;
  }

  .ms-sm-0 {
    margin-left: 0;
  }

  .ms-sm-1 {
    margin-left: 0.46875rem;
  }

  .ms-sm-2 {
    margin-left: 0.9375rem;
  }

  .ms-sm-3 {
    margin-left: 1.875rem;
  }

  .ms-sm-4 {
    margin-left: 3.121875rem;
  }

  .ms-sm-5 {
    margin-left: 4.996875rem;
  }

  .ms-sm-6 {
    margin-left: 6.24375rem;
  }

  .ms-sm-auto {
    margin-left: auto;
  }

  .p-sm-0 {
    padding: 0;
  }

  .p-sm-1 {
    padding: 0.46875rem;
  }

  .p-sm-2 {
    padding: 0.9375rem;
  }

  .p-sm-3 {
    padding: 1.875rem;
  }

  .p-sm-4 {
    padding: 3.121875rem;
  }

  .p-sm-5 {
    padding: 4.996875rem;
  }

  .p-sm-6 {
    padding: 6.24375rem;
  }

  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-sm-1 {
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }

  .px-sm-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .px-sm-3 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  .px-sm-4 {
    padding-right: 3.121875rem;
    padding-left: 3.121875rem;
  }

  .px-sm-5 {
    padding-right: 4.996875rem;
    padding-left: 4.996875rem;
  }

  .px-sm-6 {
    padding-right: 6.24375rem;
    padding-left: 6.24375rem;
  }

  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-sm-1 {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }

  .py-sm-2 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .py-sm-3 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .py-sm-4 {
    padding-top: 3.121875rem;
    padding-bottom: 3.121875rem;
  }

  .py-sm-5 {
    padding-top: 4.996875rem;
    padding-bottom: 4.996875rem;
  }

  .py-sm-6 {
    padding-top: 6.24375rem;
    padding-bottom: 6.24375rem;
  }

  .pt-sm-0 {
    padding-top: 0;
  }

  .pt-sm-1 {
    padding-top: 0.46875rem;
  }

  .pt-sm-2 {
    padding-top: 0.9375rem;
  }

  .pt-sm-3 {
    padding-top: 1.875rem;
  }

  .pt-sm-4 {
    padding-top: 3.121875rem;
  }

  .pt-sm-5 {
    padding-top: 4.996875rem;
  }

  .pt-sm-6 {
    padding-top: 6.24375rem;
  }

  .pe-sm-0 {
    padding-right: 0;
  }

  .pe-sm-1 {
    padding-right: 0.46875rem;
  }

  .pe-sm-2 {
    padding-right: 0.9375rem;
  }

  .pe-sm-3 {
    padding-right: 1.875rem;
  }

  .pe-sm-4 {
    padding-right: 3.121875rem;
  }

  .pe-sm-5 {
    padding-right: 4.996875rem;
  }

  .pe-sm-6 {
    padding-right: 6.24375rem;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .pb-sm-1 {
    padding-bottom: 0.46875rem;
  }

  .pb-sm-2 {
    padding-bottom: 0.9375rem;
  }

  .pb-sm-3 {
    padding-bottom: 1.875rem;
  }

  .pb-sm-4 {
    padding-bottom: 3.121875rem;
  }

  .pb-sm-5 {
    padding-bottom: 4.996875rem;
  }

  .pb-sm-6 {
    padding-bottom: 6.24375rem;
  }

  .ps-sm-0 {
    padding-left: 0;
  }

  .ps-sm-1 {
    padding-left: 0.46875rem;
  }

  .ps-sm-2 {
    padding-left: 0.9375rem;
  }

  .ps-sm-3 {
    padding-left: 1.875rem;
  }

  .ps-sm-4 {
    padding-left: 3.121875rem;
  }

  .ps-sm-5 {
    padding-left: 4.996875rem;
  }

  .ps-sm-6 {
    padding-left: 6.24375rem;
  }

  .text-sm-start {
    text-align: left;
  }

  .text-sm-end {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-grid {
    display: grid;
  }

  .d-md-table {
    display: table;
  }

  .d-md-table-row {
    display: table-row;
  }

  .d-md-table-cell {
    display: table-cell;
  }

  .d-md-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-md-none {
    display: none;
  }

  .flex-md-fill {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .flex-md-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .flex-md-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1;
        flex-shrink: 1;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .justify-content-md-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .justify-content-md-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .justify-content-md-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .align-items-md-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .align-items-md-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .align-items-md-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center;
        align-content: center;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto;
        align-self: auto;
  }

  .align-self-md-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .align-self-md-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .align-self-md-center {
    -ms-flex-item-align: center;
        align-self: center;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .m-md-0 {
    margin: 0;
  }

  .m-md-1 {
    margin: 0.46875rem;
  }

  .m-md-2 {
    margin: 0.9375rem;
  }

  .m-md-3 {
    margin: 1.875rem;
  }

  .m-md-4 {
    margin: 3.121875rem;
  }

  .m-md-5 {
    margin: 4.996875rem;
  }

  .m-md-6 {
    margin: 6.24375rem;
  }

  .m-md-auto {
    margin: auto;
  }

  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-md-1 {
    margin-right: 0.46875rem;
    margin-left: 0.46875rem;
  }

  .mx-md-2 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }

  .mx-md-3 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  .mx-md-4 {
    margin-right: 3.121875rem;
    margin-left: 3.121875rem;
  }

  .mx-md-5 {
    margin-right: 4.996875rem;
    margin-left: 4.996875rem;
  }

  .mx-md-6 {
    margin-right: 6.24375rem;
    margin-left: 6.24375rem;
  }

  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-md-1 {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }

  .my-md-2 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }

  .my-md-3 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .my-md-4 {
    margin-top: 3.121875rem;
    margin-bottom: 3.121875rem;
  }

  .my-md-5 {
    margin-top: 4.996875rem;
    margin-bottom: 4.996875rem;
  }

  .my-md-6 {
    margin-top: 6.24375rem;
    margin-bottom: 6.24375rem;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mt-md-1 {
    margin-top: 0.46875rem;
  }

  .mt-md-2 {
    margin-top: 0.9375rem;
  }

  .mt-md-3 {
    margin-top: 1.875rem;
  }

  .mt-md-4 {
    margin-top: 3.121875rem;
  }

  .mt-md-5 {
    margin-top: 4.996875rem;
  }

  .mt-md-6 {
    margin-top: 6.24375rem;
  }

  .mt-md-auto {
    margin-top: auto;
  }

  .me-md-0 {
    margin-right: 0;
  }

  .me-md-1 {
    margin-right: 0.46875rem;
  }

  .me-md-2 {
    margin-right: 0.9375rem;
  }

  .me-md-3 {
    margin-right: 1.875rem;
  }

  .me-md-4 {
    margin-right: 3.121875rem;
  }

  .me-md-5 {
    margin-right: 4.996875rem;
  }

  .me-md-6 {
    margin-right: 6.24375rem;
  }

  .me-md-auto {
    margin-right: auto;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .mb-md-1 {
    margin-bottom: 0.46875rem;
  }

  .mb-md-2 {
    margin-bottom: 0.9375rem;
  }

  .mb-md-3 {
    margin-bottom: 1.875rem;
  }

  .mb-md-4 {
    margin-bottom: 3.121875rem;
  }

  .mb-md-5 {
    margin-bottom: 4.996875rem;
  }

  .mb-md-6 {
    margin-bottom: 6.24375rem;
  }

  .mb-md-auto {
    margin-bottom: auto;
  }

  .ms-md-0 {
    margin-left: 0;
  }

  .ms-md-1 {
    margin-left: 0.46875rem;
  }

  .ms-md-2 {
    margin-left: 0.9375rem;
  }

  .ms-md-3 {
    margin-left: 1.875rem;
  }

  .ms-md-4 {
    margin-left: 3.121875rem;
  }

  .ms-md-5 {
    margin-left: 4.996875rem;
  }

  .ms-md-6 {
    margin-left: 6.24375rem;
  }

  .ms-md-auto {
    margin-left: auto;
  }

  .p-md-0 {
    padding: 0;
  }

  .p-md-1 {
    padding: 0.46875rem;
  }

  .p-md-2 {
    padding: 0.9375rem;
  }

  .p-md-3 {
    padding: 1.875rem;
  }

  .p-md-4 {
    padding: 3.121875rem;
  }

  .p-md-5 {
    padding: 4.996875rem;
  }

  .p-md-6 {
    padding: 6.24375rem;
  }

  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-md-1 {
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }

  .px-md-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .px-md-3 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  .px-md-4 {
    padding-right: 3.121875rem;
    padding-left: 3.121875rem;
  }

  .px-md-5 {
    padding-right: 4.996875rem;
    padding-left: 4.996875rem;
  }

  .px-md-6 {
    padding-right: 6.24375rem;
    padding-left: 6.24375rem;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-md-1 {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }

  .py-md-2 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .py-md-3 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .py-md-4 {
    padding-top: 3.121875rem;
    padding-bottom: 3.121875rem;
  }

  .py-md-5 {
    padding-top: 4.996875rem;
    padding-bottom: 4.996875rem;
  }

  .py-md-6 {
    padding-top: 6.24375rem;
    padding-bottom: 6.24375rem;
  }

  .pt-md-0 {
    padding-top: 0;
  }

  .pt-md-1 {
    padding-top: 0.46875rem;
  }

  .pt-md-2 {
    padding-top: 0.9375rem;
  }

  .pt-md-3 {
    padding-top: 1.875rem;
  }

  .pt-md-4 {
    padding-top: 3.121875rem;
  }

  .pt-md-5 {
    padding-top: 4.996875rem;
  }

  .pt-md-6 {
    padding-top: 6.24375rem;
  }

  .pe-md-0 {
    padding-right: 0;
  }

  .pe-md-1 {
    padding-right: 0.46875rem;
  }

  .pe-md-2 {
    padding-right: 0.9375rem;
  }

  .pe-md-3 {
    padding-right: 1.875rem;
  }

  .pe-md-4 {
    padding-right: 3.121875rem;
  }

  .pe-md-5 {
    padding-right: 4.996875rem;
  }

  .pe-md-6 {
    padding-right: 6.24375rem;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }

  .pb-md-1 {
    padding-bottom: 0.46875rem;
  }

  .pb-md-2 {
    padding-bottom: 0.9375rem;
  }

  .pb-md-3 {
    padding-bottom: 1.875rem;
  }

  .pb-md-4 {
    padding-bottom: 3.121875rem;
  }

  .pb-md-5 {
    padding-bottom: 4.996875rem;
  }

  .pb-md-6 {
    padding-bottom: 6.24375rem;
  }

  .ps-md-0 {
    padding-left: 0;
  }

  .ps-md-1 {
    padding-left: 0.46875rem;
  }

  .ps-md-2 {
    padding-left: 0.9375rem;
  }

  .ps-md-3 {
    padding-left: 1.875rem;
  }

  .ps-md-4 {
    padding-left: 3.121875rem;
  }

  .ps-md-5 {
    padding-left: 4.996875rem;
  }

  .ps-md-6 {
    padding-left: 6.24375rem;
  }

  .text-md-start {
    text-align: left;
  }

  .text-md-end {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-grid {
    display: grid;
  }

  .d-lg-table {
    display: table;
  }

  .d-lg-table-row {
    display: table-row;
  }

  .d-lg-table-cell {
    display: table-cell;
  }

  .d-lg-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .flex-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1;
        flex-shrink: 1;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .align-items-lg-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .align-items-lg-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .align-items-lg-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center;
        align-content: center;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto;
        align-self: auto;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center;
        align-self: center;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .m-lg-0 {
    margin: 0;
  }

  .m-lg-1 {
    margin: 0.46875rem;
  }

  .m-lg-2 {
    margin: 0.9375rem;
  }

  .m-lg-3 {
    margin: 1.875rem;
  }

  .m-lg-4 {
    margin: 3.121875rem;
  }

  .m-lg-5 {
    margin: 4.996875rem;
  }

  .m-lg-6 {
    margin: 6.24375rem;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-lg-1 {
    margin-right: 0.46875rem;
    margin-left: 0.46875rem;
  }

  .mx-lg-2 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }

  .mx-lg-3 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  .mx-lg-4 {
    margin-right: 3.121875rem;
    margin-left: 3.121875rem;
  }

  .mx-lg-5 {
    margin-right: 4.996875rem;
    margin-left: 4.996875rem;
  }

  .mx-lg-6 {
    margin-right: 6.24375rem;
    margin-left: 6.24375rem;
  }

  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-lg-1 {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }

  .my-lg-2 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }

  .my-lg-3 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .my-lg-4 {
    margin-top: 3.121875rem;
    margin-bottom: 3.121875rem;
  }

  .my-lg-5 {
    margin-top: 4.996875rem;
    margin-bottom: 4.996875rem;
  }

  .my-lg-6 {
    margin-top: 6.24375rem;
    margin-bottom: 6.24375rem;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .mt-lg-1 {
    margin-top: 0.46875rem;
  }

  .mt-lg-2 {
    margin-top: 0.9375rem;
  }

  .mt-lg-3 {
    margin-top: 1.875rem;
  }

  .mt-lg-4 {
    margin-top: 3.121875rem;
  }

  .mt-lg-5 {
    margin-top: 4.996875rem;
  }

  .mt-lg-6 {
    margin-top: 6.24375rem;
  }

  .mt-lg-auto {
    margin-top: auto;
  }

  .me-lg-0 {
    margin-right: 0;
  }

  .me-lg-1 {
    margin-right: 0.46875rem;
  }

  .me-lg-2 {
    margin-right: 0.9375rem;
  }

  .me-lg-3 {
    margin-right: 1.875rem;
  }

  .me-lg-4 {
    margin-right: 3.121875rem;
  }

  .me-lg-5 {
    margin-right: 4.996875rem;
  }

  .me-lg-6 {
    margin-right: 6.24375rem;
  }

  .me-lg-auto {
    margin-right: auto;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .mb-lg-1 {
    margin-bottom: 0.46875rem;
  }

  .mb-lg-2 {
    margin-bottom: 0.9375rem;
  }

  .mb-lg-3 {
    margin-bottom: 1.875rem;
  }

  .mb-lg-4 {
    margin-bottom: 3.121875rem;
  }

  .mb-lg-5 {
    margin-bottom: 4.996875rem;
  }

  .mb-lg-6 {
    margin-bottom: 6.24375rem;
  }

  .mb-lg-auto {
    margin-bottom: auto;
  }

  .ms-lg-0 {
    margin-left: 0;
  }

  .ms-lg-1 {
    margin-left: 0.46875rem;
  }

  .ms-lg-2 {
    margin-left: 0.9375rem;
  }

  .ms-lg-3 {
    margin-left: 1.875rem;
  }

  .ms-lg-4 {
    margin-left: 3.121875rem;
  }

  .ms-lg-5 {
    margin-left: 4.996875rem;
  }

  .ms-lg-6 {
    margin-left: 6.24375rem;
  }

  .ms-lg-auto {
    margin-left: auto;
  }

  .p-lg-0 {
    padding: 0;
  }

  .p-lg-1 {
    padding: 0.46875rem;
  }

  .p-lg-2 {
    padding: 0.9375rem;
  }

  .p-lg-3 {
    padding: 1.875rem;
  }

  .p-lg-4 {
    padding: 3.121875rem;
  }

  .p-lg-5 {
    padding: 4.996875rem;
  }

  .p-lg-6 {
    padding: 6.24375rem;
  }

  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-lg-1 {
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }

  .px-lg-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .px-lg-3 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  .px-lg-4 {
    padding-right: 3.121875rem;
    padding-left: 3.121875rem;
  }

  .px-lg-5 {
    padding-right: 4.996875rem;
    padding-left: 4.996875rem;
  }

  .px-lg-6 {
    padding-right: 6.24375rem;
    padding-left: 6.24375rem;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-lg-1 {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }

  .py-lg-2 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .py-lg-3 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .py-lg-4 {
    padding-top: 3.121875rem;
    padding-bottom: 3.121875rem;
  }

  .py-lg-5 {
    padding-top: 4.996875rem;
    padding-bottom: 4.996875rem;
  }

  .py-lg-6 {
    padding-top: 6.24375rem;
    padding-bottom: 6.24375rem;
  }

  .pt-lg-0 {
    padding-top: 0;
  }

  .pt-lg-1 {
    padding-top: 0.46875rem;
  }

  .pt-lg-2 {
    padding-top: 0.9375rem;
  }

  .pt-lg-3 {
    padding-top: 1.875rem;
  }

  .pt-lg-4 {
    padding-top: 3.121875rem;
  }

  .pt-lg-5 {
    padding-top: 4.996875rem;
  }

  .pt-lg-6 {
    padding-top: 6.24375rem;
  }

  .pe-lg-0 {
    padding-right: 0;
  }

  .pe-lg-1 {
    padding-right: 0.46875rem;
  }

  .pe-lg-2 {
    padding-right: 0.9375rem;
  }

  .pe-lg-3 {
    padding-right: 1.875rem;
  }

  .pe-lg-4 {
    padding-right: 3.121875rem;
  }

  .pe-lg-5 {
    padding-right: 4.996875rem;
  }

  .pe-lg-6 {
    padding-right: 6.24375rem;
  }

  .pb-lg-0 {
    padding-bottom: 0;
  }

  .pb-lg-1 {
    padding-bottom: 0.46875rem;
  }

  .pb-lg-2 {
    padding-bottom: 0.9375rem;
  }

  .pb-lg-3 {
    padding-bottom: 1.875rem;
  }

  .pb-lg-4 {
    padding-bottom: 3.121875rem;
  }

  .pb-lg-5 {
    padding-bottom: 4.996875rem;
  }

  .pb-lg-6 {
    padding-bottom: 6.24375rem;
  }

  .ps-lg-0 {
    padding-left: 0;
  }

  .ps-lg-1 {
    padding-left: 0.46875rem;
  }

  .ps-lg-2 {
    padding-left: 0.9375rem;
  }

  .ps-lg-3 {
    padding-left: 1.875rem;
  }

  .ps-lg-4 {
    padding-left: 3.121875rem;
  }

  .ps-lg-5 {
    padding-left: 4.996875rem;
  }

  .ps-lg-6 {
    padding-left: 6.24375rem;
  }

  .text-lg-start {
    text-align: left;
  }

  .text-lg-end {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1025px) {
  .d-rn-inline {
    display: inline;
  }

  .d-rn-inline-block {
    display: inline-block;
  }

  .d-rn-block {
    display: block;
  }

  .d-rn-grid {
    display: grid;
  }

  .d-rn-table {
    display: table;
  }

  .d-rn-table-row {
    display: table-row;
  }

  .d-rn-table-cell {
    display: table-cell;
  }

  .d-rn-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-rn-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-rn-none {
    display: none;
  }

  .flex-rn-fill {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .flex-rn-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .flex-rn-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .flex-rn-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .flex-rn-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .flex-rn-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .flex-rn-grow-1 {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .flex-rn-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .flex-rn-shrink-1 {
    -ms-flex-negative: 1;
        flex-shrink: 1;
  }

  .flex-rn-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .flex-rn-nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }

  .flex-rn-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .justify-content-rn-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .justify-content-rn-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .justify-content-rn-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .justify-content-rn-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .justify-content-rn-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .justify-content-rn-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .align-items-rn-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .align-items-rn-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .align-items-rn-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-rn-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .align-items-rn-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .align-content-rn-start {
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }

  .align-content-rn-end {
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

  .align-content-rn-center {
    -ms-flex-line-pack: center;
        align-content: center;
  }

  .align-content-rn-between {
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }

  .align-content-rn-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }

  .align-content-rn-stretch {
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }

  .align-self-rn-auto {
    -ms-flex-item-align: auto;
        align-self: auto;
  }

  .align-self-rn-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .align-self-rn-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .align-self-rn-center {
    -ms-flex-item-align: center;
        align-self: center;
  }

  .align-self-rn-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }

  .align-self-rn-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }

  .order-rn-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-rn-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-rn-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-rn-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-rn-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-rn-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-rn-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-rn-last {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .m-rn-0 {
    margin: 0;
  }

  .m-rn-1 {
    margin: 0.46875rem;
  }

  .m-rn-2 {
    margin: 0.9375rem;
  }

  .m-rn-3 {
    margin: 1.875rem;
  }

  .m-rn-4 {
    margin: 3.121875rem;
  }

  .m-rn-5 {
    margin: 4.996875rem;
  }

  .m-rn-6 {
    margin: 6.24375rem;
  }

  .m-rn-auto {
    margin: auto;
  }

  .mx-rn-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-rn-1 {
    margin-right: 0.46875rem;
    margin-left: 0.46875rem;
  }

  .mx-rn-2 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }

  .mx-rn-3 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  .mx-rn-4 {
    margin-right: 3.121875rem;
    margin-left: 3.121875rem;
  }

  .mx-rn-5 {
    margin-right: 4.996875rem;
    margin-left: 4.996875rem;
  }

  .mx-rn-6 {
    margin-right: 6.24375rem;
    margin-left: 6.24375rem;
  }

  .mx-rn-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-rn-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-rn-1 {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }

  .my-rn-2 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }

  .my-rn-3 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .my-rn-4 {
    margin-top: 3.121875rem;
    margin-bottom: 3.121875rem;
  }

  .my-rn-5 {
    margin-top: 4.996875rem;
    margin-bottom: 4.996875rem;
  }

  .my-rn-6 {
    margin-top: 6.24375rem;
    margin-bottom: 6.24375rem;
  }

  .my-rn-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-rn-0 {
    margin-top: 0;
  }

  .mt-rn-1 {
    margin-top: 0.46875rem;
  }

  .mt-rn-2 {
    margin-top: 0.9375rem;
  }

  .mt-rn-3 {
    margin-top: 1.875rem;
  }

  .mt-rn-4 {
    margin-top: 3.121875rem;
  }

  .mt-rn-5 {
    margin-top: 4.996875rem;
  }

  .mt-rn-6 {
    margin-top: 6.24375rem;
  }

  .mt-rn-auto {
    margin-top: auto;
  }

  .me-rn-0 {
    margin-right: 0;
  }

  .me-rn-1 {
    margin-right: 0.46875rem;
  }

  .me-rn-2 {
    margin-right: 0.9375rem;
  }

  .me-rn-3 {
    margin-right: 1.875rem;
  }

  .me-rn-4 {
    margin-right: 3.121875rem;
  }

  .me-rn-5 {
    margin-right: 4.996875rem;
  }

  .me-rn-6 {
    margin-right: 6.24375rem;
  }

  .me-rn-auto {
    margin-right: auto;
  }

  .mb-rn-0 {
    margin-bottom: 0;
  }

  .mb-rn-1 {
    margin-bottom: 0.46875rem;
  }

  .mb-rn-2 {
    margin-bottom: 0.9375rem;
  }

  .mb-rn-3 {
    margin-bottom: 1.875rem;
  }

  .mb-rn-4 {
    margin-bottom: 3.121875rem;
  }

  .mb-rn-5 {
    margin-bottom: 4.996875rem;
  }

  .mb-rn-6 {
    margin-bottom: 6.24375rem;
  }

  .mb-rn-auto {
    margin-bottom: auto;
  }

  .ms-rn-0 {
    margin-left: 0;
  }

  .ms-rn-1 {
    margin-left: 0.46875rem;
  }

  .ms-rn-2 {
    margin-left: 0.9375rem;
  }

  .ms-rn-3 {
    margin-left: 1.875rem;
  }

  .ms-rn-4 {
    margin-left: 3.121875rem;
  }

  .ms-rn-5 {
    margin-left: 4.996875rem;
  }

  .ms-rn-6 {
    margin-left: 6.24375rem;
  }

  .ms-rn-auto {
    margin-left: auto;
  }

  .p-rn-0 {
    padding: 0;
  }

  .p-rn-1 {
    padding: 0.46875rem;
  }

  .p-rn-2 {
    padding: 0.9375rem;
  }

  .p-rn-3 {
    padding: 1.875rem;
  }

  .p-rn-4 {
    padding: 3.121875rem;
  }

  .p-rn-5 {
    padding: 4.996875rem;
  }

  .p-rn-6 {
    padding: 6.24375rem;
  }

  .px-rn-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-rn-1 {
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }

  .px-rn-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .px-rn-3 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  .px-rn-4 {
    padding-right: 3.121875rem;
    padding-left: 3.121875rem;
  }

  .px-rn-5 {
    padding-right: 4.996875rem;
    padding-left: 4.996875rem;
  }

  .px-rn-6 {
    padding-right: 6.24375rem;
    padding-left: 6.24375rem;
  }

  .py-rn-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-rn-1 {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }

  .py-rn-2 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .py-rn-3 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .py-rn-4 {
    padding-top: 3.121875rem;
    padding-bottom: 3.121875rem;
  }

  .py-rn-5 {
    padding-top: 4.996875rem;
    padding-bottom: 4.996875rem;
  }

  .py-rn-6 {
    padding-top: 6.24375rem;
    padding-bottom: 6.24375rem;
  }

  .pt-rn-0 {
    padding-top: 0;
  }

  .pt-rn-1 {
    padding-top: 0.46875rem;
  }

  .pt-rn-2 {
    padding-top: 0.9375rem;
  }

  .pt-rn-3 {
    padding-top: 1.875rem;
  }

  .pt-rn-4 {
    padding-top: 3.121875rem;
  }

  .pt-rn-5 {
    padding-top: 4.996875rem;
  }

  .pt-rn-6 {
    padding-top: 6.24375rem;
  }

  .pe-rn-0 {
    padding-right: 0;
  }

  .pe-rn-1 {
    padding-right: 0.46875rem;
  }

  .pe-rn-2 {
    padding-right: 0.9375rem;
  }

  .pe-rn-3 {
    padding-right: 1.875rem;
  }

  .pe-rn-4 {
    padding-right: 3.121875rem;
  }

  .pe-rn-5 {
    padding-right: 4.996875rem;
  }

  .pe-rn-6 {
    padding-right: 6.24375rem;
  }

  .pb-rn-0 {
    padding-bottom: 0;
  }

  .pb-rn-1 {
    padding-bottom: 0.46875rem;
  }

  .pb-rn-2 {
    padding-bottom: 0.9375rem;
  }

  .pb-rn-3 {
    padding-bottom: 1.875rem;
  }

  .pb-rn-4 {
    padding-bottom: 3.121875rem;
  }

  .pb-rn-5 {
    padding-bottom: 4.996875rem;
  }

  .pb-rn-6 {
    padding-bottom: 6.24375rem;
  }

  .ps-rn-0 {
    padding-left: 0;
  }

  .ps-rn-1 {
    padding-left: 0.46875rem;
  }

  .ps-rn-2 {
    padding-left: 0.9375rem;
  }

  .ps-rn-3 {
    padding-left: 1.875rem;
  }

  .ps-rn-4 {
    padding-left: 3.121875rem;
  }

  .ps-rn-5 {
    padding-left: 4.996875rem;
  }

  .ps-rn-6 {
    padding-left: 6.24375rem;
  }

  .text-rn-start {
    text-align: left;
  }

  .text-rn-end {
    text-align: right;
  }

  .text-rn-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-grid {
    display: grid;
  }

  .d-xl-table {
    display: table;
  }

  .d-xl-table-row {
    display: table-row;
  }

  .d-xl-table-cell {
    display: table-cell;
  }

  .d-xl-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-xl-none {
    display: none;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .flex-xl-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1;
        flex-shrink: 1;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .align-items-xl-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .align-items-xl-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .align-items-xl-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center;
        align-content: center;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto;
        align-self: auto;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center;
        align-self: center;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .m-xl-0 {
    margin: 0;
  }

  .m-xl-1 {
    margin: 0.46875rem;
  }

  .m-xl-2 {
    margin: 0.9375rem;
  }

  .m-xl-3 {
    margin: 1.875rem;
  }

  .m-xl-4 {
    margin: 3.121875rem;
  }

  .m-xl-5 {
    margin: 4.996875rem;
  }

  .m-xl-6 {
    margin: 6.24375rem;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-xl-1 {
    margin-right: 0.46875rem;
    margin-left: 0.46875rem;
  }

  .mx-xl-2 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }

  .mx-xl-3 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  .mx-xl-4 {
    margin-right: 3.121875rem;
    margin-left: 3.121875rem;
  }

  .mx-xl-5 {
    margin-right: 4.996875rem;
    margin-left: 4.996875rem;
  }

  .mx-xl-6 {
    margin-right: 6.24375rem;
    margin-left: 6.24375rem;
  }

  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xl-1 {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }

  .my-xl-2 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }

  .my-xl-3 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .my-xl-4 {
    margin-top: 3.121875rem;
    margin-bottom: 3.121875rem;
  }

  .my-xl-5 {
    margin-top: 4.996875rem;
    margin-bottom: 4.996875rem;
  }

  .my-xl-6 {
    margin-top: 6.24375rem;
    margin-bottom: 6.24375rem;
  }

  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xl-0 {
    margin-top: 0;
  }

  .mt-xl-1 {
    margin-top: 0.46875rem;
  }

  .mt-xl-2 {
    margin-top: 0.9375rem;
  }

  .mt-xl-3 {
    margin-top: 1.875rem;
  }

  .mt-xl-4 {
    margin-top: 3.121875rem;
  }

  .mt-xl-5 {
    margin-top: 4.996875rem;
  }

  .mt-xl-6 {
    margin-top: 6.24375rem;
  }

  .mt-xl-auto {
    margin-top: auto;
  }

  .me-xl-0 {
    margin-right: 0;
  }

  .me-xl-1 {
    margin-right: 0.46875rem;
  }

  .me-xl-2 {
    margin-right: 0.9375rem;
  }

  .me-xl-3 {
    margin-right: 1.875rem;
  }

  .me-xl-4 {
    margin-right: 3.121875rem;
  }

  .me-xl-5 {
    margin-right: 4.996875rem;
  }

  .me-xl-6 {
    margin-right: 6.24375rem;
  }

  .me-xl-auto {
    margin-right: auto;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .mb-xl-1 {
    margin-bottom: 0.46875rem;
  }

  .mb-xl-2 {
    margin-bottom: 0.9375rem;
  }

  .mb-xl-3 {
    margin-bottom: 1.875rem;
  }

  .mb-xl-4 {
    margin-bottom: 3.121875rem;
  }

  .mb-xl-5 {
    margin-bottom: 4.996875rem;
  }

  .mb-xl-6 {
    margin-bottom: 6.24375rem;
  }

  .mb-xl-auto {
    margin-bottom: auto;
  }

  .ms-xl-0 {
    margin-left: 0;
  }

  .ms-xl-1 {
    margin-left: 0.46875rem;
  }

  .ms-xl-2 {
    margin-left: 0.9375rem;
  }

  .ms-xl-3 {
    margin-left: 1.875rem;
  }

  .ms-xl-4 {
    margin-left: 3.121875rem;
  }

  .ms-xl-5 {
    margin-left: 4.996875rem;
  }

  .ms-xl-6 {
    margin-left: 6.24375rem;
  }

  .ms-xl-auto {
    margin-left: auto;
  }

  .p-xl-0 {
    padding: 0;
  }

  .p-xl-1 {
    padding: 0.46875rem;
  }

  .p-xl-2 {
    padding: 0.9375rem;
  }

  .p-xl-3 {
    padding: 1.875rem;
  }

  .p-xl-4 {
    padding: 3.121875rem;
  }

  .p-xl-5 {
    padding: 4.996875rem;
  }

  .p-xl-6 {
    padding: 6.24375rem;
  }

  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-xl-1 {
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }

  .px-xl-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .px-xl-3 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  .px-xl-4 {
    padding-right: 3.121875rem;
    padding-left: 3.121875rem;
  }

  .px-xl-5 {
    padding-right: 4.996875rem;
    padding-left: 4.996875rem;
  }

  .px-xl-6 {
    padding-right: 6.24375rem;
    padding-left: 6.24375rem;
  }

  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xl-1 {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }

  .py-xl-2 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .py-xl-3 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .py-xl-4 {
    padding-top: 3.121875rem;
    padding-bottom: 3.121875rem;
  }

  .py-xl-5 {
    padding-top: 4.996875rem;
    padding-bottom: 4.996875rem;
  }

  .py-xl-6 {
    padding-top: 6.24375rem;
    padding-bottom: 6.24375rem;
  }

  .pt-xl-0 {
    padding-top: 0;
  }

  .pt-xl-1 {
    padding-top: 0.46875rem;
  }

  .pt-xl-2 {
    padding-top: 0.9375rem;
  }

  .pt-xl-3 {
    padding-top: 1.875rem;
  }

  .pt-xl-4 {
    padding-top: 3.121875rem;
  }

  .pt-xl-5 {
    padding-top: 4.996875rem;
  }

  .pt-xl-6 {
    padding-top: 6.24375rem;
  }

  .pe-xl-0 {
    padding-right: 0;
  }

  .pe-xl-1 {
    padding-right: 0.46875rem;
  }

  .pe-xl-2 {
    padding-right: 0.9375rem;
  }

  .pe-xl-3 {
    padding-right: 1.875rem;
  }

  .pe-xl-4 {
    padding-right: 3.121875rem;
  }

  .pe-xl-5 {
    padding-right: 4.996875rem;
  }

  .pe-xl-6 {
    padding-right: 6.24375rem;
  }

  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pb-xl-1 {
    padding-bottom: 0.46875rem;
  }

  .pb-xl-2 {
    padding-bottom: 0.9375rem;
  }

  .pb-xl-3 {
    padding-bottom: 1.875rem;
  }

  .pb-xl-4 {
    padding-bottom: 3.121875rem;
  }

  .pb-xl-5 {
    padding-bottom: 4.996875rem;
  }

  .pb-xl-6 {
    padding-bottom: 6.24375rem;
  }

  .ps-xl-0 {
    padding-left: 0;
  }

  .ps-xl-1 {
    padding-left: 0.46875rem;
  }

  .ps-xl-2 {
    padding-left: 0.9375rem;
  }

  .ps-xl-3 {
    padding-left: 1.875rem;
  }

  .ps-xl-4 {
    padding-left: 3.121875rem;
  }

  .ps-xl-5 {
    padding-left: 4.996875rem;
  }

  .ps-xl-6 {
    padding-left: 6.24375rem;
  }

  .text-xl-start {
    text-align: left;
  }

  .text-xl-end {
    text-align: right;
  }

  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline;
  }

  .d-xxl-inline-block {
    display: inline-block;
  }

  .d-xxl-block {
    display: block;
  }

  .d-xxl-grid {
    display: grid;
  }

  .d-xxl-table {
    display: table;
  }

  .d-xxl-table-row {
    display: table-row;
  }

  .d-xxl-table-cell {
    display: table-cell;
  }

  .d-xxl-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-xxl-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-xxl-none {
    display: none;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .flex-xxl-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1;
        flex-shrink: 1;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .align-items-xxl-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .align-items-xxl-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .align-items-xxl-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center;
        align-content: center;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto;
        align-self: auto;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center;
        align-self: center;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .m-xxl-0 {
    margin: 0;
  }

  .m-xxl-1 {
    margin: 0.46875rem;
  }

  .m-xxl-2 {
    margin: 0.9375rem;
  }

  .m-xxl-3 {
    margin: 1.875rem;
  }

  .m-xxl-4 {
    margin: 3.121875rem;
  }

  .m-xxl-5 {
    margin: 4.996875rem;
  }

  .m-xxl-6 {
    margin: 6.24375rem;
  }

  .m-xxl-auto {
    margin: auto;
  }

  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-xxl-1 {
    margin-right: 0.46875rem;
    margin-left: 0.46875rem;
  }

  .mx-xxl-2 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }

  .mx-xxl-3 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  .mx-xxl-4 {
    margin-right: 3.121875rem;
    margin-left: 3.121875rem;
  }

  .mx-xxl-5 {
    margin-right: 4.996875rem;
    margin-left: 4.996875rem;
  }

  .mx-xxl-6 {
    margin-right: 6.24375rem;
    margin-left: 6.24375rem;
  }

  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xxl-1 {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }

  .my-xxl-2 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }

  .my-xxl-3 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .my-xxl-4 {
    margin-top: 3.121875rem;
    margin-bottom: 3.121875rem;
  }

  .my-xxl-5 {
    margin-top: 4.996875rem;
    margin-bottom: 4.996875rem;
  }

  .my-xxl-6 {
    margin-top: 6.24375rem;
    margin-bottom: 6.24375rem;
  }

  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xxl-0 {
    margin-top: 0;
  }

  .mt-xxl-1 {
    margin-top: 0.46875rem;
  }

  .mt-xxl-2 {
    margin-top: 0.9375rem;
  }

  .mt-xxl-3 {
    margin-top: 1.875rem;
  }

  .mt-xxl-4 {
    margin-top: 3.121875rem;
  }

  .mt-xxl-5 {
    margin-top: 4.996875rem;
  }

  .mt-xxl-6 {
    margin-top: 6.24375rem;
  }

  .mt-xxl-auto {
    margin-top: auto;
  }

  .me-xxl-0 {
    margin-right: 0;
  }

  .me-xxl-1 {
    margin-right: 0.46875rem;
  }

  .me-xxl-2 {
    margin-right: 0.9375rem;
  }

  .me-xxl-3 {
    margin-right: 1.875rem;
  }

  .me-xxl-4 {
    margin-right: 3.121875rem;
  }

  .me-xxl-5 {
    margin-right: 4.996875rem;
  }

  .me-xxl-6 {
    margin-right: 6.24375rem;
  }

  .me-xxl-auto {
    margin-right: auto;
  }

  .mb-xxl-0 {
    margin-bottom: 0;
  }

  .mb-xxl-1 {
    margin-bottom: 0.46875rem;
  }

  .mb-xxl-2 {
    margin-bottom: 0.9375rem;
  }

  .mb-xxl-3 {
    margin-bottom: 1.875rem;
  }

  .mb-xxl-4 {
    margin-bottom: 3.121875rem;
  }

  .mb-xxl-5 {
    margin-bottom: 4.996875rem;
  }

  .mb-xxl-6 {
    margin-bottom: 6.24375rem;
  }

  .mb-xxl-auto {
    margin-bottom: auto;
  }

  .ms-xxl-0 {
    margin-left: 0;
  }

  .ms-xxl-1 {
    margin-left: 0.46875rem;
  }

  .ms-xxl-2 {
    margin-left: 0.9375rem;
  }

  .ms-xxl-3 {
    margin-left: 1.875rem;
  }

  .ms-xxl-4 {
    margin-left: 3.121875rem;
  }

  .ms-xxl-5 {
    margin-left: 4.996875rem;
  }

  .ms-xxl-6 {
    margin-left: 6.24375rem;
  }

  .ms-xxl-auto {
    margin-left: auto;
  }

  .p-xxl-0 {
    padding: 0;
  }

  .p-xxl-1 {
    padding: 0.46875rem;
  }

  .p-xxl-2 {
    padding: 0.9375rem;
  }

  .p-xxl-3 {
    padding: 1.875rem;
  }

  .p-xxl-4 {
    padding: 3.121875rem;
  }

  .p-xxl-5 {
    padding: 4.996875rem;
  }

  .p-xxl-6 {
    padding: 6.24375rem;
  }

  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-xxl-1 {
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }

  .px-xxl-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .px-xxl-3 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  .px-xxl-4 {
    padding-right: 3.121875rem;
    padding-left: 3.121875rem;
  }

  .px-xxl-5 {
    padding-right: 4.996875rem;
    padding-left: 4.996875rem;
  }

  .px-xxl-6 {
    padding-right: 6.24375rem;
    padding-left: 6.24375rem;
  }

  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xxl-1 {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }

  .py-xxl-2 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .py-xxl-3 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .py-xxl-4 {
    padding-top: 3.121875rem;
    padding-bottom: 3.121875rem;
  }

  .py-xxl-5 {
    padding-top: 4.996875rem;
    padding-bottom: 4.996875rem;
  }

  .py-xxl-6 {
    padding-top: 6.24375rem;
    padding-bottom: 6.24375rem;
  }

  .pt-xxl-0 {
    padding-top: 0;
  }

  .pt-xxl-1 {
    padding-top: 0.46875rem;
  }

  .pt-xxl-2 {
    padding-top: 0.9375rem;
  }

  .pt-xxl-3 {
    padding-top: 1.875rem;
  }

  .pt-xxl-4 {
    padding-top: 3.121875rem;
  }

  .pt-xxl-5 {
    padding-top: 4.996875rem;
  }

  .pt-xxl-6 {
    padding-top: 6.24375rem;
  }

  .pe-xxl-0 {
    padding-right: 0;
  }

  .pe-xxl-1 {
    padding-right: 0.46875rem;
  }

  .pe-xxl-2 {
    padding-right: 0.9375rem;
  }

  .pe-xxl-3 {
    padding-right: 1.875rem;
  }

  .pe-xxl-4 {
    padding-right: 3.121875rem;
  }

  .pe-xxl-5 {
    padding-right: 4.996875rem;
  }

  .pe-xxl-6 {
    padding-right: 6.24375rem;
  }

  .pb-xxl-0 {
    padding-bottom: 0;
  }

  .pb-xxl-1 {
    padding-bottom: 0.46875rem;
  }

  .pb-xxl-2 {
    padding-bottom: 0.9375rem;
  }

  .pb-xxl-3 {
    padding-bottom: 1.875rem;
  }

  .pb-xxl-4 {
    padding-bottom: 3.121875rem;
  }

  .pb-xxl-5 {
    padding-bottom: 4.996875rem;
  }

  .pb-xxl-6 {
    padding-bottom: 6.24375rem;
  }

  .ps-xxl-0 {
    padding-left: 0;
  }

  .ps-xxl-1 {
    padding-left: 0.46875rem;
  }

  .ps-xxl-2 {
    padding-left: 0.9375rem;
  }

  .ps-xxl-3 {
    padding-left: 1.875rem;
  }

  .ps-xxl-4 {
    padding-left: 3.121875rem;
  }

  .ps-xxl-5 {
    padding-left: 4.996875rem;
  }

  .ps-xxl-6 {
    padding-left: 6.24375rem;
  }

  .text-xxl-start {
    text-align: left;
  }

  .text-xxl-end {
    text-align: right;
  }

  .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1920px) {
  .d-3xl-inline {
    display: inline;
  }

  .d-3xl-inline-block {
    display: inline-block;
  }

  .d-3xl-block {
    display: block;
  }

  .d-3xl-grid {
    display: grid;
  }

  .d-3xl-table {
    display: table;
  }

  .d-3xl-table-row {
    display: table-row;
  }

  .d-3xl-table-cell {
    display: table-cell;
  }

  .d-3xl-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-3xl-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-3xl-none {
    display: none;
  }

  .flex-3xl-fill {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .flex-3xl-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .flex-3xl-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .flex-3xl-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .flex-3xl-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .flex-3xl-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .flex-3xl-grow-1 {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .flex-3xl-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .flex-3xl-shrink-1 {
    -ms-flex-negative: 1;
        flex-shrink: 1;
  }

  .flex-3xl-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .flex-3xl-nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }

  .flex-3xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .justify-content-3xl-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .justify-content-3xl-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .justify-content-3xl-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .justify-content-3xl-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .justify-content-3xl-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .justify-content-3xl-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .align-items-3xl-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .align-items-3xl-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .align-items-3xl-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-3xl-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .align-items-3xl-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .align-content-3xl-start {
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }

  .align-content-3xl-end {
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

  .align-content-3xl-center {
    -ms-flex-line-pack: center;
        align-content: center;
  }

  .align-content-3xl-between {
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }

  .align-content-3xl-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }

  .align-content-3xl-stretch {
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }

  .align-self-3xl-auto {
    -ms-flex-item-align: auto;
        align-self: auto;
  }

  .align-self-3xl-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .align-self-3xl-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .align-self-3xl-center {
    -ms-flex-item-align: center;
        align-self: center;
  }

  .align-self-3xl-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }

  .align-self-3xl-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }

  .order-3xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-3xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-3xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-3xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-3xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-3xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-3xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-3xl-last {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .m-3xl-0 {
    margin: 0;
  }

  .m-3xl-1 {
    margin: 0.46875rem;
  }

  .m-3xl-2 {
    margin: 0.9375rem;
  }

  .m-3xl-3 {
    margin: 1.875rem;
  }

  .m-3xl-4 {
    margin: 3.121875rem;
  }

  .m-3xl-5 {
    margin: 4.996875rem;
  }

  .m-3xl-6 {
    margin: 6.24375rem;
  }

  .m-3xl-auto {
    margin: auto;
  }

  .mx-3xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-3xl-1 {
    margin-right: 0.46875rem;
    margin-left: 0.46875rem;
  }

  .mx-3xl-2 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }

  .mx-3xl-3 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  .mx-3xl-4 {
    margin-right: 3.121875rem;
    margin-left: 3.121875rem;
  }

  .mx-3xl-5 {
    margin-right: 4.996875rem;
    margin-left: 4.996875rem;
  }

  .mx-3xl-6 {
    margin-right: 6.24375rem;
    margin-left: 6.24375rem;
  }

  .mx-3xl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-3xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-3xl-1 {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }

  .my-3xl-2 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }

  .my-3xl-3 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .my-3xl-4 {
    margin-top: 3.121875rem;
    margin-bottom: 3.121875rem;
  }

  .my-3xl-5 {
    margin-top: 4.996875rem;
    margin-bottom: 4.996875rem;
  }

  .my-3xl-6 {
    margin-top: 6.24375rem;
    margin-bottom: 6.24375rem;
  }

  .my-3xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-3xl-0 {
    margin-top: 0;
  }

  .mt-3xl-1 {
    margin-top: 0.46875rem;
  }

  .mt-3xl-2 {
    margin-top: 0.9375rem;
  }

  .mt-3xl-3 {
    margin-top: 1.875rem;
  }

  .mt-3xl-4 {
    margin-top: 3.121875rem;
  }

  .mt-3xl-5 {
    margin-top: 4.996875rem;
  }

  .mt-3xl-6 {
    margin-top: 6.24375rem;
  }

  .mt-3xl-auto {
    margin-top: auto;
  }

  .me-3xl-0 {
    margin-right: 0;
  }

  .me-3xl-1 {
    margin-right: 0.46875rem;
  }

  .me-3xl-2 {
    margin-right: 0.9375rem;
  }

  .me-3xl-3 {
    margin-right: 1.875rem;
  }

  .me-3xl-4 {
    margin-right: 3.121875rem;
  }

  .me-3xl-5 {
    margin-right: 4.996875rem;
  }

  .me-3xl-6 {
    margin-right: 6.24375rem;
  }

  .me-3xl-auto {
    margin-right: auto;
  }

  .mb-3xl-0 {
    margin-bottom: 0;
  }

  .mb-3xl-1 {
    margin-bottom: 0.46875rem;
  }

  .mb-3xl-2 {
    margin-bottom: 0.9375rem;
  }

  .mb-3xl-3 {
    margin-bottom: 1.875rem;
  }

  .mb-3xl-4 {
    margin-bottom: 3.121875rem;
  }

  .mb-3xl-5 {
    margin-bottom: 4.996875rem;
  }

  .mb-3xl-6 {
    margin-bottom: 6.24375rem;
  }

  .mb-3xl-auto {
    margin-bottom: auto;
  }

  .ms-3xl-0 {
    margin-left: 0;
  }

  .ms-3xl-1 {
    margin-left: 0.46875rem;
  }

  .ms-3xl-2 {
    margin-left: 0.9375rem;
  }

  .ms-3xl-3 {
    margin-left: 1.875rem;
  }

  .ms-3xl-4 {
    margin-left: 3.121875rem;
  }

  .ms-3xl-5 {
    margin-left: 4.996875rem;
  }

  .ms-3xl-6 {
    margin-left: 6.24375rem;
  }

  .ms-3xl-auto {
    margin-left: auto;
  }

  .p-3xl-0 {
    padding: 0;
  }

  .p-3xl-1 {
    padding: 0.46875rem;
  }

  .p-3xl-2 {
    padding: 0.9375rem;
  }

  .p-3xl-3 {
    padding: 1.875rem;
  }

  .p-3xl-4 {
    padding: 3.121875rem;
  }

  .p-3xl-5 {
    padding: 4.996875rem;
  }

  .p-3xl-6 {
    padding: 6.24375rem;
  }

  .px-3xl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-3xl-1 {
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }

  .px-3xl-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .px-3xl-3 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  .px-3xl-4 {
    padding-right: 3.121875rem;
    padding-left: 3.121875rem;
  }

  .px-3xl-5 {
    padding-right: 4.996875rem;
    padding-left: 4.996875rem;
  }

  .px-3xl-6 {
    padding-right: 6.24375rem;
    padding-left: 6.24375rem;
  }

  .py-3xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-3xl-1 {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }

  .py-3xl-2 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .py-3xl-3 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .py-3xl-4 {
    padding-top: 3.121875rem;
    padding-bottom: 3.121875rem;
  }

  .py-3xl-5 {
    padding-top: 4.996875rem;
    padding-bottom: 4.996875rem;
  }

  .py-3xl-6 {
    padding-top: 6.24375rem;
    padding-bottom: 6.24375rem;
  }

  .pt-3xl-0 {
    padding-top: 0;
  }

  .pt-3xl-1 {
    padding-top: 0.46875rem;
  }

  .pt-3xl-2 {
    padding-top: 0.9375rem;
  }

  .pt-3xl-3 {
    padding-top: 1.875rem;
  }

  .pt-3xl-4 {
    padding-top: 3.121875rem;
  }

  .pt-3xl-5 {
    padding-top: 4.996875rem;
  }

  .pt-3xl-6 {
    padding-top: 6.24375rem;
  }

  .pe-3xl-0 {
    padding-right: 0;
  }

  .pe-3xl-1 {
    padding-right: 0.46875rem;
  }

  .pe-3xl-2 {
    padding-right: 0.9375rem;
  }

  .pe-3xl-3 {
    padding-right: 1.875rem;
  }

  .pe-3xl-4 {
    padding-right: 3.121875rem;
  }

  .pe-3xl-5 {
    padding-right: 4.996875rem;
  }

  .pe-3xl-6 {
    padding-right: 6.24375rem;
  }

  .pb-3xl-0 {
    padding-bottom: 0;
  }

  .pb-3xl-1 {
    padding-bottom: 0.46875rem;
  }

  .pb-3xl-2 {
    padding-bottom: 0.9375rem;
  }

  .pb-3xl-3 {
    padding-bottom: 1.875rem;
  }

  .pb-3xl-4 {
    padding-bottom: 3.121875rem;
  }

  .pb-3xl-5 {
    padding-bottom: 4.996875rem;
  }

  .pb-3xl-6 {
    padding-bottom: 6.24375rem;
  }

  .ps-3xl-0 {
    padding-left: 0;
  }

  .ps-3xl-1 {
    padding-left: 0.46875rem;
  }

  .ps-3xl-2 {
    padding-left: 0.9375rem;
  }

  .ps-3xl-3 {
    padding-left: 1.875rem;
  }

  .ps-3xl-4 {
    padding-left: 3.121875rem;
  }

  .ps-3xl-5 {
    padding-left: 4.996875rem;
  }

  .ps-3xl-6 {
    padding-left: 6.24375rem;
  }

  .text-3xl-start {
    text-align: left;
  }

  .text-3xl-end {
    text-align: right;
  }

  .text-3xl-center {
    text-align: center;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }

  .d-print-inline-block {
    display: inline-block;
  }

  .d-print-block {
    display: block;
  }

  .d-print-grid {
    display: grid;
  }

  .d-print-table {
    display: table;
  }

  .d-print-table-row {
    display: table-row;
  }

  .d-print-table-cell {
    display: table-cell;
  }

  .d-print-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-print-none {
    display: none;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* Slider */
.slick-loading .slick-list {
  background: #fff url("resources/images/ajax-loader.gif") center center no-repeat;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: #009fa5;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}
.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #009fa5;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #009fa5;
  opacity: 0.75;
}
:root {
  --input-border-radius: 1.75rem;
  --input-height: 3.5rem;
  --input-background-color: #fff;
  --input-padding-x: 1.25rem;
  --input-padding-y: 0.9375rem;
  --input-font-size: 1rem;
  --input-line-height: 1.825;
  --section-spacing: 6.25rem;
}
@media (max-width: 575.98px) {
  :root {
    --section-spacing: 5rem;
  }
}
/*
* ADOBE CONFIDENTIAL
*
* Copyright 2020 Adobe Systems Incorporated
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
*/
/* grid component */
.aem-Grid {
  display: block;
  width: 100%;
}
.aem-Grid::before,
.aem-Grid::after {
  display: table;
  content: " ";
}
.aem-Grid::after {
  clear: both;
}
/* placeholder for new components */
.aem-Grid-newComponent {
  clear: both;
  margin: 0;
}
/* column of a grid */
.aem-GridColumn {
  clear: both;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
/* force showing hidden */
.aem-GridShowHidden > .aem-Grid > .aem-GridColumn {
  display: block !important;
}
/* force showing hidden components in unhide mode */
.aem-GridShowHidden > .cmp-container > .aem-Grid > .aem-GridColumn {
  display: block !important;
}
/* Generates all the rules for the grid columns up to the given amount of column */
/* Generates all the rules for the grid column offset up to the given amount of column */
/* Generates all the rules for the grid and columns for the given break point and total of columns */
/* Generates all the rules for the grids and columns */
/* API function to be called to generate a grid config */
.aem-GridColumn {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
@media (max-width: 992px) {
  .aem-GridColumn {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media (max-width: 575.98px) {
  .col-12 {
    margin-bottom: 1.875rem;
  }
  .col-12:last-child {
    margin-bottom: 0;
  }
}
html,
body {
  color: #212b32;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  line-height: 1.5;
}
html {
  scroll-padding-top: 10.625rem;
  scroll-behavior: smooth;
}
@media (max-width: 575.98px) {
  html {
    scroll-padding-top: 5rem;
  }
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-font-feature-settings: "lnum";
          font-feature-settings: "lnum";
  overflow-x: hidden;
}
a {
  color: #3870b7;
}
a[href^="mailto:"] {
  word-break: break-word;
}
p {
  margin-top: 0;
  margin-bottom: 1.875rem;
}
.container--flower-style .container--flower-style__svg, .container--bg-full-style, .generic-hero .cmp-hero-flower, .generic-hero .hero:after {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.root.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--1 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--1 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--1 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 25%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 75%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 25%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 75%;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 25%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 75%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 25%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 75%;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 20%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 40%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 60%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 80%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 20%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 40%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 60%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 80%;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 20%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 40%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 60%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 80%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 20%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 40%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 60%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 80%;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 16.6666666667%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 83.3333333333%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 16.6666666667%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 83.3333333333%;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 16.6666666667%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 83.3333333333%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 16.6666666667%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 83.3333333333%;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 14.2857142857%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 28.5714285714%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 42.8571428571%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 57.1428571429%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 71.4285714286%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 85.7142857143%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 14.2857142857%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 28.5714285714%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 42.8571428571%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 57.1428571429%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 71.4285714286%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 85.7142857143%;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 14.2857142857%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 28.5714285714%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 42.8571428571%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 57.1428571429%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 71.4285714286%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 85.7142857143%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 14.2857142857%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 28.5714285714%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 42.8571428571%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 57.1428571429%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 71.4285714286%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 85.7142857143%;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 12.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 25%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 37.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 62.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 75%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 87.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 12.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 25%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 37.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 62.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 75%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 87.5%;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 12.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 25%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 37.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 62.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 75%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 87.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 12.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 25%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 37.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 62.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 75%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 87.5%;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 11.1111111111%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 22.2222222222%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 44.4444444444%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 55.5555555556%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 77.7777777778%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 88.8888888889%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 11.1111111111%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 22.2222222222%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 44.4444444444%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 55.5555555556%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 77.7777777778%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 88.8888888889%;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 11.1111111111%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 22.2222222222%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 44.4444444444%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 55.5555555556%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 77.7777777778%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 88.8888888889%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 11.1111111111%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 22.2222222222%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 44.4444444444%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 55.5555555556%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 77.7777777778%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 88.8888888889%;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 10%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 20%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 30%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 40%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 60%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 70%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 80%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 90%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 10%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 20%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 30%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 40%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 60%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 70%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 80%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 90%;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 10%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 20%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 30%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 40%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 60%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 70%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 80%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 90%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 10%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 20%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 30%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 40%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 60%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 70%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 80%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 90%;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 9.0909090909%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 18.1818181818%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 27.2727272727%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 36.3636363636%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 45.4545454545%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 54.5454545455%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 63.6363636364%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 72.7272727273%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 81.8181818182%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 90.9090909091%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 9.0909090909%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 18.1818181818%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 27.2727272727%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 36.3636363636%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 45.4545454545%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 54.5454545455%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 63.6363636364%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 72.7272727273%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 81.8181818182%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 90.9090909091%;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 9.0909090909%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 18.1818181818%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 27.2727272727%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 36.3636363636%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 45.4545454545%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 54.5454545455%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 63.6363636364%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 72.7272727273%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 81.8181818182%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 90.9090909091%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 9.0909090909%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 18.1818181818%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 27.2727272727%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 36.3636363636%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 45.4545454545%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 54.5454545455%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 63.6363636364%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 72.7272727273%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 81.8181818182%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 90.9090909091%;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 8.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 16.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 25%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 41.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 58.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 75%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 83.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 91.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--12 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 8.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 16.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 25%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 41.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 58.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 75%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 83.3333333333%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 91.6666666667%;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--12 {
  margin-left: 100%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 8.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 16.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 25%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 41.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 50%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 58.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 75%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 83.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 91.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--12 {
  float: left;
  clear: none;
  width: 100%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 8.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 16.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 25%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 33.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 41.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 50%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 58.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 66.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 75%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 83.3333333333%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 91.6666666667%;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--12 {
  margin-left: 100%;
}
.aem-Grid > .aem-GridColumn.aem-GridColumn--default--newline {
  /* newline behavior */
  display: block;
  clear: both !important;
}
.aem-Grid > .aem-GridColumn.aem-GridColumn--default--none {
  /* none behavior */
  display: block;
  float: left;
  clear: none !important;
}
.aem-Grid > .aem-GridColumn.aem-GridColumn--default--hide {
  /* hide behavior */
  display: none;
}
@media (max-width: 768px) {
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--1 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--1 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--1 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--2 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--phone--2 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--2 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--2 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--phone--2 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--3 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--3 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--3 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--3 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--3 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--3 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--3 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 25%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 75%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 25%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 75%;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 25%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 75%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 25%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 75%;
  }
  .aem-Grid.aem-Grid--phone--4 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 20%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 40%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 60%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 80%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 20%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 40%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 60%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 80%;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 20%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 40%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 60%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 80%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 20%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 40%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 60%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 80%;
  }
  .aem-Grid.aem-Grid--phone--5 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--6 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 14.2857142857%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 28.5714285714%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 42.8571428571%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 57.1428571429%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 71.4285714286%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 85.7142857143%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 14.2857142857%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 28.5714285714%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 42.8571428571%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 57.1428571429%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 71.4285714286%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 85.7142857143%;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 14.2857142857%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 28.5714285714%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 42.8571428571%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 57.1428571429%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 71.4285714286%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 85.7142857143%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 14.2857142857%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 28.5714285714%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 42.8571428571%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 57.1428571429%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 71.4285714286%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 85.7142857143%;
  }
  .aem-Grid.aem-Grid--phone--7 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 12.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 25%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 37.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 62.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 75%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 87.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 12.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 25%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 37.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 62.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 75%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 87.5%;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 12.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 25%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 37.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 62.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 75%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 87.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 12.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 25%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 37.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 62.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 75%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 87.5%;
  }
  .aem-Grid.aem-Grid--phone--8 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 11.1111111111%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 22.2222222222%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 44.4444444444%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 55.5555555556%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 77.7777777778%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 88.8888888889%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 11.1111111111%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 22.2222222222%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 44.4444444444%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 55.5555555556%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 77.7777777778%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 88.8888888889%;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 11.1111111111%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 22.2222222222%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 44.4444444444%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 55.5555555556%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 77.7777777778%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 88.8888888889%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 11.1111111111%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 22.2222222222%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 44.4444444444%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 55.5555555556%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 77.7777777778%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 88.8888888889%;
  }
  .aem-Grid.aem-Grid--phone--9 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 10%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 20%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 30%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 40%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 60%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 70%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 80%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 90%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 10%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 20%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 30%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 40%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 60%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 70%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 80%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 90%;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 10%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 20%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 30%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 40%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 60%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 70%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 80%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 90%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 10%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 20%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 30%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 40%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 60%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 70%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 80%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 90%;
  }
  .aem-Grid.aem-Grid--phone--10 > .aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 9.0909090909%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 18.1818181818%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 27.2727272727%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 36.3636363636%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 45.4545454545%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 54.5454545455%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 63.6363636364%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 72.7272727273%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 81.8181818182%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 90.9090909091%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 9.0909090909%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 18.1818181818%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 27.2727272727%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 36.3636363636%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 45.4545454545%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 54.5454545455%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 63.6363636364%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 72.7272727273%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 81.8181818182%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 90.9090909091%;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 9.0909090909%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 18.1818181818%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 27.2727272727%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 36.3636363636%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 45.4545454545%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 54.5454545455%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 63.6363636364%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 72.7272727273%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 81.8181818182%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 90.9090909091%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 9.0909090909%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 18.1818181818%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 27.2727272727%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 36.3636363636%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 45.4545454545%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 54.5454545455%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 63.6363636364%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 72.7272727273%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 81.8181818182%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 90.9090909091%;
  }
  .aem-Grid.aem-Grid--phone--11 > .aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 8.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 25%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 41.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 58.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 75%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 91.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--phone--12 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 8.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 25%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 41.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 58.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 75%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 91.6666666667%;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--phone--12 {
    margin-left: 100%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 8.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 25%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 41.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 50%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 58.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 75%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 91.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--phone--12 {
    float: left;
    clear: none;
    width: 100%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 8.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 16.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 25%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 33.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 41.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 50%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 58.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 66.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 75%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 83.3333333333%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 91.6666666667%;
  }
  .aem-Grid.aem-Grid--phone--12 > .aem-GridColumn.aem-GridColumn--offset--phone--12 {
    margin-left: 100%;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--phone--newline {
    /* newline behavior */
    display: block;
    clear: both !important;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--phone--none {
    /* none behavior */
    display: block;
    float: left;
    clear: none !important;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--phone--hide {
    /* hide behavior */
    display: none;
  }
}
[class*=cookieconsent-optout] > div p,
[class*=cookieconsent-optout] > div p a {
  font-size: 24px;
}
@media (max-width: 991.98px) {
  [class*=cookieconsent-optout] > div p,
[class*=cookieconsent-optout] > div p a {
    font-size: 2.218vw;
  }
}
@media (max-width: 575.98px) {
  [class*=cookieconsent-optout] > div p,
[class*=cookieconsent-optout] > div p a {
    font-size: 18px;
  }
}
[class*=cookieconsent-optout] > div p a {
  color: #fff;
}
[class*=cookieconsent-optout] > div p a:hover {
  color: #fff;
}
[class*=cookieconsent-optout] > div .cmp-cta-button {
  font-size: 18px;
}
.CookieDeclaration {
  padding-top: 5.625rem;
}
@media (max-width: 767.98px) {
  .CookieDeclaration {
    padding-top: 40px;
  }
}
.CybotCookiebotDialogDetailBulkConsentCount {
  color: #fff !important;
}
[class*=cookieconsent-optout] > div {
  background-color: #068391 !important;
}
#CybotCookiebotDialogFooter {
  -ms-flex-item-align: center;
      align-self: center;
}
.bg-color-white {
  background-color: #fff;
}
.bg-color-primary-blue {
  background-color: #3870b7;
}
.bg-color-teal {
  background-color: #009fa5;
}
.bg-color-teal-10 {
  background-color: #f1f9fa;
}
.bg-color-teal,
.bg-color-teal a:not(.cmp-cta-button),
.bg-color-primary-blue,
.bg-color-primary-blue a:not(.cmp-cta-button) {
  color: #fff;
}
/*
 *  Copyright 2018 Adobe Systems Incorporated
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
@font-face {
  font-family: "icomoon";
  src: url("resources/fonts/icomoon.ttf") format("truetype"), url("resources/fonts/icomoon.woff") format("woff"), url("resources/images/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class$=__icomoon],
[class*=__icomoon],
[class$=-icomoon],
[class*=-icomoon] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.4em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icomoon-check:before {
  content: "\e905";
}
.icomoon-search:before {
  content: "\e908";
}
.icomoon-arrow:before {
  content: "\e903";
}
.icomoon-email:before {
  content: "\e901";
}
.icomoon-phone:before {
  content: "\e902";
}
.icomoon-pin:before {
  content: "\e906";
}
.icomoon-log-in:before {
  content: "\e907";
}
.icomoon-user:before {
  content: "\e909";
}
.icomoon-pin-outline:before {
  content: "\e900";
}
.icomoon-arrow-outline:before {
  content: "\e904";
}
.icomoon-facebook:before {
  content: "\e90a";
}
.icomoon-instagram:before {
  content: "\e90b";
}
.icomoon-pinterest:before {
  content: "\e90c";
}
.icomoon-twitter:before {
  content: "\e90d";
}
.icomoon-youtube:before {
  content: "\e90e";
}
.icomoon-download:before {
  content: "\e90f";
}
.icomoon-print:before {
  content: "\e910";
}
.icomoon-arrow-outline-fill:before {
  content: "\e911";
}
.icomoon-arrow-up:before {
  content: "\e912";
}
.rotate-90deg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rotate-90deg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rotate-180deg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
h1, h2, .text-media .text .cmp-title__text, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.875rem;
  font-weight: 400;
}
h1,
h2,
.text-media .text .cmp-title__text {
  font-weight: 300;
  letter-spacing: -0.66px;
}
h1 {
  font-size: 4.125rem;
  line-height: 1.2;
}
@media (max-width: 576px) {
  h1 {
    font-size: 2.875rem;
  }
}
h2, .text-media .text .cmp-title__text {
  font-size: 2.875rem;
  line-height: 1.3;
}
@media (max-width: 576px) {
  h2, .text-media .text .cmp-title__text {
    font-size: 2.25rem;
  }
}
h3 {
  font-size: 2.5rem;
}
@media (max-width: 576px) {
  h3 {
    font-size: 1.875rem;
  }
}
h3, h4, h5, h6 {
  line-height: 1.5;
}
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
/* Sections
     ========================================================================== */
/**
   * Remove the margin in all browsers.
   */
body {
  margin: 0;
}
/**
   * Render the `main` element consistently in IE.
   */
main {
  display: block;
}
/* Grouping content
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}
/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/* Text-level semantics
     ========================================================================== */
/**
   * Remove the gray background on active links in IE 10.
   */
a {
  background-color: transparent;
}
/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */
}
/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder;
}
/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%;
}
/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  font-size: 40%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -1.2em;
}
a sup {
  display: inline-block;
  text-decoration: none;
}
/* Embedded content
     ========================================================================== */
/**
   * Remove the border on images inside links in IE 10.
   */
img {
  border-style: none;
  max-width: 100%;
  vertical-align: middle;
}
/* Forms
     ========================================================================== */
/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}
/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
   * Correct the padding in Firefox.
   */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}
/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline;
}
/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto;
}
/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}
/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}
/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}
/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}
/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
  display: block;
}
/*
   * Add the correct display in all browsers.
   */
summary {
  display: list-item;
}
/* Misc
     ========================================================================== */
/**
   * Add the correct display in IE 10+.
   */
template {
  display: none;
}
/**
   * Add the correct display in IE 10.
   */
[hidden] {
  display: none;
}
.text:last-child p:last-child {
  margin-bottom: 0;
}
.text--center {
  text-align: center;
}
.text--center:not(.text-title--center) .text-title {
  text-align: left;
}
.text--font-medium {
  font-weight: 500;
}
.text-title--center .text-title {
  text-align: center;
}
.text.text-title--copy-divider .cmp-title__text {
  font-style: italic;
  font-family: "IBM Plex Serif", serif;
  letter-spacing: -0.3px;
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
  border-bottom: 1px solid currentColor;
  text-transform: none;
  font-weight: 400;
  font-size: 1.875rem;
}
@media (max-width: 575.98px) {
  .text.text-title--copy-divider .cmp-title__text {
    font-size: 1.5rem;
  }
}
.text--teal .cmp-text {
  color: #009fa5;
}
.text .cmp-title__text {
  margin-bottom: 0.625rem;
  font-size: 1.5rem;
  letter-spacing: 3.12px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  color: #009fa5;
}
.bg-color-primary-blue .text .cmp-title__text, .bg-color-teal .text .cmp-title__text {
  color: #fff;
}
.text .cmp-title__text sub,
.text .cmp-title__text sup {
  font-size: 100%;
  top: -0.2em;
  font-weight: 300;
}
.text p {
  margin-top: 0;
  margin-bottom: 1.875rem;
}
.text p i {
  font-family: "IBM Plex Serif", serif;
}
.text ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 500;
}
.text ul li {
  margin-bottom: 0.9375rem;
}
.text ul li:last-child {
  margin-bottom: 0;
}
.text ul li:before {
  content: "–";
  display: inline-block;
  margin-right: 0.625rem;
}
.columns-count-2 .text ul {
  max-width: 26.625rem;
  margin-left: auto;
  margin-right: auto;
}
.text:not(.text--lg):not(.text--md):not(.text--sm):not(.text--xs) {
  font-size: 1.25rem;
}
.text--lg {
  font-size: 1.875rem;
}
.text--md {
  font-size: 1.625rem;
}
.text--sm {
  font-size: 1.125rem;
}
.text--xs {
  font-size: 1rem;
}
.text.text-title--copy-divider.component {
  padding-bottom: 0 !important;
}
.title {
  padding-top: var(--section-spacing);
}
.title:last-child h1, .title:last-child h2, .title:last-child .text-media .text .cmp-title__text, .text-media .text .title:last-child .cmp-title__text, .title:last-child h3, .title:last-child h4, .title:last-child h5, .title:last-child h6:last-child {
  margin-bottom: 0;
}
.title--center {
  text-align: center;
}
.title--italic {
  font-style: italic;
  font-family: "IBM Plex Serif", serif;
}
.title--italic h2, .title--italic .text-media .text .cmp-title__text, .text-media .text .title--italic .cmp-title__text {
  font-size: 2.5rem;
}
.title .cmp-title {
  margin-bottom: 0;
  margin-top: 0;
}
.tile + .tile {
  margin-top: 1.875rem;
}
.title + .text-title--copy-divider {
  margin-top: calc((var(--section-spacing) / 2) - 1.875rem);
}
.title + .component {
  padding-top: 0 !important;
}
.container.aem-GridColumn .title:first-child {
  padding-top: 0;
}
.container.aem-GridColumn .component + .title {
  padding-top: 0;
}
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) + .title {
  padding-top: 0;
}
.title + .title {
  padding-top: 0;
}
.in-page-anchors + .title {
  padding-top: 0;
}
.title + .image {
  margin-top: 0 !important;
}
.component:not(.image) + .title {
  padding-top: 0;
}
.image {
  margin-top: var(--section-spacing);
  margin-bottom: var(--section-spacing);
}
.image--rectangular, .image--tall, .image--full-width {
  position: relative;
}
.image--rectangular img, .image--tall img, .image--full-width img {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.image--rectangular {
  padding-top: 83.51%;
}
.image--tall {
  padding-top: 145.79%;
}
.image--full-width {
  padding-top: 46.24%;
}
.image:not(.image--no-rounded) img {
  border-radius: 1.25rem;
}
.image:not(.image--no-shadow) img {
  -webkit-box-shadow: 0 23px 20px rgba(56, 112, 183, 0.24);
          box-shadow: 0 23px 20px rgba(56, 112, 183, 0.24);
}
.bg-color-primary-blue .image:not(.image--no-shadow) img {
  -webkit-box-shadow: 0 23px 20px rgba(0, 0, 0, 0.24);
          box-shadow: 0 23px 20px rgba(0, 0, 0, 0.24);
}
.image + .image {
  margin-top: 0;
}
.cmp-image__image {
  vertical-align: middle;
}
.image + .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg),
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) + .image {
  margin-top: 0;
}
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) .image {
  margin-top: 0;
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .image {
  margin-top: calc(var(--section-spacing) / 2);
  margin-bottom: calc(var(--section-spacing) / 2);
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .image:first-child {
  margin-top: 0;
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .image:last-child {
  margin-bottom: 0;
}
.component .image {
  margin-top: 0;
  margin-bottom: 0;
}
.image + .component {
  padding-top: 0 !important;
}
.in-page-anchors + .image {
  margin-top: 0;
}
.cmp-tile {
  padding: 2.49375rem 0.9375rem;
  border-radius: 1.25rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-filter: drop-shadow(0 23px 20px rgba(56, 112, 183, 0.25));
          filter: drop-shadow(0 23px 20px rgba(56, 112, 183, 0.25));
  background-color: #009fa5;
}
.cmp-tile,
.cmp-tile a {
  color: #fff;
}
.cmp-tile__image {
  margin-bottom: 1.875rem;
  border-radius: 50%;
  width: 198px;
  height: 198px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.cmp-tile__image img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.cmp-tile__title {
  max-width: 17.125rem;
  margin-left: auto;
  margin-right: auto;
}
.cmp-tile__title .cmp-title__text {
  font-size: 1.625rem;
}
.cmp-tile__description {
  margin-bottom: 1.246875rem;
  font-size: 1.25rem;
}
.cmp-tile__description:last-child {
  margin-bottom: 0;
}
.cmp-tile__description p:last-child {
  margin-bottom: 0;
}
.tile--large .cmp-tile {
  padding: 4.875rem 0.9375rem;
}
.tile--large .cmp-tile .cmp-tile__title {
  max-width: 100%;
}
.tile--large .cmp-tile .cmp-tile__description:not(:last-child) {
  margin-bottom: 1.875rem;
}
.tile--large .cmp-tile .cmp-title__text {
  font-size: 2.875rem;
  font-weight: 300;
}
@media (max-width: 576px) {
  .tile--large .cmp-tile .cmp-title__text {
    font-size: 2.25rem;
  }
}
.tile.aem-GridColumn.aem-GridColumn--default--12:last-child {
  padding-bottom: var(--section-spacing);
}
.container.aem-GridColumn .tile.aem-GridColumn.aem-GridColumn--default--12:last-child {
  padding-bottom: 0;
}
.social-feed {
  position: relative;
}
@media (max-width: 575.98px) {
  .social-feed {
    overflow: hidden;
  }
}
.social-feed .slick-list {
  overflow: visible;
}
.social-feed .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.social-feed .slick-slide {
  height: auto;
}
.social-feed .slick-slide:not(.slick-active) {
  opacity: 0.3;
}
.social-feed .slick-slide > div {
  height: 50%;
  padding: 0.9375rem;
}
@media (max-width: 575.98px) {
  .social-feed .slick-slide > div {
    padding-left: 0;
    padding-right: 0;
  }
}
.social-feed .slick-dotted.slick-slider {
  margin-bottom: 0;
}
.social-feed .slick-dots {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: auto;
  margin-top: calc(var(--section-spacing) / 2);
}
.social-feed .slick-dots li {
  height: 100%;
  width: 100%;
  border-radius: 1.25rem;
  overflow: hidden;
  max-width: 5.375rem;
  opacity: 0.3;
}
.social-feed .slick-dots li.slick-active {
  opacity: 1;
}
.social-feed .slick-dots button {
  width: 100%;
  height: 100%;
  background-color: #009fa5;
  padding: 2px;
}
.social-feed .slick-dots button:before {
  display: none;
}
.social-feed .cmp-social-feed__item {
  height: 100%;
}
.social-feed .cmp-social-feed__card {
  background-color: #fff;
  padding: 1.875rem;
  border-radius: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 13px 10px rgba(0, 159, 165, 0.1);
          box-shadow: 0 13px 10px rgba(0, 159, 165, 0.1);
  height: 100%;
  outline: 0;
}
@media (max-width: 575.98px) {
  .social-feed .cmp-social-feed__card {
    margin-left: 0;
    margin-right: 0;
  }
}
.social-feed .cmp-social-feed__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-bottom: 0.9375rem;
}
.social-feed .cmp-social-feed__icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  max-width: 1.625rem;
}
.social-feed .cmp-social-feed__excerpt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.social-feed .cmp-social-feed__image {
  width: 11.979vw;
  height: 11.979vw;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (min-width: 1920px) {
  .social-feed .cmp-social-feed__image {
    width: 14.9375rem;
    height: 14.9375rem;
  }
}
@media (max-width: 575.98px) {
  .social-feed .cmp-social-feed__image {
    width: 27.083vw;
    height: 27.083vw;
  }
}
.social-feed .cmp-social-feed__image img {
  display: block;
  border-radius: 0.375rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.social-feed .cmp-social-feed__text {
  margin-bottom: 0.625rem;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 1rem;
  max-height: 6.0625rem;
}
.social-feed .cmp-social-feed__text a {
  color: #009fa5;
}
.social-feed .cmp-social-feed__text a:hover {
  color: #006b6f;
}
.social-feed .cmp-social-feed .cmp-social-feed__image + .cmp-social-feed__excerpt {
  margin-left: 1.563vw;
}
@media (min-width: 1920px) {
  .social-feed .cmp-social-feed .cmp-social-feed__image + .cmp-social-feed__excerpt {
    margin-left: 1.875rem;
  }
}
@media (max-width: 575.98px) {
  .social-feed .cmp-social-feed .cmp-social-feed__image + .cmp-social-feed__excerpt {
    margin-left: 1.25rem;
  }
}
.social-feed .cmp-social-feed__date {
  font-size: 0.75rem;
  font-weight: 600;
  color: #3870b7;
  display: inline-block;
}
.social-feed .cmp-social-feed__cta {
  margin-top: auto;
}
@media (max-width: 575.98px) {
  .social-feed .cmp-social-feed__cta {
    text-align: right;
  }
}
.social-feed .cmp-social-feed .cta--link {
  font-weight: 600;
  border-radius: 1.8125rem;
  letter-spacing: 2.08px;
  text-transform: uppercase;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  font-size: 1rem;
  line-height: 2.125;
  text-decoration: none;
  color: #009fa5;
}
.social-feed .cmp-social-feed .cta--link:hover {
  color: #006b6f;
}
.social-feed .cmp-social-feed .cta--link .cta--link__icomoon--arrow {
  margin-left: 0.625rem;
}
.social-feed .cookieconsent-optout-statistics > div {
  top: 0;
  width: calc(100% - 30px);
  height: calc(100% - var(--section-spacing));
}
@media (max-width: 991.98px) {
  .social-feed .cookieconsent-optout-statistics > div {
    width: calc(100% - 40px);
  }
}
.title + .social-feed {
  margin-top: 1.246875rem;
}
.cmp-separator__horizontal-rule {
  border: none;
}
@media screen and (max-width: 1200px) {
  .text-media {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .text-media--large-gutter .cmp-textmedia-text {
    width: 41.66666667%;
    margin-left: auto;
    margin-right: auto;
  }
  .text-media--large-gutter .cmp-textmedia-text.order-md-0 {
    margin-left: 0;
  }
}
.text-media--center-alignment .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.text-media--top-offset {
  margin-top: -2.25rem;
  z-index: 2;
  position: relative;
}
.text-media--top-offset .cmp-textmedia--side .cmp-textmedia-text {
  -ms-flex-item-align: center;
      align-self: center;
}
.text-media--top-offset.component {
  padding-top: 0 !important;
}
@media (max-width: 767.98px) {
  .text-media .text,
.text-media .text p {
    text-align: center !important;
  }
}
.text-media .text .cmp-title__text {
  text-transform: none;
  color: #212b32;
}
.bg-color-primary-blue .text-media .text .cmp-title__text, .bg-color-teal .text-media .text .cmp-title__text {
  color: #fff;
}
@media (max-width: 767.98px) {
  .text-media .cta-button {
    text-align: center !important;
  }
}
@media (max-width: 575.98px) {
  .text-media .order-0 {
    margin-bottom: 1.875rem;
  }
}
.text-media + .text-media {
  margin-top: -1.25rem;
}
@media (max-width: 575.98px) {
  .text-media + .text-media {
    margin-top: -3.125rem;
  }
}
.container.aem-GridColumn .text-media--top-offset {
  margin-top: calc(-2.25rem - var(--section-spacing));
}
.container.aem-GridColumn .text-media + .text-media {
  margin-top: 0;
  padding-top: 1.875rem !important;
}
@media (max-width: 575.98px) {
  .container.aem-GridColumn .text-media + .text-media {
    padding-top: 0 !important;
    margin-top: -0.625rem;
  }
}
.cta-button--center {
  text-align: center;
}
.cta-button.icon-only .cmp-cta-button {
  min-height: 3.4375rem;
  padding: 0.5rem 1.375rem;
}
.cta-button.icon-only .cmp-cta-button__icon {
  margin: 0;
}
.cta-button .cmp-cta-button__icon:only-child {
  margin: 0;
}
.cmp-cta-button {
  font-weight: 600;
  border-radius: 1.8125rem;
  letter-spacing: 2.08px;
  text-transform: uppercase;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  font-size: 1rem;
  line-height: 2.125;
  min-height: 3.6875rem;
  background-color: #009fa5;
  color: #fff;
  border: 2px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0.625rem 1.5rem;
  cursor: pointer;
}
.cmp-cta-button:hover {
  background-color: #006b6f;
}
.cmp-cta-button__icon {
  margin-left: 0.625rem;
}
.cmp-cta-button__icon.rotate-180deg {
  margin-left: 0;
  margin-right: 0.625rem;
}
.cmp-cta-button--style-teal-outline {
  background-color: transparent;
  border-color: #009fa5;
  color: #009fa5;
}
.cmp-cta-button--style-teal-outline:hover {
  background-color: #009fa5;
  color: #fff;
  border-color: #009fa5;
}
.cmp-cta-button--style-white-outline {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.cmp-cta-button--style-white-outline:hover {
  background-color: #fff;
  color: #009fa5;
  border-color: #fff;
}
.cmp-cta-button--style-black-outline {
  background-color: transparent;
  border-color: #212b32;
  color: #212b32;
}
.cmp-cta-button--style-black-outline:hover {
  background-color: #212b32;
  color: #fff;
  border-color: #212b32;
}
.cmp-cta-button--style-white-filled, .cmp-cta-button--style-white-filled:hover {
  background-color: #fff;
  color: #009fa5;
}
.cmp-search-filter .title {
  padding-top: 0;
}
@media (min-width: 576px) {
  .cmp-search-filter__filtering {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.cmp-search-filter__search {
  position: relative;
}
@media (min-width: 576px) {
  .cmp-search-filter__search {
    margin-right: 0.9375rem;
  }
}
@media (max-width: 575.98px) {
  .cmp-search-filter__search {
    margin-bottom: 1.875rem;
  }
}
.cmp-search-filter__search:hover button {
  color: #009fa5;
}
.cmp-search-filter__search:hover input {
  color: #009fa5;
}
.cmp-search-filter__search:hover input::-webkit-input-placeholder {
  color: #009fa5;
}
.cmp-search-filter__search:hover input:-moz-placeholder {
  color: #009fa5;
}
.cmp-search-filter__search:hover input::-moz-placeholder {
  color: #009fa5;
}
.cmp-search-filter__search:hover input:-ms-input-placeholder {
  color: #009fa5;
}
.cmp-search-filter__button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  top: var(--input-padding-y);
  right: var(--input-padding-x);
  margin: auto;
  display: inline-block;
  padding: 0;
  background: transparent;
  border: 0;
  color: #212b32;
}
.cmp-search-filter__button svg {
  max-width: 1rem;
}
.cmp-search-filter__roles, .cmp-search-filter__search {
  width: 100%;
}
@media (min-width: 576px) {
  .cmp-search-filter__roles, .cmp-search-filter__search {
    max-width: 20rem;
  }
}
@media (min-width: 576px) {
  .cmp-search-filter__roles {
    margin-left: 0.9375rem;
  }
}
.cmp-search-filter__member {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3.121875rem;
}
@media (max-width: 575.98px) {
  .cmp-search-filter__member {
    margin-bottom: 1.875rem;
  }
}
.cmp-search-filter__member-photo {
  -o-object-fit: cover;
     object-fit: cover;
  width: 10.625rem;
  height: 10.625rem;
  border-radius: 1.25rem;
  -webkit-box-shadow: 0 20px 8px rgba(56, 112, 183, 0.24);
          box-shadow: 0 20px 8px rgba(56, 112, 183, 0.24);
  margin-right: 1.875rem;
}
@media (max-width: 575.98px) {
  .cmp-search-filter__member-photo {
    width: 8.1875rem;
    height: 8.1875rem;
    margin-right: 1.246875rem;
  }
}
.cmp-search-filter__member-name {
  font-family: "IBM Plex Serif", serif;
  color: #009fa5;
  font-size: 1.875rem;
  letter-spacing: -0.3px;
  font-style: italic;
  margin-bottom: 0.9375rem;
}
@media (max-width: 575.98px) {
  .cmp-search-filter__member-name {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
}
.cmp-search-filter__member-position {
  line-height: 1.23;
  font-weight: 500;
  font-size: 1.625rem;
  margin-bottom: 1.875rem;
}
@media (max-width: 575.98px) {
  .cmp-search-filter__member-position {
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
  }
}
.cmp-search-filter input,
.cmp-search-filter select {
  font-size: var(--input-font-size);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--input-background-color);
  border-radius: var(--input-border-radius);
  color: #212b32;
  border: 0;
  padding: var(--input-padding-y) var(--input-padding-x);
  width: 100%;
  height: var(--input-height);
  line-height: var(--input-line-height);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.08px;
}
.cmp-search-filter input:focus,
.cmp-search-filter select:focus {
  outline: rgba(0, 159, 165, 0.1);
}
.cmp-search-filter input::-webkit-input-placeholder,
.cmp-search-filter select::-webkit-input-placeholder {
  color: #212b32;
  opacity: 1;
}
.cmp-search-filter input:-moz-placeholder,
.cmp-search-filter select:-moz-placeholder {
  color: #212b32;
  opacity: 1;
}
.cmp-search-filter input::-moz-placeholder,
.cmp-search-filter select::-moz-placeholder {
  color: #212b32;
  opacity: 1;
}
.cmp-search-filter input:-ms-input-placeholder,
.cmp-search-filter select:-ms-input-placeholder {
  color: #212b32;
  opacity: 1;
}
.cmp-search-filter .custom-select {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.08px;
  font-size: var(--input-font-size);
  position: relative;
  z-index: 1;
}
.cmp-search-filter .custom-select__label {
  display: block;
  padding: var(--input-padding-y) var(--input-padding-x);
  color: #212b32;
  height: var(--input-height);
  background: #fff;
  border-radius: var(--input-border-radius);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--input-line-height);
  cursor: pointer;
}
.cmp-search-filter .custom-select__arrow {
  position: absolute;
  right: var(--input-padding-x);
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
  border: solid #212b32;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  border-top-right-radius: 30%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.cmp-search-filter .custom-select__list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: #fff;
  border-bottom-left-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
  padding-bottom: 1.25rem;
  visibility: hidden;
}
.cmp-search-filter .custom-select__list li {
  padding: 0.3125rem var(--input-padding-x);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.cmp-search-filter .custom-select__list li:hover, .cmp-search-filter .custom-select__list li.current {
  font-weight: 700;
}
.cmp-search-filter .custom-select:hover .custom-select__label {
  color: #009fa5;
}
.cmp-search-filter .custom-select:hover .custom-select__arrow {
  border-color: #009fa5;
}
.cmp-search-filter .expanded .custom-select__arrow {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  border-color: #009fa5;
}
.cmp-search-filter .expanded .custom-select__list {
  visibility: visible;
}
.cmp-search-filter .expanded .custom-select__label {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #009fa5;
}
.cmp-search-filter__no-results {
  font-size: 1.25rem;
}
.cmp-lab-member .title {
  padding-top: 0;
}
.cmp-lab-member__back {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.cmp-lab-member__back .cmp-cta-button__icon {
  margin-left: 0;
  margin-right: 0.625rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.cmp-lab-member__name {
  color: #009fa5;
  font-family: "IBM Plex Serif", serif;
  font-style: italic;
  font-size: 1.875rem;
  margin-bottom: 0.9375rem;
}
@media (max-width: 575.98px) {
  .cmp-lab-member__name {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
}
.cmp-lab-member__position {
  font-size: 1.625rem;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 575.98px) {
  .cmp-lab-member__position {
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
  }
}
.cmp-lab-member .image {
  margin-top: 0;
  margin-bottom: 1.875rem;
}
.cmp-columncontainer .cmp-container > .aem-Grid > .aem-GridColumn {
  padding-left: 0;
  padding-right: 0;
}
.cmp-columncontainer .columns-count-2 .column {
  max-width: 50%;
}
@media (max-width: 575.98px) {
  .cmp-columncontainer .columns-count-2 .column {
    max-width: 100%;
  }
}
.cmp-columncontainer .columns-count-3 .column {
  max-width: 33.33%;
}
@media (max-width: 575.98px) {
  .cmp-columncontainer .columns-count-3 .column {
    max-width: 100%;
  }
}
.cmp-columncontainer .columns-count-4 .column {
  max-width: 25%;
}
@media (max-width: 991.98px) {
  .cmp-columncontainer .columns-count-4 .column {
    max-width: 50%;
  }
  .cmp-columncontainer .columns-count-4 .column:nth-child(-n+2) {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .cmp-columncontainer .columns-count-4 .column {
    max-width: 100%;
  }
  .cmp-columncontainer .columns-count-4 .column:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .cmp-columncontainer .column {
    margin-bottom: 1.875rem;
  }
  .cmp-columncontainer .column:last-child {
    margin-bottom: 0;
  }
}
.cmp-columncontainer .column .cmp-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.cmp-columncontainer .column .tile {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.cmp-columncontainer .column .cmp-cta-button {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 991.98px) {
  .cmp-columncontainer .column .cmp-cta-button {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
@media (max-width: 575.98px) {
  .cmp-columncontainer .column .cmp-cta-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.cmp-columncontainer .column .text.text--md + .text {
  margin-top: 0.9375rem;
}
.cmp-in-page-anchors {
  padding-bottom: var(--section-spacing);
}
@media (min-width: 576px) {
  .cmp-in-page-anchors {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.cmp-in-page-anchors__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #009fa5;
  font-weight: 500;
  text-align: center;
  max-width: 25%;
  width: 100%;
  text-decoration: none;
  font-size: 1.625rem;
}
@media (max-width: 575.98px) {
  .cmp-in-page-anchors__item {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
.cmp-in-page-anchors__item .cta-button {
  margin-top: auto;
}
.cmp-in-page-anchors__item span:not(:last-child) {
  margin-bottom: 3.125rem;
}
@media (max-width: 575.98px) {
  .cmp-in-page-anchors__item span:not(:last-child) {
    margin-bottom: 0.3125rem;
  }
}
.cmp-in-page-anchors__item sup {
  font-size: 100%;
  font-weight: 300;
  top: 0;
}
.cmp-in-page-anchors__item + .cmp-in-page-anchors__item {
  margin-top: 2.49375rem;
}
@media (min-width: 576px) {
  .cmp-in-page-anchors__item + .cmp-in-page-anchors__item {
    margin-left: 1.875rem;
    margin-top: 0;
  }
}
.in-page-anchors + .component {
  padding-top: 0;
}
.in-page-anchors + .container.aem-GridColumn {
  padding-top: 0;
}
.cmp-supporters-list {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}
.cmp-supporters-list__item-large {
  display: none;
  margin-bottom: var(--section-spacing);
}
.cmp-supporters-list__item-large.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cmp-supporters-list__item-large-logo img {
  border-radius: 1.25rem;
  -webkit-box-shadow: 0 23px 20px rgba(56, 112, 183, 0.24);
          box-shadow: 0 23px 20px rgba(56, 112, 183, 0.24);
}
.cmp-supporters-list__item-large-content {
  font-size: 1.25rem;
}
@media (max-width: 575.98px) {
  .cmp-supporters-list__item-large-content {
    text-align: center;
  }
}
.cmp-supporters-list__item-large-content p:last-child {
  margin-bottom: 0;
}
.cmp-supporters-list__logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.cmp-supporters-list__logo {
  width: 25%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}
@media (max-width: 575.98px) {
  .cmp-supporters-list__logo {
    width: 50%;
  }
}
.cmp-supporters-list__logo img {
  background-color: #f1f9fa;
  border-radius: 1.25rem;
  -webkit-box-shadow: 0 23px 20px rgba(56, 112, 183, 0.24);
          box-shadow: 0 23px 20px rgba(56, 112, 183, 0.24);
  padding: 0.625rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.cmp-supporters-list__logo a:hover img {
  background-color: #009fa5;
}
.cmp-supporters-list .partners__arrows {
  text-align: center;
  display: none;
  margin-top: 1.875rem;
}
@media (max-width: 575.98px) {
  .cmp-supporters-list .partners__arrows {
    display: block;
  }
}
.cmp-supporters-list .partners__arrows a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 5.5625rem;
  height: 5.5625rem;
  border-radius: 50%;
  border: 2px solid #009fa5;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
  color: #009fa5;
}
.cmp-supporters-list .partners__arrows a + a {
  margin-left: 1.875rem;
}
.cmp-supporters-list .partners__arrows span {
  pointer-events: none;
}
.cmp-supporters-list .partners__arrows .disabled {
  opacity: 0.3;
}
.cmp-supporters-list .partners__arrows .arrow-prev span {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.cmp-newsroom-list {
  background-color: transparent;
}
.cmp-newsroom-list .cmp-newsroom-list__wrapper + .cmp-newsroom-list__wrapper {
  padding-top: var(--section-spacing);
}
.cmp-newsroom-list__page {
  display: none;
}
.cmp-newsroom-list__page.active {
  display: block;
}
.cmp-newsroom-list__title {
  text-align: center;
}
.cmp-newsroom-list-item {
  border-radius: 1.25rem;
  padding: 1.875rem;
  position: relative;
  margin-bottom: 1.875rem;
}
.cmp-newsroom-list-item:last-child {
  margin-bottom: 0;
}
.cmp-newsroom-list-item .cmp-newsroom-list-item--pr-date {
  display: none;
}
.cmp-newsroom-list-item .item-title {
  font-size: 1.5rem;
  font-family: "IBM Plex Serif", serif;
  line-height: 1.2;
  font-style: italic;
  letter-spacing: -0.24px;
  margin-bottom: 1.246875rem;
  color: #009fa5;
}
.cmp-newsroom-list-item .item-title:last-child {
  margin-bottom: 0;
}
.cmp-newsroom-list-item .item-content {
  font-size: 1.25rem;
}
.cmp-newsroom-list-item .item-content p {
  margin-bottom: 0;
}
.cmp-newsroom-list-item--card {
  background-color: #f1f9fa;
  border: 0;
  -webkit-filter: drop-shadow(0 23px 20px rgba(56, 112, 183, 0.25));
          filter: drop-shadow(0 23px 20px rgba(56, 112, 183, 0.25));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.cmp-newsroom-list-item--card .item-content:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.cmp-newsroom-list-item--card .cta-button {
  text-align: right;
  margin-top: auto;
}
.cmp-newsroom-list-item--card .cta-button .cmp-cta-button--text {
  display: none;
}
.cmp-newsroom-list-item--list {
  border: 1px solid rgba(0, 159, 165, 0.25);
}
@media (min-width: 576px) {
  .cmp-newsroom-list-item--list .item-title,
.cmp-newsroom-list-item--list .item-content {
    max-width: 90%;
  }
}
@media (min-width: 576px) {
  .cmp-newsroom-list-item--list .cta-button {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
  }
}
@media (max-width: 575.98px) {
  .cmp-newsroom-list-item--list .cta-button {
    text-align: right;
    margin-top: 2.49375rem;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
}
@media (min-width: 576px) {
  .cmp-newsroom-list-item--presentation.cmp-newsroom-list-item--list .item-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .cmp-newsroom-list-item--presentation.cmp-newsroom-list-item--list .item-content p + p {
    margin-left: 1.875rem;
  }
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper {
  position: relative;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper:after {
  content: "";
  background-image: url("resources/images/gei-newsroom-flower.svg");
  position: absolute;
  right: 0;
  height: 428px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 190px;
  left: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: 20px;
}
@media (max-width: 1199.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper:after {
    height: 35vw;
    background-position-y: 160px;
  }
}
@media (max-width: 991.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper:after {
    background-position-y: 110px;
    height: 34vw;
  }
}
@media (max-width: 767.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper:after {
    display: none;
  }
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card {
  background-color: #fff;
  padding: 2.49375rem 1.5rem;
}
@media (max-width: 575.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card {
    margin-bottom: 1.5rem;
  }
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .cmp-newsroom-list-item--pr-date {
  display: block;
  color: #3870b7;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .cmp-newsroom-list-item--pr-date span::after {
  content: "";
  display: inline-block;
  height: 20px;
  width: 1px;
  background-color: #3870b7;
  margin: 0 10px;
  position: relative;
  bottom: -6px;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .item-title {
  margin-bottom: 1.875rem;
  font-size: 1.75rem;
  font-weight: 300;
  font-family: "Raleway", sans-serif;
  color: #000;
  font-style: normal;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .item-content:not(:last-child) {
  margin-bottom: 1.875rem;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .cta-button {
  text-align: left;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .cta-button .cmp-cta-button {
  background: unset;
  color: #009fa5;
  padding: 0;
  border: 0px;
  min-height: unset;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .cta-button .cmp-cta-button:hover {
  color: #006b6f;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .cta-button .cmp-cta-button--text {
  display: inline-block;
  margin-right: 1.25rem;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item--card .cta-button .cmp-cta-button__icon {
  margin: 0;
}
@media (max-width: 767.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item {
    position: relative;
  }
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper .cmp-newsroom-list-item:after {
    content: "";
    background-image: url("resources/images/gei-newsroom-flower.svg");
    position: absolute;
    right: 0;
    height: 350px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 265%;
    background-position: 50% 85px;
    left: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: 20px;
  }
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper.cmp-newsroom-list--has-description:after {
  background-size: contain;
  background-position-y: unset;
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper.cmp-newsroom-list--has-description .cmp-newsroom-list-item:after {
  background-position: 50% 23px;
}
@media (max-width: 420px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--flower-wrapper.cmp-newsroom-list--has-description .cmp-newsroom-list-item:after {
    height: 300px;
  }
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--two::after {
  background-position-y: 260px;
}
@media (max-width: 1199.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--two::after {
    background-position-y: 200px;
  }
}
@media (max-width: 991.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--two::after {
    background-position-y: 170px;
  }
}
.cmp-newsroom-list__pr-flower .cmp-newsroom-list--two.cmp-newsroom-list--has-description::after {
  background-position-y: 115px;
}
@media (max-width: 1199.98px) {
  .cmp-newsroom-list__pr-flower .cmp-newsroom-list--two.cmp-newsroom-list--has-description::after {
    background-position-y: 95px;
  }
}
.cmp-newsroom-list__pagination {
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: var(--section-spacing);
}
.cmp-newsroom-list__pagination,
.cmp-newsroom-list__pagination a {
  color: #009fa5;
}
.cmp-newsroom-list__pagination > span {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  color: #212b32;
}
.cmp-newsroom-list__pagination a {
  text-decoration: none;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.cmp-newsroom-list__pagination a.current {
  font-weight: 700;
  color: #212b32;
}
.cmp-newsroom-list__pagination .arrow-next {
  margin-left: 1.25rem;
}
.cmp-newsroom-list__pagination .arrow-prev {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 1.25rem;
}
.cmp-newsroom-list__pagination .arrow-prev, .cmp-newsroom-list__pagination .arrow-next {
  color: #009fa5;
}
.cmp-newsroom-list__pagination .arrow-prev span, .cmp-newsroom-list__pagination .arrow-next span {
  pointer-events: none;
}
.cmp-newsroom-list__pagination .arrow-prev.disabled, .cmp-newsroom-list__pagination .arrow-next.disabled {
  pointer-events: none;
  opacity: 0.5;
  color: #009fa5;
  font-weight: normal;
}
.cmp-newsroom-list .cmp-cta-button--text {
  display: none;
}
.cmp-newsroom-list .cmp-newsroom-list__three,
.cmp-newsroom-list .cmp-newsroom-list__two {
  gap: 1.875rem;
}
@media (min-width: 576px) {
  .cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--list,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--list {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    margin-bottom: 0;
  }
  .cmp-newsroom-list .cmp-newsroom-list__three.active,
.cmp-newsroom-list .cmp-newsroom-list__two.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card {
  border-radius: 1.875rem;
  background: transparent;
  padding: 2.5rem 1.5rem;
  border: 1px solid #fff;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-title,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-title {
  margin-bottom: 1.875rem;
  font-style: unset;
  font-weight: 300;
  font-size: 1.75rem;
  font-family: "Raleway", sans-serif;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-content:not(:last-child),
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-content:not(:last-child) {
  margin-bottom: 1.875rem;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-content p,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-content p {
  font-size: 0.9375rem;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  line-height: 1.133;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-content p:not(:last-child),
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-content p:not(:last-child) {
  margin-bottom: 0.5625rem;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-content p span,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-content p span {
  display: inline-block;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-content p .item-content--text,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-content p .item-content--text {
  width: 132px;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 132px;
          flex: 0 0 132px;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-content p .item-content--text::after,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-content p .item-content--text::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 1.25rem;
  background-color: #fff;
  position: absolute;
  right: 0.9375rem;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-title,
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .item-content,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-title,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .item-content {
  color: #fff;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .cta-button,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .cta-button {
  text-align: left;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .cmp-cta-button,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .cmp-cta-button {
  background-color: transparent;
  color: #fff;
  padding: 0;
  margin: 0;
  border: 0;
  font-weight: bold;
  min-height: unset;
  line-height: 1;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--presentation-card .cmp-cta-button--text,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--presentation-card .cmp-cta-button--text {
  display: inline-block;
  margin-right: 1.25rem;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news {
  background-color: transparent;
  padding: 0;
  -webkit-filter: unset;
          filter: unset;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-image,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-image {
  border-radius: 0.5rem;
  -webkit-box-shadow: 1px 22px 20px 0px rgba(56, 112, 183, 0.25);
          box-shadow: 1px 22px 20px 0px rgba(56, 112, 183, 0.25);
  height: 16rem;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-image img,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-image img {
  border-radius: 0.5rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 100%;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-content,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-content {
  padding: 2.5rem 1.5rem;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 767.98px) {
  .cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-content,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-content {
    padding: 2.5rem 0;
  }
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .item-title,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .item-title {
  margin-bottom: 1.875rem;
  font-family: "Raleway", sans-serif;
  font-size: 1.75rem;
  font-weight: 300;
  font-style: normal;
  color: #000;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button {
  text-align: left;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #009fa5;
  font-weight: bold;
  line-height: 1;
  min-height: unset;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button span:not(.cmp-cta-button__icon),
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button span:not(.cmp-cta-button__icon) {
  margin-right: 1.25rem;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button .cmp-cta-button__icon,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button .cmp-cta-button__icon {
  margin: 0;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button:hover,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button:hover {
  color: #006b6f;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button:hover .cmp-cta-button__icon,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button:hover .cmp-cta-button__icon {
  color: #006b6f;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date,
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date,
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date,
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date {
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  display: block;
  font-weight: bold;
  color: #3870b7;
  font-size: 0.9375rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: 1.875rem;
}
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date span::after,
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date span::after,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date span::after,
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date span::after,
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date span::after,
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date span::after {
  content: "";
  display: inline-block;
  height: 20px;
  width: 1px;
  background-color: #3870b7;
  margin: 0 0.9375rem;
  position: relative;
  bottom: -6px;
}
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-image {
  display: none;
}
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cta-button .cmp-cta-button span:not(.cmp-cta-button__icon) {
  display: none;
}
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--card.cmp-newsroom-list-item--news .cmp-newsroom-list-item--news-date {
  color: #000;
}
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .item-content,
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .item-content,
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .item-content {
  margin-bottom: 1.25rem;
}
.cmp-newsroom-list .cmp-newsroom-list__two .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .item-details,
.cmp-newsroom-list .cmp-newsroom-list__three .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .item-details,
.cmp-newsroom-list .cmp-newsroom-list__all .cmp-newsroom-list-item--list.cmp-newsroom-list-item--news .item-details {
  font-size: 1.25rem;
  margin: 0;
}
.newsroom-list + .cta-button {
  margin-top: -1.25rem;
}
@media (max-width: 575.98px) {
  .newsroom-list + .cta-button {
    margin-top: -2.5rem;
  }
}
.cmp-newsroom-list-item + .cmp-newsroom-list__title {
  margin-top: var(--section-spacing);
}
.container.aem-GridColumn .newsroom-list + .cta-button {
  margin-top: 1.875rem;
}
@media (max-width: 575.98px) {
  .container.aem-GridColumn .newsroom-list + .cta-button {
    margin-top: 0;
  }
}
.cmp-newsroom-list__title-news {
  margin-bottom: 2.5rem;
  font-size: 2.5rem;
}
@media (max-width: 767.98px) {
  .cmp-newsroom-list__title-news {
    margin-bottom: 1.875rem;
  }
}
.cmp-newsroom-list__title-news::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #EAF0F8;
  display: block;
  margin-top: 10px;
}
.generic-hero {
  position: relative;
}
.generic-hero .hero:after {
  content: "";
  display: block;
  overflow: hidden;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
}
.generic-hero .hero.bg-color-white:after {
  background-color: #fff;
}
.generic-hero .hero.bg-color-primary-blue:after {
  background-color: #3870b7;
}
.generic-hero .hero.bg-color-teal:after {
  background-color: #009fa5;
}
.generic-hero .hero.bg-color-teal-10:after {
  background-color: #f1f9fa;
}
.generic-hero .cmp-hero {
  position: relative;
  z-index: 2;
}
.generic-hero .cmp-hero__image {
  margin-top: 0;
  margin-bottom: -2.25rem;
}
.generic-hero .cmp-hero__title {
  padding-top: 0;
}
.generic-hero .cmp-hero-flower {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
}
.generic-hero .cmp-hero-flower svg {
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: auto;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  vertical-align: middle;
}
@media (max-width: 575.98px) {
  .generic-hero .cmp-hero-flower svg {
    max-width: 190%;
    left: 50%;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%);
  }
}
.generic-hero .cmp-hero-flower__flower--teal .flower-color {
  fill: #009fa5;
}
@media (min-width: 768px) {
  .generic-hero .has-two-column-text .col-md-10 {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .generic-hero .has-two-column-text .cmp-hero__text {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.875rem;
       -moz-column-gap: 1.875rem;
            column-gap: 1.875rem;
    text-align: left;
  }
}
@media (min-width: 576px) {
  .hero--homepage .cmp-hero__title:first-child,
.hero--homepage .cmp-hero__section:first-child {
    margin-top: 6.25rem;
  }
}
.hero--homepage .cmp-hero__cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.hero--homepage .cmp-hero__text {
  max-width: 28.125rem;
  margin-bottom: 0.9375rem;
}
@media (min-width: 576px) {
  .hero--homepage .cmp-hero__text {
    margin-bottom: 3.125rem;
  }
}
@media (min-width: 576px) {
  .hero--homepage .cta-button + .cta-button {
    margin-left: 0.625rem;
  }
}
.hero--homepage .cmp-hero-flower svg {
  -webkit-transform: translate(34%, -50%);
          transform: translate(34%, -50%);
}
@media (max-width: 575.98px) {
  .hero--homepage .cmp-hero-flower svg {
    max-width: 300vw;
    -webkit-transform: translate(-50vw, 0%);
            transform: translate(-50vw, 0%);
    left: 0;
    top: 0;
  }
}
.hero--page .cmp-hero__text:last-child,
.hero--page .cmp-hero__title:last-child {
  margin-bottom: 4.36875rem;
}
.cmp-navigation {
  height: 100%;
}
.cmp-navigation a sup {
  font-size: 100%;
  top: -0.2em;
  margin-left: -2.8px;
}
.cmp-navigation__group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.cmp-navigation__group .mega-menu {
  display: none;
  position: absolute;
  top: 60%;
  left: -1.246875rem;
  padding: 1.246875rem;
  width: 15.625rem;
  border-radius: 0.625rem;
  overflow: hidden;
  height: auto;
  -webkit-box-shadow: 0 13px 10px rgba(0, 159, 165, 0.1);
          box-shadow: 0 13px 10px rgba(0, 159, 165, 0.1);
  background-color: #f1f9fa;
  z-index: 2;
}
.cmp-navigation__group .mega-menu li {
  margin-bottom: 0.625rem;
}
.cmp-navigation__group .mega-menu li + li {
  margin-left: 0;
}
.cmp-navigation__group .mega-menu li:last-child {
  margin-bottom: 0;
}
.cmp-navigation__group .mega-menu a {
  display: block;
  color: #212b32;
}
@media (max-width: 1024.98px) {
  .cmp-navigation__group .mega-menu a:hover {
    color: #009fa5;
  }
}
.cmp-navigation__group .mega-menu .cmp-navigation__item--active a {
  color: #009fa5;
}
.cmp-navigation__item-arrow {
  max-width: 0.6875rem;
  height: auto;
  width: auto;
  margin-left: 0.625rem;
}
.cmp-navigation__item-arrow path {
  stroke: currentColor;
}
.cmp-navigation__item {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cmp-navigation__item:hover .mega-menu {
  display: block;
}
.cmp-navigation__item a {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  text-decoration: none;
  color: #212b32;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.cmp-navigation__item + .cmp-navigation__item {
  margin-left: 1.25rem;
}
@media (min-width: 1025px) {
  .cmp-navigation__item:hover > a {
    color: #009fa5;
  }
}
.cmp-navigation__item--active > a {
  color: #009fa5;
}
.cmp-navigation__item:hover .cmp-navigation__item-arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.cmp-navigation .search-button {
  width: 1.5rem;
}
@media (max-width: 1024.98px) {
  .cmp-navigation .search-button {
    margin-right: 1.25rem;
  }
}
@media (min-width: 1025px) {
  .cmp-navigation .search-button:hover {
    color: #44ba87;
  }
}
.cmp-navigation .search-button.is-open .search-icon {
  display: none;
}
.cmp-navigation .search-button.is-open .close-icon {
  display: block;
}
.mega-menu-isOpen .cmp-navigation .search-button {
  display: none;
}
.cmp-navigation .search-icon {
  display: inline-block;
  width: 1.4375rem;
  height: auto;
}
@media (max-width: 1024.98px) {
  .cmp-navigation .search-icon {
    width: 1.25rem;
  }
}
.cmp-navigation .close-icon {
  display: none;
  max-width: 1.125rem;
}
@media (max-width: 1024.98px) {
  .cmp-navigation .show-desktopMenu {
    display: none;
  }
}
@media (min-width: 1025px) {
  .cmp-navigation .hide-desktopMenu {
    display: none;
  }
}
.cmp-navigation__item-mobile {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@media (min-width: 1025px) {
  .cmp-navigation__item-mobile {
    display: none;
  }
}
.mobile-toggle-menu {
  width: 1.4375rem;
  height: 0.5625rem;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.mobile-toggle-menu:before, .mobile-toggle-menu:after {
  display: none;
}
.mobile-toggle-menu span {
  position: absolute;
  width: 100%;
  height: 2px;
  right: 0;
  border-radius: 5px;
  background-color: #212b32;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.mobile-toggle-menu span:first-child {
  top: 0;
}
.mobile-toggle-menu span:last-child {
  bottom: 0;
  width: 75%;
}
.mobile-toggle-menu.active span:first-child {
  -webkit-transform: translateY(4px) rotate(45deg);
          transform: translateY(4px) rotate(45deg);
}
.mobile-toggle-menu.active span:last-child {
  -webkit-transform: translateY(-3px) rotate(-45deg);
          transform: translateY(-3px) rotate(-45deg);
  width: 100%;
}
.mega-menu__mobile {
  background-color: #fff;
  position: fixed;
  top: 5rem;
  left: 0;
  width: 100%;
  margin-top: -1px;
  display: none;
  height: calc(100% - 5rem);
  z-index: 100;
}
@media (min-width: 1025px) {
  .mega-menu__mobile {
    display: none;
  }
}
@media (max-width: 1024.98px) {
  .mega-menu__mobile.open {
    display: block;
  }
}
.mega-menu__mobile ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mega-menu__mobile ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 1.125rem;
}
.mega-menu__mobile ul li .arrow {
  width: 6.25rem;
  text-align: right;
}
.mega-menu__mobile ul .active a {
  color: #009fa5;
}
.mega-menu__mobile ul a {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  text-decoration: none;
  color: #212b32;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  width: 100%;
}
.mega-menu__mobile .back-to-menu {
  color: #212b32;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  text-decoration: none;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  font-weight: 600;
  letter-spacing: 2.8px;
}
.mega-menu__mobile .back-to-menu .arrow {
  margin-right: 0.625rem;
}
.mega-menu__mobile .back-to-menu .arrow:after {
  -webkit-transform: rotate(130deg);
          transform: rotate(130deg);
}
.mega-menu__mobile .arrow {
  display: inline-block;
}
.mega-menu__mobile .arrow:after {
  content: "";
  border: solid #212b32;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  border-top-right-radius: 30%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.mega-menu__mobile .scroll-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mega-menu__mobile .column {
  width: 100vw;
  padding: 0.9375rem 1.246875rem;
}
@media (min-width: 768px) {
  .mega-menu__mobile .column {
    width: 50vw;
  }
}
.mega-menu__mobile .menu-group {
  display: none;
}
.mega-menu__mobile .menu-group.active {
  display: block;
}
#top-search-modal {
  position: absolute;
  top: 100%;
  width: 18.75rem;
  padding: 0.625rem;
  background-color: #fff;
  display: none;
}
@media (max-width: 575.98px) {
  #top-search-modal {
    width: 100%;
    left: 0;
    z-index: 10;
  }
}
#top-search-modal .cmp_modal__header {
  display: none;
}
#top-search-modal .cmp-search__input {
  height: 100%;
  width: 100%;
  font-size: 16px;
  border: 1px solid #dfe1e5;
  background: #fff;
  padding: 5px 9px 4px 30px;
}
#top-search-modal .cmp-search__input:focus {
  outline: 0;
}
#top-search-modal .cmp-search__results-wrapper p {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
#top-search-modal .cmp-search__results-wrapper p:last-child {
  margin-bottom: 0;
}
#top-search-modal .cmp-search__results-wrapper a {
  color: #009fa5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 2.8px;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
}
#top-search-modal .cmp-search__results-wrapper a svg {
  margin-left: 0.625rem;
}
#top-search-modal.open {
  display: block;
}
.root.container .xf-content-height {
  min-height: inherit;
  margin: 0;
}
.root.container > .cmp-container > section > .cmp-container section:first-child {
  padding-bottom: 4.36875rem;
}
.root.container > .cmp-container > section > .cmp-container section:first-child .component:not(.image) {
  padding-bottom: 0;
}
.root.container > .cmp-container > section > .cmp-container section:first-child,
.root.container > .cmp-container > section > .cmp-container .generic-hero:first-child {
  padding-top: calc(10.625rem + 2.5rem);
}
@media (max-width: 1024.98px) {
  .root.container > .cmp-container > section > .cmp-container section:first-child,
.root.container > .cmp-container > section > .cmp-container .generic-hero:first-child {
    padding-top: calc(5rem + 3.75rem);
  }
}
.container {
  position: relative;
}
.container.aem-GridColumn {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}
.container.aem-GridColumn .container.aem-GridColumn:not(.has-bg) {
  padding-top: 0;
  padding-bottom: 0;
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .component:not(.image):first-child {
  padding-top: 0;
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .component:not(.image):last-child {
  padding-bottom: 0;
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .component:not(.image) .component:not(.image) {
  padding-top: 0;
  padding-bottom: 0;
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .component:not(.image) p:last-child {
  margin-bottom: 0;
}
.container.aem-GridColumn:not(.container--flower-style):not(.container--border-style) .component:not(.image) + .component:not(.image) {
  padding-top: 0;
}
.container .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.generic-hero):not(.has-bg) + .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) {
  padding-top: 0;
}
.component:not(.image) {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}
.component:not(.image) .component:not(.image) {
  padding-top: 0;
  padding-bottom: 0;
}
.component:not(.image) p:last-child {
  margin-bottom: 0;
}
.component:not(.image) + .component:not(.image) {
  padding-top: 0;
}
.component:not(.image) + .responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg),
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) + .component:not(.image) {
  padding-top: 0;
}
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg) .component:not(.image) {
  padding-top: 0;
}
.container--flower-style .component:not(.image),
.cmp-textmedia .component:not(.image),
.container--border-style .component:not(.image) {
  padding-top: 0;
  padding-bottom: 0;
}
.container--flower-style .component.text,
.cmp-textmedia .component.text,
.container--border-style .component.text {
  margin-bottom: 1.875rem !important;
}
.container--flower-style .component.text:last-child,
.cmp-textmedia .component.text:last-child,
.container--border-style .component.text:last-child {
  margin-bottom: 0 !important;
}
.cmp-container[class*=bg-color-] {
  background-color: transparent;
}
.container--bg-full-style {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.container-image,
.container-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container-image {
  z-index: 2;
  background-position: center;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}
.container-color {
  z-index: 1;
}
.container--border-style > .cmp-container {
  border: 2px solid #fff;
  border-radius: 1.25rem;
  padding: 3.906vw 5.885vw;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
@media (min-width: 1920px) {
  .container--border-style > .cmp-container {
    padding: 4.6875rem 7.0625rem;
  }
}
@media (max-width: 575.98px) {
  .container--border-style > .cmp-container {
    padding: 15.625vw 5.417vw;
  }
}
.container--border-style > .cmp-container.bg-color-white {
  background-color: rgba(255, 255, 255, 0.56);
}
.container--border-style > .cmp-container.bg-color-primary-blue {
  background-color: rgba(56, 112, 183, 0.56);
}
.container--border-style > .cmp-container.bg-color-teal {
  background-color: rgba(0, 159, 165, 0.56);
}
.container--border-style > .cmp-container.bg-color-teal-10 {
  background-color: rgba(241, 249, 250, 0.56);
}
.container--border-style > .cmp-container .aem-GridColumn {
  padding-left: 0;
  padding-right: 0;
}
.container--border-style .cta-button {
  margin-top: 0.625rem;
}
.container--flower-style__svg {
  display: none;
}
.container--flower-style {
  min-height: 42.3125rem;
}
.container--flower-style .container--flower-style__svg {
  display: block;
  overflow: hidden;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
.container--flower-style .container--flower-style__svg:after {
  content: "";
  background-image: url("resources/images/gei-flower.svg");
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
  height: 54.375rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  left: 59vw;
}
@media (max-width: 991.98px) {
  .container--flower-style .container--flower-style__svg:after {
    -webkit-transform: translateY(63%);
            transform: translateY(63%);
    max-width: 27.1875rem;
  }
}
@media (max-width: 575.98px) {
  .container--flower-style .container--flower-style__svg:after {
    max-width: 100%;
    -webkit-transform: translateY(54%);
            transform: translateY(54%);
    height: 105vw;
    left: 0;
  }
}
.container--flower-style > .cmp-container a {
  font-weight: 500;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .container--flower-style > .cmp-container {
    max-width: 50%;
  }
}
@media (max-width: 575.98px) {
  .container--flower-style > .cmp-container {
    padding-bottom: 35vw;
  }
}
.container--flower-style .title h2, .container--flower-style .title .text-media .text .cmp-title__text, .text-media .text .container--flower-style .title .cmp-title__text {
  font-size: 2.5rem;
  line-height: 1.5;
}
@media (max-width: 576px) {
  .container--flower-style .title h2, .container--flower-style .title .text-media .text .cmp-title__text, .text-media .text .container--flower-style .title .cmp-title__text {
    font-size: 1.875rem;
  }
}
.container--flower-style .image {
  margin-top: 0;
  margin-bottom: 1.875rem;
}
.container--flower-style .image img {
  max-width: 100%;
  width: auto;
}
@media (max-width: 575.98px) {
  .container--flower-style.container.aem-GridColumn {
    padding-top: 1.875rem;
  }
}
@supports not (-webkit-touch-callout: none) {
  .container-image {
    background-attachment: fixed;
  }
}
body > .root.container > .cmp-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}
.aem-AuthorLayer-Preview .cq-Editable-dom--container.aem-GridColumn {
  padding-bottom: var(--section-spacing);
  padding-top: var(--section-spacing);
}
.cmp-accordion__item {
  width: 100%;
  overflow: hidden;
  border-radius: 1.25rem;
  border: 2px solid #009fa5;
  margin-bottom: 1.246875rem;
  position: relative;
}
.cmp-accordion__item:last-child {
  margin-bottom: 0;
}
.cmp-accordion__button {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 1.875rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  line-height: inherit;
  outline: 0;
  color: #212b32;
}
.cmp-accordion__button:hover .cmp-accordion__title {
  color: #006b6f;
}
.cmp-accordion__button:hover .cmp-accordion__icon:after {
  border-color: #006b6f;
}
@media (max-width: 575.98px) {
  .cmp-accordion__button {
    padding: 1.246875rem;
  }
}
.cmp-accordion__button--expanded .cmp-accordion__icon:after {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}
.cmp-accordion__title {
  color: #009fa5;
  font-style: italic;
  font-family: "IBM Plex Serif", serif;
  max-width: 90%;
}
.cmp-accordion__icon::after {
  content: "";
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  border: solid #009fa5;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 8px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: absolute;
  right: 1.875rem;
  top: 1.875rem;
}
@media (max-width: 575.98px) {
  .cmp-accordion__icon::after {
    padding: 4px;
    right: 1.246875rem;
    top: 1.246875rem;
  }
}
.cmp-accordion__icon svg {
  display: none;
}
.cmp-accordion__header {
  font-size: 1.5rem;
  line-height: 1.2;
  cursor: pointer;
  letter-spacing: -0.24px;
}
.cmp-accordion__header p {
  font-size: 1.25rem;
  width: 100%;
  margin-top: 1.246875rem;
  margin-bottom: 0;
  line-height: 1.5;
}
.cmp-accordion__panel {
  padding: 0 1.875rem 1.875rem;
}
@media (max-width: 575.98px) {
  .cmp-accordion__panel {
    padding: 0 1.21875rem 1.21875rem;
  }
}
.cmp-accordion__panel .aem-GridColumn {
  padding-left: 0;
  padding-right: 0;
}
.cmp-accordion .image {
  margin-top: 0 !important;
}
.accordion .component:not(:last-child) {
  margin-bottom: 1.875rem;
}
.experiencefragment.footer {
  margin-top: auto;
}
#main-footer {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
@media (min-width: 992px) {
  #main-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (max-width: 1024.98px) {
  #main-footer {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
}
#main-footer > .image,
#main-footer > .container {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
#main-footer > .image {
  width: 83.33%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  #main-footer > .image {
    padding: 0;
  }
}
@media (min-width: 992px) {
  #main-footer > .image {
    width: 25%;
    margin: 0;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
#main-footer > .image .cmp-image__link {
  display: inline-block;
}
@media (min-width: 992px) {
  #main-footer > .container {
    width: 75%;
  }
}
@media (min-width: 768px) {
  #main-footer > .container {
    width: 66.66%;
  }
}
#main-footer > .container > .cmp-container > .aem-Grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 991.98px) {
  #main-footer > .container > .cmp-container > .aem-Grid {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 83.33%;
  }
}
#main-footer > .container > .cmp-container > .aem-Grid .button {
  width: auto !important;
  padding-left: 0.46875rem !important;
  padding-right: 0.46875rem !important;
}
#main-footer > .container > .cmp-container > .aem-Grid .button .cmp-button__text {
  font-size: 1.125rem;
  font-weight: 500;
}
#main-footer > .container > .cmp-container > .aem-Grid .button svg {
  vertical-align: middle;
}
#main-footer .cmp-container section:first-child,
#main-footer .container.aem-GridColumn {
  padding: 0;
}
#main-footer .aem-GridColumn--offset--default--1 {
  margin-left: 0;
}
#main-footer .cmp-container section:first-child {
  margin-right: 1.40625rem;
}
@media (min-width: 992px) {
  #main-footer .cmp-container section:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
  }
}
#main-footer .cmp-container section:last-child {
  width: auto;
}
@media (max-width: 991.98px) {
  #main-footer .cmp-container section:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    margin-top: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  #main-footer .cmp-container section {
    width: 100%;
  }
}
#main-footer .component {
  padding-top: 0;
  padding-bottom: 0;
}
#main-footer .image {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  #main-footer .image {
    margin-bottom: 3.125rem;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.header:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
  z-index: -1;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
@media (max-width: 1024.98px) {
  .mega-menu-isOpen .header:after {
    background-color: #fff;
    -webkit-transition: none;
    transition: none;
  }
}
.header .image, .header .navigation {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
@media (max-width: 992px) {
  .header .image, .header .navigation {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.header .image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 58.33%;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .header .image {
    width: 33.33%;
  }
}
@media (min-width: 992px) {
  .header .image {
    width: 25%;
  }
}
.header .navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 41.66%;
}
@media (min-width: 768px) {
  .header .navigation {
    width: 66.66%;
  }
}
@media (min-width: 992px) {
  .header .navigation {
    width: 75%;
  }
}
.header--sticky:after {
  background-color: rgba(241, 249, 250, 0.8);
}
@media (min-width: 1025px) {
  .header--sticky #main-header {
    min-height: 3.125rem;
  }
}
.header--sticky .cmp-navigation__group .mega-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background-color: rgba(241, 249, 250, 0.6);
  top: 100%;
}
#main-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 10.625rem;
  -webkit-transition: min-height 0.3s;
  transition: min-height 0.3s;
  line-height: normal;
}
@media (max-width: 1024.98px) {
  #main-header {
    min-height: 5rem;
  }
}
#main-header > .aem-Grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cmp-embed-youtube, .cmp-embed__youtube, .cmp-embed-vimeo {
  position: relative;
  height: 0;
  border-radius: 1.25rem;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.cmp-embed-youtube iframe, .cmp-embed__youtube iframe, .cmp-embed-vimeo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cmp-embed.cmp-embed-selfhostedvideo {
  border-radius: 1.25rem;
  overflow: hidden;
}
.cmp-embed.cmp-embed-selfhostedvideo video {
  width: 100%;
  vertical-align: middle;
}
.embed + .text + .cta-button {
  margin-top: -2.1875rem;
}
/*# sourceMappingURL=theme.css.map */
