// Breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
  @if $mq2 == false {
    @media ($maxmin: $mq01) {
      @content;
    }
  } @else {
    @media (min-width: $mq01) and (max-width: $mq2) {
      @content;
    }
  }
}

// Responsive font size mixin
@mixin font-size($font-size, $multiply: 1) {
  @each $breakpoint, $font-size in $font-size {
    @if $breakpoint == null {
      font-size: $font-size * $multiply;
    } @else {
      @include breakpoint($breakpoint) {
        font-size: $font-size * $multiply;
      }
    }
  }
}

// Reset default list style
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin position($position: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
