/*
 *  Copyright 2018 Adobe Systems Incorporated
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

//** Icons Specific Fonts
$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: './resources/fonts' !default;
$icomoon-pin-outline: '\e900';
$icomoon-email: '\e901';
$icomoon-phone: '\e902';
$icomoon-arrow: '\e903';
$icomoon-arrow-outline: '\e904';
$icomoon-check: '\e905';
$icomoon-pin: '\e906';
$icomoon-log-in: '\e907';
$icomoon-search: '\e908';
$icomoon-user: '\e909';
$icomoon-facebook: '\e90a';
$icomoon-instagram: '\e90b';
$icomoon-pinterest: '\e90c';
$icomoon-twitter: '\e90d';
$icomoon-youtube: '\e90e';
$icomoon-download: '\e90f';
$icomoon-print: '\e910';
$icomoon-arrow-outline-fill: '\e911';
$icomoon-arrow-up: '\e912';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin iconstyle() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.4em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class$='__icomoon'],
[class*='__icomoon'],
[class$='-icomoon'],
[class*='-icomoon'] {
  @include iconstyle();
}

.icomoon-check:before {
  content: $icomoon-check;
}
.icomoon-search:before {
  content: $icomoon-search;
}
.icomoon-arrow:before {
  content: $icomoon-arrow;
}
.icomoon-email:before {
  content: $icomoon-email;
}
.icomoon-phone:before {
  content: $icomoon-phone;
}
.icomoon-pin:before {
  content: $icomoon-pin;
}
.icomoon-log-in:before {
  content: $icomoon-log-in;
}
.icomoon-user:before {
  content: $icomoon-user;
}
.icomoon-pin-outline:before {
  content: $icomoon-pin-outline;
}
.icomoon-arrow-outline:before {
  content: $icomoon-arrow-outline;
}
.icomoon-facebook:before {
  content: $icomoon-facebook;
}
.icomoon-instagram:before {
  content: $icomoon-instagram;
}
.icomoon-pinterest:before {
  content: $icomoon-pinterest;
}
.icomoon-twitter:before {
  content: $icomoon-twitter;
}
.icomoon-youtube:before {
  content: $icomoon-youtube;
}
.icomoon-download:before {
  content: $icomoon-download;
}
.icomoon-print:before {
  content: $icomoon-print;
}
.icomoon-arrow-outline-fill:before {
  content: $icomoon-arrow-outline-fill;
}
.icomoon-arrow-up:before {
  content: $icomoon-arrow-up;
}

.rotate-90deg {
  transform: rotate(90deg);
}
.rotate-90deg {
  transform: rotate(90deg);
}
.rotate-180deg {
  transform: rotate(180deg);
}
