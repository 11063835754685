.title {
  padding-top: var(--section-spacing);
  &:last-child {
    #{headings(1,6)}:last-child {
      margin-bottom: 0;
    }
  }
  &--center {
    text-align: center;
  }
  &--italic {
    font-style: italic;
    font-family: $font-serif;
    h2 {
      font-size: rem(40px);
    }
  }
  .cmp-title {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.tile + .tile {
  margin-top: calc($spacer * 2);
}
.title + .text-title--copy-divider {
  margin-top: calc((var(--section-spacing) / 2) - #{$p-spacer});
}
.title + .component {
  padding-top: 0 !important;
}
.container.aem-GridColumn {
  .title:first-child {
    padding-top: 0;
  }
  .component + .title {
    padding-top: 0;
  }
}
.responsivegrid.aem-GridColumn.aem-GridColumn--default--12:not(.has-bg)
  + .title {
  padding-top: 0;
}
.title + .title {
  padding-top: 0;
}
.in-page-anchors + .title {
  padding-top: 0;
}
.title + .image {
  margin-top: 0 !important;
}
.component:not(.image) + .title {
  padding-top: 0;
}
