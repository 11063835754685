.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
    z-index: -1;
    backdrop-filter: blur(2px);
    transition: background-color $transition-time;
    @include media-breakpoint-down(rn) {
      .mega-menu-isOpen & {
        background-color: $white;
        transition: none;
      }
    }
  }
  .image, .navigation {
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);
    @media (max-width: 992px) {
      padding-left: calc($grid-gutter-width / 1.5);
      padding-right: calc($grid-gutter-width / 1.5);
    }
  }
  .image {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: 58.33%;
    padding-top: $spacer;
    padding-bottom: $spacer;
    margin-top: 0;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      width: 33.33%;
    }
    @include media-breakpoint-up(lg) {
      width: 25%;
    }
  }
  .navigation {
    display: flex;
    justify-content: flex-end;
    width: 41.66%;
    
    @include media-breakpoint-up(md) {
      width: 66.66%;
    }
    @include media-breakpoint-up(lg) {
      width: 75%;
    }
  }
  &--sticky {
    &:after {
      background-color: rgba($teal-10, 80%);
    }
    
    #main-header {
      @include media-breakpoint-up(rn) {
        min-height: rem(50px);
      }
    }

    .cmp-navigation__group .mega-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      backdrop-filter: blur(2px);
      background-color: rgba($teal-10, 60%);
      top: 100%;
    }
  }
}
#main-header {
  display: flex;
  min-height: $header-height;
  transition: min-height $transition-time;
  line-height: normal;

  @include media-breakpoint-down(rn) {
    min-height: $header-height-rn;
  }

  > .aem-Grid {
    display: flex;
  }
}
