.cmp-newsroom-list {
  background-color: transparent;
  .cmp-newsroom-list__wrapper + .cmp-newsroom-list__wrapper {
    padding-top: var(--section-spacing);
  }
  
  &__page {
    display: none;
    &.active {
      display: block;
    }  
  }
  &__title {
    text-align: center;
  }

  &-item {
    border-radius: $border-radius;
    padding: calc($spacer * 2);
    position: relative;
    margin-bottom: calc($spacer * 2); 
    &:last-child {
      margin-bottom: 0;
    }

    .cmp-newsroom-list-item--pr-date {
      display: none;
    }

    .item-title {
      font-size: rem(24px);
      font-family: $font-serif;
      line-height: 1.2;
      font-style: italic;
      letter-spacing: -0.24px;
      margin-bottom: calc($spacer * 1.33);
      color: $primary;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .item-content {
      font-size: rem(20px);
      p {
        margin-bottom: 0;
      }
    }
    &--card {
      background-color: $teal-10;
      border: 0;
      filter: $drop-shadow;
      display: flex;
      flex-direction: column;
      .item-content:not(:last-child) {
        margin-bottom: $spacer;
      }
      .cta-button {
        text-align: right;
        margin-top: auto;
        .cmp-cta-button {
          &--text {
            display: none;
          }
        }
      }
    }
    &--list {
      border: 1px solid rgba($primary, 25%);
      .item-title,
      .item-content {
        @include media-breakpoint-up(sm) {
          max-width: 90%;
        }
      }
      .cta-button {
        @include media-breakpoint-up(sm) {
          position: absolute;
          top: calc($spacer * 2);
          right: calc($spacer * 2);
        }
        @include media-breakpoint-down(sm) {
          text-align: right;
          margin-top: calc($spacer * 2.66);
          order: 3;
          align-self: flex-end;
        }
      }
    }

    &--presentation {
      &.cmp-newsroom-list-item--list .item-content {
        @include media-breakpoint-up(sm) {
          display: flex;
          p + p {
            margin-left: rem(30px);
          }
        }
      }
    }  
  }

  &__pr-flower {
    .cmp-newsroom-list--flower-wrapper {
      position: relative;
      &:after {
        content: '';
        background-image: url('./resources/images/gei-newsroom-flower.svg');
        // background-image: url('https://org-dev.ccaemtest.org/content/dam/gei/gei-newsroom-flower.svg');
        position: absolute;
        right: 0;
        height: 428px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: 190px;
        left: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 20px;
        
        @include media-breakpoint-down(xl) {
          height: 35vw;
          background-position-y: 160px;
        }
        @include media-breakpoint-down(lg) {
          background-position-y: 110px;
          height: 34vw;
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
      }



      .cmp-newsroom-list-item--card {
        background-color: $white;
        padding: calc($spacer * 2.66) calc($spacer * 1.6); 
  
        @include media-breakpoint-down(sm) {
          margin-bottom: rem(24px);
        }
  
        .cmp-newsroom-list-item--pr-date {
          display: block;
          color: $primary-blue;
          font-size:rem(12px);
          line-height: 1;
          text-transform: uppercase;
  
          span {
            &::after {
              content:'';
              display: inline-block;
              height: 20px;
              width: 1px;
              background-color: $primary-blue;
              margin:0 10px;
              position: relative;
              bottom: -6px;
          
            }
          }
        }
        .item-title {
          margin-bottom: calc($spacer * 2);
          font-size: rem(28px);
          font-weight: 300;
          font-family: $font-sans-serif;
          color: $black;
          font-style: normal;
        }
  
        .item-content:not(:last-child) {
          margin-bottom: calc($spacer * 2); 
        }
  
        .cta-button {
          text-align: left;        
          
          .cmp-cta-button {
            background: unset;
            color: $teal;
            padding: 0;
            border: 0px;
            min-height: unset;
            font-weight: bold;
            line-height: 1;
            cursor: pointer;
            &:hover {
              color: $color-accent-darker;
            }
            &--text {
              display: inline-block;
              margin-right: rem(20px);
            }
            &__icon {
              margin: 0;
            }
          }
        }
      }
      .cmp-newsroom-list-item {
        @include media-breakpoint-down(md) {
          position: relative;

          &:after {
            content: '';
            background-image: url('./resources/images/gei-newsroom-flower.svg');
            // background-image: url('https://org-dev.ccaemtest.org/content/dam/gei/gei-newsroom-flower.svg');
            position: absolute;
            right: 0;
            height: 350px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: 265%;
            background-position: 50% 85px;
            left: 0;
            bottom: 0;
            pointer-events: none;
            border-radius: 20px;
          }
        }
      }
      &.cmp-newsroom-list--has-description {
        &:after {
          background-size: contain;
          background-position-y: unset;
        }

        .cmp-newsroom-list-item {
          &:after {
            background-position: 50% 23px;
          }
          @media (max-width: 420px) {
            &:after { 
              height: 300px;
            }
          }
        }
      }
    }
    .cmp-newsroom-list {    
      &--two {
        &::after {
          background-position-y: 260px;
          @include media-breakpoint-down(xl) {
            background-position-y: 200px;
          }
          @include media-breakpoint-down(lg) {
            background-position-y: 170px;
          }
        }
        &.cmp-newsroom-list--has-description {
          &::after {
            background-position-y: 115px;
            @include media-breakpoint-down(xl) {
              background-position-y: 95px;
            }
          }
        }
      }   
    } 
  }

  &__pagination {
    font-size: rem(24px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--section-spacing);
    &,
    a {
      color: $primary;
    }
    > span {
      margin-left: rem(10px);
      margin-right: rem(10px);
      color: $color-foreground;
    }
    a {
      text-decoration: none;
      margin-left: rem(10px);
      margin-right: rem(10px);
      display: inline-flex;
      &.current {
        font-weight: $font-bold;
        color: $color-foreground;
      }
    }

    .arrow {
      &-next {
        margin-left: rem(20px);
      }
      &-prev {
        transform: rotate(180deg);
        margin-right: rem(20px);
      }
      &-prev,
      &-next {
        color: $primary;
        span {
          pointer-events: none;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
          color: $primary;
          font-weight: normal;
        }
      }
    }
  }

  .cmp-cta-button {
    &--text {
      display: none;
    }
  }

  .cmp-newsroom-list__three,
  .cmp-newsroom-list__two {
    gap: calc($spacer * 2);
    @include media-breakpoint-up(sm) {
      .cmp-newsroom-list-item--list {
        width: 100%;
      }
    }
    @include media-breakpoint-up(md) {
      .cmp-newsroom-list-item--card {
        flex: 1 0 0%;
        margin-bottom: 0;
      }
      &.active {
        display: flex; 
        flex-wrap: wrap;
        flex-direction: row;
      }
    }
    

    .cmp-newsroom-list-item--presentation-card {
      border-radius: calc($spacer * 2);
      background: transparent;
      padding: rem(40px) rem(24px);
      border: 1px solid $white;
      backdrop-filter: blur(7px);

      .item-title {
        margin-bottom: calc($spacer * 2);
        font-style: unset;
        font-weight: 300;
        font-size: rem(28px);
        font-family: $font-sans-serif;
      }

      .item-content {
        &:not(:last-child) {
          margin-bottom: calc($spacer * 2);
        }
        p {
          font-size: rem(15px);
          font-weight: bold;
          display: flex;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          line-height: 1.133;

          &:not(:last-child) {
            margin-bottom: rem(9px);
          }
          span {
            display: inline-block;
          }

          .item-content--text{
            width: 132px;
            position: relative;
            flex: 0 0 132px;
            
            &::after {
              content: '';
              display: inline-block;
              width: 1px;
              height: rem(20px);
              background-color: $white;
              position: absolute;
              right: $spacer;
            }
          }
        }
      }
      
      .item-title,
      .item-content {
        color: $white;
      }

      .cta-button {
        text-align: left;
      }

      .cmp-cta-button {
        background-color: transparent;
        color: #fff;
        padding: 0;
        margin: 0;
        border: 0;
        font-weight: bold;
        min-height: unset; 
        line-height: 1;

        &--text {
          display: inline-block;
          margin-right: rem(20px);
        }
      }
    }

    .cmp-newsroom-list-item--card {
      &.cmp-newsroom-list-item--news {
        background-color: transparent;
        padding: 0;
        filter: unset;

        .cmp-newsroom-list-item--news-image {
          border-radius: rem(8px);
          box-shadow: 1px 22px 20px 0px rgba(56,112,183,0.25);

          height: rem(256px);
          img {
            border-radius: rem(8px);
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }
        .cmp-newsroom-list-item--news-content {
          padding: rem(40px) rem(24px);
          flex: 1 0 0%;
          display: flex;
          flex-direction: column;
          
          @include media-breakpoint-down(md) {
            padding: rem(40px) 0;
          }
        }
    
        .item-title {
          margin-bottom: calc($spacer * 2);
          font-family: $font-sans-serif;
          font-size: rem(28px);
          font-weight: 300;
          font-style: normal;
          color: $black;
        }
        
        .cta-button {
          text-align: left;
          .cmp-cta-button {
            background-color: transparent;
            border: 0;
            padding: 0;
            color: $teal;
            font-weight: bold;
            line-height: 1;
            min-height: unset;
            
            span {
              &:not(.cmp-cta-button__icon) {
                margin-right: rem(20px);
              }
            }
            .cmp-cta-button__icon {
              margin: 0;
            }
            &:hover {
              color: $primary-hover;
              .cmp-cta-button__icon {
                color: $primary-hover;
              }
            }
          }
        }
      }
    }    
  }
  .cmp-newsroom-list__three,
  .cmp-newsroom-list__two,
  .cmp-newsroom-list__all {
    .cmp-newsroom-list-item--card,
    .cmp-newsroom-list-item--list {
      &.cmp-newsroom-list-item--news { 
        .cmp-newsroom-list-item--news-date {
          font-feature-settings: normal;
          display: block;
          font-weight: bold;
          color: $primary-blue;
          font-size:rem(15px);
          line-height: 1;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          margin-bottom: calc($spacer * 2);
      
          span {
            &::after {
              content:'';
              display: inline-block;
              height: 20px;
              width: 1px;
              background-color: $primary-blue;
              margin:0 rem(15px);
              position: relative;
              bottom: -6px;         
            }
          }
        }
      }
    }
  }
  .cmp-newsroom-list__all {
    .cmp-newsroom-list-item--card{
      &.cmp-newsroom-list-item--news { 
        .cmp-newsroom-list-item--news-image {
          display: none;
        }
        .cta-button {
          .cmp-cta-button {
            span {
              &:not(.cmp-cta-button__icon) {
                display: none;
              }
            }
          }
        }
        .cmp-newsroom-list-item--news-date {
          color: $black;
        }
      }
    }
  } 
  .cmp-newsroom-list__two,
  .cmp-newsroom-list__three,
  .cmp-newsroom-list__all {
    .cmp-newsroom-list-item--list {
      &.cmp-newsroom-list-item--news {
        .item-content {
          margin-bottom: rem(20px);
        }
        .item-details {
          font-size: rem(20px);
          margin: 0;
        }
      }
    }
  }
} 

.newsroom-list + .cta-button {
  margin-top: rem(-20px);
  @include media-breakpoint-down(sm) {
    margin-top: rem(-40px);
  }
}
.cmp-newsroom-list-item + .cmp-newsroom-list__title {
  margin-top: var(--section-spacing);
}
.container.aem-GridColumn {
  .newsroom-list + .cta-button {
    margin-top: rem(30px);
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  } 
}

.cmp-newsroom-list__title-news {
  margin-bottom: rem(40px);
  font-size: rem(40px);
  @include media-breakpoint-down(md) {
    margin-bottom: calc($spacer * 2);
  }
  &::after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #EAF0F8;
    display: block;
    margin-top: 10px;
  }
}