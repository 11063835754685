.cmp-in-page-anchors {
  padding-bottom: var(--section-spacing);
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: center;
  }
  &__item {
    display: flex;
    flex-direction: column;
    color: $primary;
    font-weight: $font-medium;
    text-align: center;
    max-width: 25%;
    width: 100%;
    text-decoration: none;
    font-size: rem(26px);
    @include media-breakpoint-down(sm) {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    .cta-button {
      margin-top: auto;
    }
    span:not(:last-child) {
      margin-bottom: rem(50px);
      @include media-breakpoint-down(sm) {
        margin-bottom: rem(5px);
      }
    }
    sup {
      font-size: 100%;
      font-weight: $font-light;
      top: 0;
    }
  }

  &__item + &__item {
    margin-top: calc($spacer * 2.66);
    @include media-breakpoint-up(sm) {
      margin-left: calc($spacer * 2);
      margin-top: 0;
    }
  }
}

.in-page-anchors + .component {
  padding-top: 0;
}
.in-page-anchors + .container.aem-GridColumn {
  padding-top: 0;
}
